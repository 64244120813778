<template>
    <div></div>
</template>

<script>
import $ from 'jquery';
//import router from '../router.js' 
  export default {
    name: 'AdComp',
    props: {view: String},
     data: () => ({

        }),
  created () { 
      if(window.location.hostname!="localhost")
      {
        let self = this;
            let user = $.parseJSON(localStorage.getItem('user'));
        if(user.vip!="true")
        {
         $.get( this.$root_url + "/ads/ad_1.txt")
        .done(function(data) {    
            $("#"+self.view).append(data);
         })
            .fail(function() {
                console.log("Disable adblock pls");
            //router.push({ name: 'disable_ab' });
        })
      }
      
    

  }
  },

  
  
  }
</script>

