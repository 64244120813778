<template >
  <v-app style="width:100%; background-color:rgba(0,0,0,0);" >      
      
      <Player ref="Player" :options="videoOptions" class="mt-4" />

        <v-card
      class="mx-auto mt-8"
        width="100%"    
        style="border-radius:15px; background-color:rgba(0,0,0,.4)"   
      tile
    >
  
      <v-list flat  style="background-color: rgba(0,0,0,0.2);">
        <v-subheader  v-if="show.cat_id<4"> <v-icon dark 
            :color="'orange'"
            @click="toggle_watch_all(item,i)" 
            >{{'mdi-eye-check'}}</v-icon>    
            <h2 class="ml-5 orange--text text--lighten-1" style=" text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.4);">Season {{this.$route.params.season}} 
              <span style="font-size:12pt; color:gray; font-weight:normal;">
                ({{this.episodes.length}})
              </span>              
              </h2>
              <v-spacer>
              </v-spacer>
               <v-icon dark  :color="'orange'" @click="download_season()" 
            >{{'mdi-download-multiple'}}</v-icon>    
        </v-subheader>        
        <v-subheader v-else>Episodes({{this.episodes.length}})          
        </v-subheader>
        

        <v-list-item-group  color="primary">
          
          <v-list-item
            v-for="(item, i) in episodes"
            :key="i"            
          >
            <v-list-item-icon >
              <v-icon dark 
                        @click="toggle_watch(item,i)"   
                        :color="getWatchStatus(item) ? 'orange' : 'white'"
                        >{{getWatchStatus(item) ? 'mdi-eye-check' : 'mdi-eye'}}</v-icon>     
                
                               
            </v-list-item-icon>            
            <v-list-item-content @click="play_item(item,i)">
              <v-list-item-title v-if="show.cat_id<4">
                 <v-icon dark style="margin-left:-28px; margin-top:-3px; position:absolute;"
                        @click="play_item(item,i)"  
                        color="blue" 
                        v-if="item_playing == i">mdi-play</v-icon>  
                        Episode {{getItemEpisode(item) }} <br>
                        <p style="font-size:10pt; color:darkgray;" v-if="item.air_date!='' && item.air_date!=undefined"> Aired:  {{item.air_date}}</p>
                        
                        <p style="font-size:10pt; color:darkgray;" v-else> Added {{item.add_date.split(" ")[0]}}</p>
                                  
                        </v-list-item-title>
              <v-list-item-title v-else> {{show.title}} {{getItemEpisode(item) }}</v-list-item-title>              
            </v-list-item-content>
            
                 <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon dark v-on="on"
                        @click="play_item(item,i)"   
                        v-if="item.subs!=null">mdi-comment-text-outline</v-icon>                      
        </template>
        <span v-if="item.subs!=null">This Episode has {{item.subs.length}} subtitles</span>
      </v-tooltip>
         <v-menu offset-y dark min-width="140" class="" >
                <template  v-slot:activator="{ on }">
                <div class="d-inline-flex float-right mt-1"><v-icon v-on="on" style="    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);">mdi-dots-vertical</v-icon></div>                                        
                </template>
                <v-list style="background-color:#000000;" >       
                    <v-list-item style="background-color:#000000;">
                      <a @click="downloadSingle(item.src_vip_sd)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 480P</span></a>                                                  
                    </v-list-item>                                 
                    <v-list-item style="background-color:#000000;" v-if="user.vip=='true' && item.src_vip_hd!=''">
                      <a @click="downloadSingle(item.src_vip_hd)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 720P</span></a>                                                  
                    </v-list-item>                                 
                    <v-list-item style="background-color:#000000;">
                      <a @click="getPlaylistsDialog(item)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Add To Playlists</span></a>                                                  
                    </v-list-item>    

                </v-list>
                </v-menu>
                   
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

 <v-row>
    <v-dialog v-model="show_playlists"  transition="dialog-bottom-transition" width="500">      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_playlists = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add To Playlist</v-toolbar-title>
          
          <v-spacer></v-spacer>
          
             <v-toolbar-items>
            
            <v-btn color="green" dark text @click="add_to_playlist()">Done</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>      
          <v-list-item>
            <v-list-item-content >                              
                <v-select v-model="selected_playlist"
                  :items="playlists"
                  item-text="name"                  
                    return-object></v-select>              
            </v-list-item-content>
            </v-list-item>
          
        </v-list>
      </v-card>
    </v-dialog>
 </v-row>
        
  </v-app>
  
</template>

<script>
    import $ from 'jquery';
    
  import Player from '../components/Player';
   let timer_handle;
    
  export default {
    data: () => ({   
        qualities: [],
        item_playing:0,   
        btnSearchSubs: false,
        btnSubtitle: "Get Subtitles",
        show_playlists:false,
        selected_video:null,
        selected_playlist:null,
        playlists:[],
        season_dl_interval  : 0,
        season_dl_list: [],
        next_episode: 0,      
        timer:0,
        switch1: true,
        user:[],
        show:[],
        episodes: [],
        videoOptions:{
          autoplay: true,
          controls: true,
          nativeTextTracks: false,
            sources: [
            ],
            tracks:
            []
        } 
        }),    
   components:{
      "Player": Player
    },created()
    {
     this.user =  $.parseJSON(localStorage.getItem('user'));
    },
    mounted() {
      let self = this;           
      console.log(self.$refs);

    this.timer =  setInterval(function(){ 
          let nextEpIndex = self.item_playing+1;          
          let next_ep = self.episodes[nextEpIndex];
          if(self.$refs.Player.next_timer>0)
          {                        
            if(next_ep!==null)
            {
              self.$refs.Player.next_timer-=1; 
              $("#next_time_text").html('Playing E'+(nextEpIndex+1) + ": " + next_ep.episode_title + ' in ' + self.$refs.Player.next_timer + '');  
            }
            
          } 
          else
          {
            if(!self.$refs.Player.cancel_autoplay && self.$refs.Player.play_nextDialog)
            {
                self.$refs.Player.cancel_autoplay = true;                 
                 self.play_item(self.episodes[nextEpIndex],nextEpIndex,true);
                 $(".dialog_next_episode").css("display","none");
            }
          }
        }, 1000);
        


      let url  = self.$root_url + "/webapi/videos/get_episodes.php?show_id="+this.$route.params.showid+"&season="+this.$route.params.season+"&user_id="+self.user.id +"&token="+self.user.auth_token;      
      $.getJSON(url, function(data) {                    
         self.episodes = data.episodes;        
         self.show = data.show;   
         let epFound = false;     
        if(self.$route.params.episode>0)
         {                    
           var i = 0;
            for (; i < self.episodes.length; i++) {
              var episode = self.episodes[i];    
              
              if(episode.episode==self.$route.params.episode)
              {      
                console.log("Found match at " + i+": " + episode.episode + "Param: " + self.$route.params.episode);

                epFound = true;            
                setTimeout(function(){
                    self.play_item(episode,i);
                  self.item_playing  = i;
                }, 500);
                  break;
              }
            }
            
            if(!epFound)
            {
              setTimeout(function(){
                  self.play_item(self.episodes[0],0);
          }, 500);
            }
         }
         else
         setTimeout(function(){
                  self.play_item(self.episodes[0],0);
          }, 500);
          
          
      });
    },
    computed: {

    },
    
    beforeRouteLeave (to, from, next) { 
      try{
          clearInterval(this.timer);
          let current_episode = this.episodes[this.item_playing];          
          let self = this;               
          var currentTime =   self.$refs.Player.player.currentTime();
          var duration =   self.$refs.Player.player.duration();

          $.getJSON(self.$root_url + "/webapi/videos/user_watched.php?video_id="+current_episode.video_id + "&position="+parseInt(currentTime) + "&length=" + parseInt(duration) +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {              
              
          });

            }
        catch(err) {    
          console.log(err) ;
        }
      
      next();
     },
  
    methods: {
     handler(params) {
        console.log(params);
        this.video_ended();
    },
    video_ended()
    {
      let self = this;
        
            let current_episode = self.episodes[self.item_playing];                          
            var duration =   self.$refs.Player.player.duration;
              $.getJSON(self.$root_url + "/webapi/videos/user_watched.php?video_id="+current_episode.video_id + "&position="+parseInt(duration/60) + "&length=" + parseInt(duration/60)+"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {
                self.episodes[self.item_playing].watched = 1;
              });
              
                if(self.item_playing < self.episodes.length-1)
                {
                    
                    self.$refs.Player.next_episode = self.item_playing +2;
                    self.$refs.Player.play_nextDialog = true;
                    self.$refs.Player.next_timer = 10;
                    self.$refs.Player.cancel_autoplay = false;
                    self.$refs.Player.showPlayNextDialog(); 

               

                } 
    },
    download_Async(urls)
    {      
        var url = urls.pop();        
        var a = document.createElement("a");
        a.setAttribute('href', url);
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank');
        a.click();

        if (urls.length == 0) {
          clearInterval(this.season_dl_interval);
        }

    },
    download_season()
    {
        if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
        }

      let self = this;
      this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will download all episodes of Season ' + this.$route.params.season + "'. Please note that you need to allow popups for this site.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {
              
                      this.$swal.fire({
                title: 'Choose quality',
                text: 'Select betwen 720P and 480P',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText:"480P",
                confirmButtonText: "720P"                
                }).then((result) => {        
                  if (result.value) {
                   self.season_dl_list = [];
                    self.episodes.forEach(function(item) {
                        self.season_dl_list.push(self.get_download_url(item.src_vip_hd));                         
                    });
                    self.season_dl_interval = setInterval(self.download_Async, 300, self.season_dl_list);
                  }else
                  {
                     self.season_dl_list = [];

                    self.episodes.forEach(function(item) {
                        self.season_dl_list.push(self.get_download_url(item.src_vip_sd));                         
                    });
                    self.season_dl_interval = setInterval(self.download_Async, 300, self.season_dl_list)
                  }});                                      
            }});

    },
    getPlaylistsDialog(item)
    {
      let self = this;
      
                    $.getJSON(self.$root_url + "/webapi/videos/playlists.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&p=1", function(data) {
                      self.selected_video = item;
                      self.show_playlists = true;
                      self.playlists = data.items;                
                      self.loading = false;
                      
                  });
    },
   add_to_playlist()
      {
          let self = this;
      this.selected_video.id = this.selected_video.episode_id;
      this.selected_video.cat_id = this.show.cat_id;
      var playlist_data = JSON.stringify(this.selected_playlist);   
      var vid_data = JSON.stringify(this.selected_video);   

    
        $.post( self.$root_url + "/webapi/videos/playlist_add.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { playlist_data: playlist_data, video_data:vid_data})
              .done(function( data ) {              
                  
                  let result = $.parseJSON(data);
                        if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.show_report_error = false;
                    self.show_playlists = false;
                    self.$swal( "Done",self.selected_video.title + " was added to playlist " + self.selected_playlist.name);
                    
                  }         
          }); 
   
      },
    downloadSingle(url)
    {
        if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
        }
        window.location = this.get_download_url(url);
    },
    get_download_url(url)
    {

        var base = new String(url).substring(url.lastIndexOf('/') + 1); 
        if(base.lastIndexOf(".") != -1)       
          base = base.substring(0, base.lastIndexOf("."));
          base = base.replace("_480p","");
          base = base.replace("_720p","");
        return url +"&filename="+base+".mp4";
    },
      getItemEpisode(item)
      {
        
        if(item.extra_episode>0 && item.extra_episode !== undefined)
        {
          return item.episode + "-" + item.extra_episode + " " + item.episode_title;
        }
        else
        return  item.episode + ": " + item.episode_title;
      },
      changeSrc: function (item) {      
      this.selected_quality = item.title;
        
      },
        reloadEpisodes: function(){
          let self = this;
          $.getJSON(self.$root_url + "/webapi/videos/get_episodes.php?show_id="+this.$route.params.showid+"&season="+this.$route.params.season+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                
                self.episodes = data.episodes;                
                self.show = data.show;        
                self.play_item(self.episodes[self.item_playing]);        
              });
        },
        getSubs: function()
          {
            let self = this;
            let episode = this.item_playing+1;
            this.btnSearchSubs = true;
            this.btnSubtitle = "Searching please wait..";
                $.getJSON(self.$root_url + "/webapi/subtitle/search_show.php?show_id="+this.$route.params.showid+"&season="+this.$route.params.season + "&episode="+episode +"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                                                
                    alert(data.reason);                    
                    self.btnSearchSubs = false;
                    self.btnSubtitle = "Search Subtitles";
                    self.reloadEpisodes();                
                });
          },
          getWatchStatus(item)
          {
            
            if(item.watched == null) return false;
            else if(item.watched==1) return true;
            else return false;
          },

          toggle_watch_all()
          {
          this.$swal.fire({
          title: 'Are you sure?',
          text: 'This action will toggle watchstatus on all episodes of season ' + this.$route.params.season,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {
              let self = this;
              let watched = null;
              let x;
              let cmd = "unwatch";
              
              if(self.episodes[0].watched==null)                         
                  cmd = "watch";
              


              $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd="+cmd+"&show_id="+self.show.id +"&season="+this.$route.params.season+"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                                                
                
                for(x=0;x<self.episodes.length;x++)
                {
                  let item = self.episodes[x];
                  if(x==0) 
                  {
                    if(item.watched!=null)
                    watched = null; else 
                    {
                      watched = 1;                            
                    }
                  }
                  self.episodes[x].watched =  watched;               
                } 
              });                        
            }
          })            
          },

        toggle_watch(item,i)
        {
          
          let self = this;
          if(item.watched!=null)
          {
            this.episodes[i].watched = null;
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=unwatch&video_id="+item.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                                                

            });
          }else
          {
            this.episodes[i].watched = "1";
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=watch&video_id="+item.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {   

            });
          }
        },
         play_item(video,i,continueVid=false)
        {     
          
          var strEp = i+1;
          window.history.pushState("", "", "/play_season/"+this.$route.params.showid+"/"+this.$route.params.season+"/"+strEp);
           $("html, body").animate({ scrollTop: 0 }, "slow");
          let self = this;
          clearTimeout(timer_handle);
          self.$refs.Player.play_nextDialog = false;
          
          self.item_playing = i;
          if(!continueVid)
          {
              self.$refs.Player.loadSources(video);
                $.getJSON(self.$root_url + "/webapi/videos/play_count.php?video_id="+video.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                
              });
          }
          else{
              self.$refs.Player.loadNext(video);
                    $.getJSON(self.$root_url + "/webapi/videos/play_count.php?video_id="+video.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                
              });
          }
          
          
          

        }, 
    }
  }
</script>
