<template>
  
     
      <v-row align="start" class="fill-height">
        <v-col cols="12"
        >
     <v-card width="full" align="start" class="" color="rgba(0,0,0,0.7)">
       <v-card-title >
         <h1 class="display-1 mx-auto" style="color:white;">Mobilevids Applications</h1>         
       </v-card-title>
       <v-card-text>
         
           <p style="text-align:center;">Here are alternative ways of accessing Mobilevids.</p>
           <v-divider></v-divider>    
            <v-container>
             <v-row>
             <v-col cols="12" style="">
               <h2>Kodi Addon</h2>
               <p>Kodi (formerly XBMC) is a free and open-source media player software application developed by the XBMC Foundation. Kodi is available for multiple operating systems and hardware platforms, with a software 10-foot user interface for use with televisions and remote controls. It allows users to play and view most streaming media, such as videos, music, podcasts, and videos from the Internet, as well as all common digital media files from local and network storage media.</p>
               <p>To install the addon do the following:</p>
               <h4>Instructional video</h4>  
               <video width="720" height="480" controls style="margin-top:-30px;">
                  <source src="https://mobilevids.org/media/tutorial/kodi.mp4" type="video/mp4">                                
                </video>
               <p>
                1) Go to settings then File Manager<br/>
                2) Add Source then select "None" and enter the address https://mobilevids.org/kodi/ and add a name for the source
                3) Go to addons then select the box icon then Install from zip file<br/>
                4) Choose the source name you set in step 1 then select plugin.video.mobilevids.zip<br/>
                5) Wait a couple minutes for it to install, should get a notification once its done<br/>
                6) Go to the MV app settings and enter your user/password</p>
                    
                
             </v-col>                                                
             </v-row>                                        
           </v-container>

      <v-divider></v-divider> 

           
      <v-divider></v-divider> 
  
      <v-divider></v-divider> 

         
       </v-card-text>

     </v-card>    
        </v-col>
       
      <SiteConfig/>
      </v-row>
     
</template>


<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'


export default {
  name: 'Account',   
  data: () => ({   
      data_loading : false,         
      user: []

  }),
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{
"SiteConfig":SiteConfig
  },
  methods:{     
  
  },

  mounted(){
    
     //let self = this // create a closure to access component in the callback below
    
     //$.getJSON(self.$root_url, function(data) {                     });

     
  }
};
</script>