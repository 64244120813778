<template >

      <v-container >  
        <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
      <h4 class="mt-2"  style="top:20px; width:100%">Now Watching - <span style="color:#4f0524; font-size:9pt; ">BETA</span>         
      <p style="color:#dcdee0;">Get inspired and see what others are watching right now.</p>
      
      </h4>            
    
               
        <v-pagination
        color="#d4af37"
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-2 pr-5 pl-5"
      ></v-pagination>
        
        <VideoList view="now_watching_tv"  />        

        <v-pagination
        v-model="page"
           color="#d4af37"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12 pr-5 pl-5"
      ></v-pagination>
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';

import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'TVShows',    
   
    props: {
      source: String
    },
     data: () => ({   
          page: 1,
        total_pages:1,
        switch1: true,
        loading: true,
        user:[],
         cards: [     
        ]
        }),
  created(){
    this.user =  $.parseJSON(localStorage.getItem('user'));
    if(this.user.view_style!=undefined && (this.user.view_style>0 || this.user.view_style=='true'))
        this.switch1 = true;
    else
        this.switch1 = false;

  },
  components:{
    "VideoList" : VideoList,
    "SiteConfig": SiteConfig
  },
  methods:{
        changePage()
        {
            var self = this;
            self.loading = true;
         $.getJSON(self.$root_url + "/webapi/videos/now_watching.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&p="+self.page + "&timezone="+self.getTimeZone(), function(data) {
            self.cards = data.items;                
            self.loading = false;
            });
        },
         getTimeZone()
        {    
            return encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
        },
        
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
        $.getJSON(self.$root_url + "/webapi/videos/now_watching.php?user_id="+self.user.id +"&token="+self.user.auth_token + "&timezone="+self.getTimeZone(), function(data) {
        self.loading = false;
        self.cards = data.items;   
        self.total_pages = data.total_pages;
    });
    },
     beforeRouteLeave (to, from, next) { 
        this.cards      = [];
        this.user       = [];
        delete this.cards;
        delete this.user;
      next();
     }
  }
</script>

