<template >

      <v-container >  
      <h4  style="position:absolute; top:40px; width:100%">Video Conversions
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h4>            
      
       <div style="height:30px;"></div>
    
    <v-tabs
        class="mt-6"
      background-color="rgba(0,0,0,0.7)"
      color="amber darken-2"
      left           
    >
      <v-tab  @click="changeTab(0)">Active</v-tab>
      <v-tab @click="changeTab(1)">Finished</v-tab>    
      <v-tab @click="changeTab(2)">TVShows Log</v-tab>    
      <v-tab @click="changeTab(3)">Movies Log</v-tab>    
           
    </v-tabs>
    <v-btn class="float-right" style="margin-top:-40px; margin-right:10px;" @click="updateStatus()">Update</v-btn>   
    <div v-if="selected_tab==0">

         <v-data-table
    :headers="headers"
    :items="active_conversions"    
    :sort-desc="[false, true]"
    @click:row="OptionsDLG"
    multi-sort
    class="elevation-1"
  ></v-data-table>
        
      </div>

    <div v-if="selected_tab==1">
         <v-data-table
    :headers="headers"
    :items="finished_conversions"    
    :sort-desc="[false, true]"
    multi-sort
    class="elevation-1"
  ></v-data-table>
        
      </div>
    <div v-if="selected_tab==2">
         <v-data-table   
    :headers="log_header" 
    :items="tv_log"    
    :sort-desc="[false, true]"
    multi-sort
    class="elevation-1"
  ></v-data-table>      
      </div>
          <div v-if="selected_tab==3">
          <v-data-table
      :headers="log_header" 
    :items="movies_log"    
    :sort-desc="[false, true]"
    multi-sort
    class="elevation-1"
  ></v-data-table>    
        
      </div>
      


        
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'VideoConversions',    
    props: {
      source: String
    },
     data: () => ({   
          page: 1,
        total_pages:1,
        timer: null,
        switch1: true,
        loading: true,
        selected_tab: "",
        user:[],
        cards: [],
        log_header: [
          { text: 'Log', value: 'row' },
        ],
         headers: [
        { text: 'Status', value: 'keep_alive' },
        { text: 'PID', value: 'pid' },
        { text: 'Path', value: 'title' },
        { text: 'Quality', value: 'quality' },
        { text: 'Progress', value: 'progress' },
        { text: 'Duration', value: 'duration' },        
        { text: 'Added', value: 'added' } 
      ],
        active_conversions:[],
        finished_conversions:[],
        tv_log: [],
        movies_log: []
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{    
  "SiteConfig": SiteConfig
  },
   beforeRouteLeave (to, from, next) { 
       clearInterval(this.timer);
          next();
   },
  methods:{OptionsDLG(value)
    {
      
      let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will kill the process,.are you sure?' ,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, kill it'
          }).then((result) => {        
            if (result.value) {
              
      var datastring = JSON.stringify(value);      
              $.post( self.$root_url + "/webapi/backend/kill_processid.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {              
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    this.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The user was successfully deleted',
                      'success'
                    );
                    
                    let index = self.users.indexOf(self.selected_user);
                    if (index > -1) {
                      self.users.splice(index, 1);
                      }
                    }          
                });  


              
            }
          });
        

    },
        updateStatus()
        {            
            var self = this // create a closure to access component in the callback below
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/video_conversions.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                                    self.loading = false;
                                    self.active_conversions     = data.active;                           
                                    self.finished_conversions   = data.finished;                           

           });
        }
        ,
            _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
        changeTab(index)
        {            
          
            this.selected_tab = index;
        },
        update_thread(){  
            var self = this // create a closure to access component in the callback below
            self.loading = true;
                $.getJSON(self.$root_url + "/webapi/videos/video_conversions.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                                        self.loading = false;
                                        self.active_conversions     = data.active;                           
                                        self.finished_conversions   = data.finished;     
                                        self.tv_log                 = data.tv_log;                     
                                        self.movies_log             = data.movie_log;                      
                                    });
                    }        

  },
    mounted() { // when the Vue app is booted up, this is run automatically.
  
    var self = this // create a closure to access component in the callback below
    $.getJSON(self.$root_url + "/webapi/videos/video_conversions.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                            self.loading = false;
                            self.active_conversions     = data.active;                           
                            self.finished_conversions   = data.finished;      
                            self.tv_log                 = data.tv_log;                     
                            self.movies_log             = data.movie_log;
                        });                    
    this.timer = setInterval(this.update_thread, 5000);

    }
  }
</script>

