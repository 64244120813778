import Vue from 'vue';
import VueRouter from 'vue-router';
// User pages
import Menu from './views/Menu'
import LoginPage from './views/Login'
import RegisterPage from './views/Register'
import RecoverPage from './views/Recover'
import AccountPage from './views/Account.vue'
import ProfilePage from './views/Profile.vue'
import InboxPage from './views/Inbox.vue'
import MyInvitesPage from './views/MyInvites.vue'

// List pages
import HomePage from './views/Home'
import MoviesPage from './views/Movies'
import TopMoviesPage from './views/TopMovies'
import TVShowsPage from './views/TVShows'
import TopShowsPage from './views/TopShows'
import SportsPage from './views/Sports'
import SeasonsPage from './views/Seasons'
import SocialPage from './views/Social'
import SearchPage from './views/Search'
import PremiumPage from './views/Premium'
import MyShowsPage from './views/MyShows'
import LiveTVPage from './views/LiveTV'
import ShowCalendar from './views/ShowCalendar'
import RewardsPage from './views/Rewards'
import NowWatchingPage from './views/NowWatching'
import PlaylistsPage from './views/Playlists'



//admin view
import UsersPage from './views/Users'
import SupportBoxPage from './views/SupportBox'
import InvitesPage from './views/Invites'
import ConversionsPage from './views/Video_Conversions'
import VideoManagerPage from './views/Video_Manager'
import VideoReportsPage from './views/Video_Reports'
import NewsManagerPage from './views/News_Manager'
import IMDBManagerPage from './views/IMDB'
import CookiePage from './views/Cookies'
import SystemPage from './views/System'
import KebabPage from './views/Kebab'
import AppsPage from './views/Apps'

// adblock
import AdblockPage from './views/DisableAB'

// player
import VideoPlayerPage from './views/VideoPlayer'

import TVPlayer from './views/TVPlayer'
import PlaylistPlayer from './views/PlaylistPlayer'
var routes = 0;

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', components: {a:Menu},
      children :[
        { path: 'home', components:{b:HomePage },name:"home"},
        { path: 'movies', components:{b: MoviesPage },name:"movies"},
        { path: 'top_movies', components:{b: TopMoviesPage },name:"top_movies"},
        
        { path: 'tvshows', components:{b: TVShowsPage },name:"tvshows"},
        { path: 'top_shows', components:{b: TopShowsPage },name:"top_shows"},
        { path: 'now_watching', components:{b: NowWatchingPage },name:"now_watching"},
        { path: 'playlists', components:{b: PlaylistsPage },name:"playlists"},
        
        
        
        
        { path: 'ppv', components:{b: SportsPage },name:"ppv"},        
        { path: 'livetv', components:{b: LiveTVPage },name:"livetv"},                
        { path: 'social', components:{b: SocialPage },name:"social"},
        { path: 'apps', components:{b: AppsPage },name:"apps"},
        
        { path: 'myshows', components:{b: MyShowsPage },name:"myshows"},
        { path: 'show_calendar', components:{b: ShowCalendar },name:"show_calendar"},
        
        { path: 'users', components:{b: UsersPage },name:"users"},   
        { path: 'conversions', components:{b: ConversionsPage },name:"conversions"},   
        { path: 'video_manager', components:{b: VideoManagerPage },name:"video_manager"},  
        { path: 'video_reports', components:{b: VideoReportsPage },name:"video_reports"},  
        
        { path: 'news_manager', components:{b: NewsManagerPage },name:"news_manager"}, 
        { path: 'IMDB_manager', components:{b: IMDBManagerPage },name:"IMDB_manager"},         
        { path: 'support_box', components:{b: SupportBoxPage },name:"support_box"},         
        { path: 'cookie_opensub', components:{b: CookiePage },name:"cookie_opensub"},         
        
        
        { path: 'manage', components:{b: KebabPage },name:"manage"},  
        { path: 'invites', components:{b: MyInvitesPage },name:"invites"},          
        { path: 'admin_invites', components:{b: InvitesPage },name:"admin_invites"},    
        { path: 'system', components:{b: SystemPage },name:"system"},          
        { path: 'account', components:{b: AccountPage },name:"account"},
        { path: 'profile/:user_id', components:{b: ProfilePage },name:"profile"},
        { path: 'inbox', components:{b: InboxPage },name:"inbox"},
        { path: 'premium', components:{b: PremiumPage },name:"premium"},
        { path: 'play/:vid', components:{b: VideoPlayerPage },name:"play"},       
        { path: 'play_primitive/:vid', components:{b: VideoPlayerPage },name:"play_primitive"},       
        { path: 'show/:showid', components:{b: SeasonsPage },name:"season"},
        { path: 'play_season/:showid/:season/:episode', components:{b: TVPlayer },name:"play_season"},
        { path: 'play_list/:listid/:index', components:{b: PlaylistPlayer },name:"play_list"},
        { path: 'search/:query/:opt', components:{b: SearchPage },name:"search_opt"},                
        { path: 'search/:query', components:{b: SearchPage },name:"search"},  
        { path: 'rewards', components:{b: RewardsPage },name:"rewards"},                
        
      ] 
    },
    { path: 'disable_ab', components:{a: AdblockPage },name:"disable_ab"},   
    {
      path: '/login', components: {a:LoginPage}, name : "login"      
    },
    {
      path: '/register', components: {a:RegisterPage},name:"register"         
    },
    {
      path: '/recover', components: {a:RecoverPage},name:"recover"         
    },
    
    
  
    
    

    // otherwise redirect to home
    { path: '*', redirect: '/home' }
  ]
});

router.beforeEach((to, from, next) => {  
  routes+=1;
  
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register','/recover'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
 
  if (authRequired && !loggedIn) {     
    return next('/login');
  }else if(to.path=="/")
  return next("/home");
  
  next();
  if(routes>10)
  {    
    location.reload();
  }
});


export default router;
