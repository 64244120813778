<template>
  <v-app style="width:100%; background-color:rgba(0,0,0,0);" class="mt-5" >      
    

<div v-if="show_vip">
  <div  style="text-align:center; position:relative; padding:6px; border-top-left-radius: 15px; border-top-right-radius: 15px; background-color:rgba(0,0,6,0.8); height:40px; margin-left:auto; margin-right:auto; margin-top:-0px;" >          
    <h4><span style="color: rgb(212, 175, 55);">You're watching:</span> {{current_channel_index}}.{{current_channel}} </h4>                            
  </div>
<iframe  allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media" border="0" 
allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" id="livetvplayer" :src="'https://mobilevids.org/media/live/stream.php?id=1&user_id='+this.user.id +'&token='+this.user.auth_token"
class="liveplayer"
   height="450">    
</iframe>
   <div class="video_controls text-center" >                    
      <v-btn text class="float-left orange--text text--lighten-1" @click="dialog=!dialog" ><v-icon  style="font-size:28pt;" >mdi-view-list</v-icon></v-btn>
          
          
          <v-btn text class="orange--text text--lighten-1" @click="prevChannel()" :disabled="current_channel_index<=1" >
              <v-icon style="font-size:32pt;" >mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn text class="orange--text text--lighten-1"  @click="nextChannel()" :disabled="current_channel_index == channels.length">
              <v-icon  style="font-size:32pt;">mdi-skip-next</v-icon>
          </v-btn>
            </div>   
        <v-card
      class="mx-auto mt-6"
        width="100%"    
        style="border-radius:15px; background-color:rgba(0,0,0,.4)"   
      tile
    >
    
    <div style="text-align:center; padding-top:10px;" >
        <h2 style="color: rgb(212, 175, 55);">Channels</h2>
    </div>
    <v-card-title >
      
      <v-text-field
        v-model="search"        
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table style="background-color: rgba(0,0,0,0.0);" 
    calculate-widths="false"
      :headers="headers"
      :items="channels"
      :search="search"
        @click:row="play_item" item-key="id"
        
    ></v-data-table>

    </v-card>

     <v-row justify="center">
    <v-dialog v-model="dialog" width="700"  transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Schedule</v-toolbar-title>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>{{toDay()}}</v-subheader>
          <v-list-item  >
            <v-list-item-content>
            <p v-for="(item, i) in schedule" class="mt-4"
            :key="i">{{item.name}}<br><span class="orange--text" style="font-size:10pt;">Channel:{{item.channel}}</span></p> 
            </v-list-item-content>           
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>

</div>
<div v-else style="text-align:center;">

<h2  class="mt-10" style="text-align:center; color:#d4af37;">Live TV is only available for Premium members. <br> Upgrade now to maximize your experience and get access to all the amazing features.</h2>
<a class="v-btn v-btn--contained v-btn--router theme--dark v-size--default" style="background-color: rgb(212, 175, 55);" href="/premium">Go Premium!</a>

</div>
<SiteConfig />
  </v-app>
  
</template>

<script>
    import $ from 'jquery';     
    import SiteConfig from '../components/SiteConfig'

  export default {
    data: () => ({      
        item_playing:0,   
        current_channel:"",
        current_channel_index:1,
        user:[],        
        search:"",
        headers: [{ text: '#', value: 'id',align: 'start', width: 2},
                  { text: 'Channel', value: 'channel' }],
        channels: [],
        schedule:[],
        dialog: false,
        show_vip: false
        }),    
   components:{    
     "SiteConfig":SiteConfig
    },created()
    {
     this.user =  $.parseJSON(localStorage.getItem('user'));
     
    },
    mounted() {
      let self = this;                              
      $.getJSON(self.$root_url + "/webapi/livetv/get_channels.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                 
         self.channels = data.channels;      
         self.schedule = data.schedule;
         self.current_channel = data.channels[0].channel[0];
      });

       if(this.user.vip == 'true')
          this.show_vip = true;
        else 
          return false;

    },
  computed:{

  },
    beforeRouteLeave (to, from, next) { 

      next();
     },
    methods: {
      toDay()
      {
        let today = new Date().toISOString().slice(0, 10)
        return today;
      },
      nextChannel()
      {
        this.current_channel_index += 1;     
        
        let item = this.channels[this.current_channel_index-1];   
        this.play_item(item,this.current_channel_index);
      },
      prevChannel()
      {
        this.current_channel_index -= 1;     
        let item = this.channels[this.current_channel_index-1];   
        this.play_item(item,this.current_channel_index);
      },
      play_item(item, row)
      {         
          this.current_channel = item.channel[0];
          this.current_channel_index = item.id;         
           $("html, body").animate({ scrollTop: 0 }, "slow");
           $("#livetvplayer").attr('src',"https://mobilevids.org/media/live/stream.php?id="+item.id + "&user_id="+this.user.id +"&token="+this.user.auth_token);
           
           return row;
      }
    }
  }
</script>
<style >
.liveplayer
{
width:100%; 
background-color:black; 
border-style:none; 
height: 720px;
max-height:720px!important;
outline: none; 
overflow:hide; 
border-width:0px;
}
.video_controls{
  position: relative;
   margin-top:-8px; 
  width:100%; 
  padding:10px; 
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color:rgba(0,0,6,0.8);
  height:60px;
  margin-left:auto;
  margin-right:auto;
}
.dialog_next_episode
{
  position: absolute; margin-top:200px; margin-left:40%; width:400px; z-index:100;
}

@media only screen and (max-width: 600px) {
  .liveplayer {
    height: 180px!important;
    max-height:180px!important;
  }
}

@media only screen and (min-width: 600px)  and (max-width: 800px){
  .liveplayer {   
    height: 340px !important;;  
    max-height:340px!important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px){
  .liveplayer {
    height: 380px;
    max-height:380px!important;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px){
  .liveplayer {
    height: 380px;
    max-height:380px!important;
  }
}


</style>