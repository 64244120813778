<template >

      <v-container >  
         <h2  style="position:absolute; top:40px; width:100%">Users
           <v-btn dark  color="blue" text @click="new_user_dialog = true"><span class="mdi mdi-account-plus mdi-24px" style="color:white;" ></span> New</v-btn>
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h2>        
      <v-card
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0"
    >    
      <v-divider></v-divider>

    <v-card-title>      
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
       @click:row="rowClick" item-key="id"
    >
    
    </v-data-table>

      
      </v-card>
            

 <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selected_user.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark  color="red" text @click="deleteUser()">Delete</v-btn>
            <v-btn dark text @click="saveUser()">Save</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>User Information</v-subheader>
            <v-list-item>
            <v-list-item-content>              
              <v-text-field label="MediaTrunk User ID" v-model="selected_user.mt_user_id"></v-text-field>
              
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Username" v-model="selected_user.name" hint="This will be the users new username"></v-text-field>
              
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field          
                label="Password" hint="At least 8 characters" min="8" type="password"  v-model="selected_user.password"></v-text-field>                           
            </v-list-item-content>
          </v-list-item>
            <v-list-item>
            <v-list-item-content>
              <v-list-item-title>E-mail</v-list-item-title>
              <v-text-field placeholder="E-Mail" v-model="selected_user.mail"></v-text-field>              
            </v-list-item-content>
          </v-list-item>
            <v-list-item>
            <v-list-item-content>
              <v-list-item-title>VIP Membership Ends:</v-list-item-title>                                        
                   <v-row justify="">
    <v-date-picker v-model="selected_user.vip_expiry" color="green lighten-1 float-left"></v-date-picker>
    
  </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
      <v-subheader>User Role</v-subheader>
      <v-col class="d-flex" cols="12" sm="4">
        <v-combobox
          v-model="selected_role"
          @change="selectRole"
          :items="roles"
          label="Role"
          :hint="selected_role.info"
          persistent-hint
        ></v-combobox>
        <p></p>
      </v-col>

          <v-subheader>General</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="selected_user.notify_wall"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle>Users preference regarding notification</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="selected_user.banned"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Banned</v-list-item-title>
              <v-list-item-subtitle>Ban or Unban a user here</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          
          
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>



<v-row justify="center">
    <v-dialog v-model="new_user_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="new_user_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{new_user.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="newUser()">Create User</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>User Information</v-subheader>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Username" v-model="new_user.name" hint="This will be the users new username"></v-text-field>
              
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field          
                label="Password" hint="At least 8 characters" min="8" type="password"  v-model="new_user.password"></v-text-field>                           
            </v-list-item-content>
          </v-list-item>
            <v-list-item>
            <v-list-item-content>
              <v-list-item-title>E-mail</v-list-item-title>
              <v-text-field placeholder="E-Mail" v-model="new_user.mail"></v-text-field>              
            </v-list-item-content>
          </v-list-item>
            <v-list-item>
            <v-list-item-content>
              <v-list-item-title>VIP Membership Ends:</v-list-item-title>                                        
                   <v-row justify="">
    <v-date-picker v-model="new_user.vip_expiry" color="green lighten-1 float-left"></v-date-picker>
    
  </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>General</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="new_user.notify_wall"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle>Users preference regarding notification</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
         
          
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>



<SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Home',    
    props: {
      source: String
    },
     data: () => ({   
        page            : 1,
        user            :[],
        loading         : true,        
        users           :[],
        selected_user   :[],
        roles           :[{id:0,text:"Role",info:"Basic access"}],
        selected_role   :[],
        new_user        :{name:"",mail:"",password:"",vip_expiry:"",notify_wall:""},
        new_user_dialog :false,
        dialog          :false,
        search: '',
        headers: [
          { text: 'User Id', value: 'id' },
          { text: 'Role', value: 'role_name' },
          { text: 'Name', value: 'name' },
          { text: 'E-Mail', value: 'mail' },
          { text: 'Member Since', value: 'member_date' },
          { text: 'VIP Expires', value: 'vip_expiry' },
          { text: 'Last IP', value: 'last_ipaddress' },
          { text: 'Notifications', value: 'notify_wall' },          
          { text: 'Banned', value: 'banned' },   
          { text: 'Invites', value: 'invites_available' },   
          { text: 'MT ID', value: 'mt_user_id' } 
          ]

        }),
  components:{
"SiteConfig":SiteConfig
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{
    selectRole()
    {
        
    },
    newUser()
    {
         let self = this;
    var datastring = JSON.stringify(self.new_user);          
          $.post( self.$root_url + "/webapi/user/new_user.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
            .done(function( data ) {              
                let result = $.parseJSON(data);
              if(result.status==0)
              {                             
                self.$swal('Failed', result.reason, "error");                        
              }else
              {
                this.dialog = false;
                self.$swal.fire(
                  'Updated!',
                  'The user was successfully created',
                  'success'
                );                
                }          
            });  

    },
      deleteUser()
      {       
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently remove ' + this.selected_user.name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete' + this.selected_user.name
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_user);      
              $.post( self.$root_url + "/webapi/user/delete_user.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {              
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    this.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The user was successfully deleted',
                      'success'
                    );
                    
                    let index = self.users.indexOf(self.selected_user);
                    if (index > -1) {
                      self.users.splice(index, 1);
                      }
                    }          
                });  
            }
          })

      },
      saveUser()
      {
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'Make sure everything is correct before you go on',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {              
            self.selected_user.role = self.selected_role.id;
            var datastring = JSON.stringify(self.selected_user);      
            
        $.post( self.$root_url+ "/webapi/user/save_user.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
          .done(function( data ) {                    
              let result = $.parseJSON(data);
            if(result.status==0)
            {                             
               self.$swal('Failed', result.reason, "error");                        
            }else
            {
              this.dialog = false;
               self.$swal.fire(
                'Updated!',
                'The user was successfully updated',
                'success'
              )         

            }          
              });             
            }
          })
      },

      rowClick: function (item, row) {      
        let self = this;
      row.select(true);
      this.selected_user = item;
       
       self.roles.forEach(function(entry) {
          if(entry.id==item.role)
          self.selected_role = entry;
        });

      if(this.selected_user.notify_wall > 0) this.selected_user.notify_wall = true; else this.selected_user.notify_wall = false;
      if(this.selected_user.banned > 0) this.selected_user.banned = true; else this.selected_user.banned = false;
      this.dialog = true;
    }   
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/user/users.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {        
        self.users = data.users;
        self.roles = data.roles;
        self.loading = false;       
        
    });


    }
  }
</script>

