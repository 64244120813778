<template>
<div class="mt-4">
     <h3  style=""><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-movie</v-icon> Recently Added Movies</h3>
     <v-divider></v-divider>
    <v-slide-group
      
      class="mt-4"      
      mobile-breakpoint="1"
    >
      <v-slide-item
        v-for="card in this.$parent.recent_movies"
        :key="card.id"
      >

<div class="custom_card">  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear >   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                    <h5><b>{{card.title}}</b><br></h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" >
            <v-list-item style="background-color:#000000;">                
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('GotoMovie',card)" text small color="white"><v-icon>mdi-movie</v-icon><span class="ml-2">Go to Movie</span></v-btn>                                                
            </v-list-item>          
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
          </v-list-item>                         

        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>              
            </div>
      </v-slide-item>
    </v-slide-group>
<div style="margin-top:10px;">  
     <h3  style=""><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-movie</v-icon> Recently Released Movies</h3>
     <v-divider></v-divider>



    <v-slide-group
          
      class="mt-4"      
      mobile-breakpoint="1"
    >
      <v-slide-item
        v-for="card in this.$parent.recent_released"
        :key="card.id"
      >

<div class="custom_card">  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear >   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                    <h5><b>{{card.title}}</b><br></h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" >
            <v-list-item style="background-color:#000000;">                
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('GotoMovie',card)" text small color="white"><v-icon>mdi-movie</v-icon><span class="ml-2">Go to Movie</span></v-btn>                                
            </v-list-item>          
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
            </v-list-item>                         
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>              
            </div>
      </v-slide-item>
    </v-slide-group>
</div>
<div style="margin-top:-30px;">

<h3  class="mt-12"><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-remote-tv</v-icon> Recently Added TV Episodes</h3>
           <v-divider></v-divider>
  <v-slide-group
      
       class="mt-4"
      mobile-breakpoint="2"
    >
      <v-slide-item
        v-for="card in this.$parent.recent_uploads"
        :key="card.id"
  
      >
           <div class="custom_card">  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear >   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                    <h5><b>{{card.title}}</b><br>
                {{" S" + card.season + "E" + card.episode}}</h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" >
            <v-list-item style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px;" text small color="white" @click="action_menu_click('Gotoshow',card)" ><v-icon>mdi-remote-tv</v-icon> <span class="ml-2">Go to show</span></v-btn>                            
            </v-list-item>
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
            </v-list-item>                         

            <v-list-item v-if="card.follow_id<=0" style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Sub',card)" text small color="#d4af37"><v-icon>mdi-heart</v-icon><span class="ml-2"> Subscribe</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id>0"  style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Unsub',card)" text small color="#d4af37"><v-icon>mdi-heart-off</v-icon><span class="ml-2">Unsubscribe</span></v-btn>
            </v-list-item>             
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>              
            </div>

      </v-slide-item>
</v-slide-group>
</div>
<div style="margin-top:-30px;">
<h3  class="mt-12"><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-remote-tv</v-icon> Recently Aired TV Episodes</h3>
           <v-divider></v-divider>
  <v-slide-group
      
       class="mt-4"
      mobile-breakpoint="2"
    >
      <v-slide-item
        v-for="card in this.$parent.recent_episodes"
        :key="card.id"
  
      >
           <div class="custom_card">  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear >   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                    <h5><b>{{card.title}}</b><br>
                {{" S" + card.season + "E" + card.episode}}</h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" >
            <v-list-item style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px;" text small color="white" @click="action_menu_click('Gotoshow',card)" ><v-icon>mdi-remote-tv</v-icon> <span class="ml-2">Go to show</span></v-btn>                            
            </v-list-item>
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
          </v-list-item>                                   
            <v-list-item v-if="card.follow_id<=0" style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Sub',card)" text small color="#d4af37"><v-icon>mdi-heart</v-icon><span class="ml-2"> Subscribe</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id>0"  style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Unsub',card)" text small color="#d4af37"><v-icon>mdi-heart-off</v-icon><span class="ml-2">Unsubscribe</span></v-btn>
            </v-list-item>             
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>              
            </div>

      </v-slide-item>
</v-slide-group>
</div>
<div class="mt-2">
     <h3  style=""><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-tennis-ball</v-icon> Latest PPV</h3>
     <v-divider></v-divider>



    <v-slide-group
         
      class="mt-4"      
      mobile-breakpoint="1"
    >
      <v-slide-item
        v-for="card in this.$parent.recent_sports"
        :key="card.id"
      >

<div class="custom_card">  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear >   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                    <h5><b>{{card.title}}</b><br></h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" >
            <v-list-item style="background-color:#000000;">                
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('GotoMovie',card)" text small color="white"><v-icon>mdi-tennis-ball</v-icon><span class="ml-2">Go to Event</span></v-btn>                                
            </v-list-item>         
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
          </v-list-item>                          
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>              
            </div>
      </v-slide-item>
    </v-slide-group>

</div>
<v-row>
    <v-dialog v-model="show_playlists"  transition="dialog-bottom-transition" width="500">      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_playlists = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add To Playlist</v-toolbar-title>
          
          <v-spacer></v-spacer>
          
             <v-toolbar-items>
            
            <v-btn color="green" dark text @click="add_to_playlist()">Done</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>      
          <v-list-item>
            <v-list-item-content >                              
                <v-select v-model="selected_playlist"
                  :items="playlists"
                  item-text="name"
                  
                    return-object></v-select>              
            </v-list-item-content>
            </v-list-item>
          
        </v-list>
      </v-card>
    </v-dialog>
 </v-row>  
</div>
</template>

<style >
@media only screen and (max-width: 600px) {  
  .v-slide-group__prev
  {
    min-width: 20px;    
    top: 0;
    height: 104px;
    position: absolute;
   
    z-index: 99;
  }
   .v-slide-group__prev .v-icon
   {
       
        height: 50px;
      width: 50px;
      border-radius: 15px;
      background-color: rgba(0,0,0,0.4);
   }
     .v-slide-group__prev .v-icon
   {
       
        height: 50px;
      width: 50px;
      border-radius: 25px;
      background-color: rgba(27,17,20,0.9);
      color: white;
      font-size: 28pt;
   }
  .v-slide-group__next
  {
    min-width: 20px;    
    top: 0;
    right:-10px;   
    position: absolute;  
    z-index: 99;
    height: 104px;
  }
  .v-slide-group__next .v-icon
   {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      background-color: rgba(27,17,20,0.9);
      color: white;
      font-size: 28pt;
   }
}
</style>


<style  scoped>
@media only screen and (max-width: 600px) {
  .col-lg-2{
    max-width: 20%;
  }
}
@media only screen and (max-width:1200px) {
  .col-lg-2{
    max-width: 20%;
  }
}
@media only screen and (max-width:520px) {
  .col-lg-2{
    max-width: 100%;
  }
}
@media only screen and (min-width:1200px) {
  .col-lg-2{
    max-width: 10%;
  }
}

</style>


<script>
import $ from 'jquery';
import router from '../router.js' 
  export default {
    name: 'RecentCards',
    props: {
      source: String
    },
     data: () => ({
       user: [],
       posterNA:'',
       show_playlists:false,
       playlists:[],
       selected_playlist:[],
       selected_video:[],       
     action_menu: [
        { title: 'Add to favorites', icon: "mdi-heart", color:"white"},
        { title: 'Go Premium' ,icon: "mdi-star",color:"#d4af37"},
        { title: 'Help' ,icon: "mdi-help",color:"white"},
        { title: 'Sign Out' ,icon: "mdi-exit-run",color:"white"}
    ]
  }),

  created () {  
        this.posterNA = this.$root_url + '/img/poster_NA.png';
        this.user = $.parseJSON(localStorage.getItem('user'));
        
  },
  methods:{
    add_to_playlist()
      {
        
      let self = this;
      if(this.selected_video.ep_id>0)      
        this.selected_video.id = this.selected_video.ep_id;
          
      var playlist_data = JSON.stringify(this.selected_playlist);   
      var vid_data = JSON.stringify(this.selected_video);   

        $.post( self.$root_url + "/webapi/videos/playlist_add.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { playlist_data: playlist_data, video_data:vid_data})
              .done(function( data ) {              
                  
                  let result = $.parseJSON(data);
                        if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                    self.show_playlists = false;
                  }else
                  {
                    self.show_playlists = false;
                    self.$swal( "Done",self.selected_video.title + " was added to playlist " + self.selected_playlist.name);
                  }         
          }); 
   
      },
    _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
       action_menu_click (menu,card) {
         
         var datastring ="";
         var result = false;
         let self = this;
            switch(menu)
            {
                case "GotoMovie":
                   router.push({ name: 'play', params: {vid: card.id} }); 
                  break;
                case "Gotoshow": 
                     router.push({ name: 'season', params: {showid: card.id} }); 
                break;
                case "AddToPlaylist" :                     
                      $.getJSON(self.$root_url + "/webapi/videos/playlists.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&p=1", function(data) {
                        self.selected_video = card;
                        self.show_playlists = true;
                        self.playlists = data.items;                
                        self.loading = false;                        
                    });
                break;
                case "Sub": 
                 
                 if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
                  }
                      datastring = JSON.stringify({show_id:card.id});
                       result = this._post(this.$root_url + "/webapi/videos/follow.php",datastring);
                  if(result.status==0)                  
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show subscribed','You will now start receiving new episodes notifications from this show.','success');
                    
                    card.follow_id = result.follow_id;
                  }

                break;
                case "Unsub":                                            
                    datastring = JSON.stringify({follow_id:card.follow_id});
                    result = this._post(this.$root_url + "/webapi/videos/unfollow.php",datastring);
                  if(result.status==0)      
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show unsubscribed','You will no longer receive new episodes notifications from this show.','success');        
                    card.follow_id = 0;
                  }
                  


                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
            }
            },
        play_item(card)
        {            
            
            if(card.cat_id>1 && card.cat_id<4)
             router.push({ name: 'play_season', params: {showid: card.id,season:card.season,episode:card.episode} });    
             else
             router.push({ name: 'play', params: {vid: card.id} });             
        },        
        

  },
  
  }
</script>

