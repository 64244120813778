<template>
  <v-app>    
    <div id="main-page">
     <v-card width="400" class="mx-auto mt-5" color="rgba(0,0,0,0.7)">
       <v-card-title color="">
         <h1 class="display-1 white--text" >Recover Account</h1>
       </v-card-title>
       <v-card-text>
         <v-form v-model="valid" id="KebabForm">

            <v-text-field label="E-mail"
              prepend-icon="mdi-email"
              dark
              :rules="emailRules" 
              required
              name="Email"/> 

           

         </v-form>
       </v-card-text>
       <v-divider></v-divider>
       <v-card-actions>        
        <v-spacer></v-spacer>
        <v-btn color="info" @click="recover()" :disabled="!valid"  dark>Recover</v-btn>
       </v-card-actions> 

     </v-card>     
    </div>
    <SiteConfig/>
  </v-app>
</template>


<script>
import $ from 'jquery';
import router from '../router.js' 
import SiteConfig from '../components/SiteConfig'
export default {
  name: 'Recover',
  components: { 
    "SiteConfig":SiteConfig
  },
  methods: {
      recover () {
        let self = this;
        var form = $('#KebabForm').serializeArray();
        var formdata = {};
        $.each(form,
        function(i, v) {
          formdata[v.name] = v.value;
        });
        
        var datastring = JSON.stringify(formdata);
        

        $.post( self.$root_url + "/webapi/user/recover.php", { data: datastring})
          .done(function( data ) {
              let user = $.parseJSON(data);
            if(user.status==0)
            {                             
               self.$swal('Recover failed', user.reason, "error");                        
            }else
            {
                self.$swal('Recover successful', user.reason, "success").then(
                  function () {  router.push({ name: 'home' }); }
                );            

            }
          
        });
      }
    },
    data: () => ({
        valid: true,
        showPassword: false,
        showRepeat: false,          
          nameRules: [
              (v) => !!v || 'Name is required',
              (v) => (v || '').length >= 6 || 'Name must be at least 6 characters long',
              (v) => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
            ],           
            emailRules: [
              (v) => !!v || 'E-mail is required',
              (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],            
            passwordRules: [
              (v) => !!v || 'Password is required',
              (v) => (v || '').length >= 8 || 'Password must be atleast 8 characters long',
              (v) => !!v || 'Password is required',                  
            ],
            inviteRules: [
              (v) => !!v || 'Invitation is required',
              (v) => (v || '').length == 20 || 'Invite code invalid',
            ]
    })
};
</script>
<style>

</style>