<template>

      <div style="text-align:center; background-color:rgba(0,0,0,0.4)" >  
          <h1 class="red">ADBlock Detected</h1>        
          <h4 class="mt-6 yellow--text">              
              Look we understand that ADs are annoying AF, but we rely on them to support the site and keep bringing you free entertainment.<br>So please disable your adblock for this site. <br> Kind regards<br>// Admins</h4>
            <img :src="this.$root_url + '/img/disable_ab.gif'" style="border-radius:15px;"/>              

        <SiteConfig />    
      </div>
</template>
<script>
import SiteConfig from '../components/SiteConfig'
  export default {

    name: 'DisableAB',    
    props: {
      source: String
    },
     data: () => ({   
        }),
  components:{
      "SiteConfig":SiteConfig,
  },
  created(){
  },
    mounted() { 
    }
  }
</script>

