import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router';
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';

//import 'vue-plyr/dist/vue-plyr.css'
import 'video.js/dist/video-js.css'
//Vue.prototype.$root_url = 'https://beta.mobilevids.org';
Vue.prototype.$root_url = 'https://mobilevids.org';

Vue.config.productionTip = false
Vue.config.silent = false
Vue.config.devtools = false;
Vue.config.performance = true;
require('./assets/css/main.css')

import 'sweetalert2/dist/sweetalert2.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(VueSweetalert2);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
