<template>

<v-row>
      <v-list style="background-color:rgba(0,0,0,0); margin-left:0px; width:100%;">        
        <v-list-item-group color="white">
          
          <v-list-item
            v-for="(card, i) in this.$parent.cards"
            :key="i"
            class="mt-1"            
              style="background-color:rgba(0,0,0,0.62); height:100px;  border-radius:5px; overflow:hidden;"
               @click="play_item(card)" >            
              <v-img
               :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                
                contain="1"
                style="height:100px; max-width:68px; margin-left:-20px; -webkit-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
-moz-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6); "
                
              >
              </v-img>            
            <v-list-item-content style="padding:0px;">
              <v-list-item-title><p class="ml-2 mt-4">{{card.title}}</p>              
              <div  class="ml-2" style="font-size:9pt; width:100%;" v-if="view=='movies'">
               <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>  
               <span class=" orange--text"><br>Runtime: </span><span>{{toHHMMSS(card.runtime)}}</span>
               

                <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>
                
                </div>

                <div  class="ml-2" style="font-size:9pt; width:100%;" v-if="view=='tv'">
                  <p style="color:gray; margin-top:-2px;">Latest Episode {{card.episode_info}}</p>
                  <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                                      
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>
                
                </div>


      
                                
              <p  class="ml-2" style="font-size:9pt;" v-if="view=='unwatched_episodes'">S{{card.season}}E{{card.episode}}  <span class="float-right text-right" style="margin-top:-40px;"> <b style="color:gray;">Added</b><br><br>{{ card.add_date.split(" ")[0]}} </span> </p>
              
              <div  class="ml-2" style="font-size:9pt; margin-top:-10px;" v-if="view=='now_watching_tv'">{{card.episode_info}}<br>                              
                watched by  <a v-if="view=='now_watching_tv'" style="margin-left:0px; text-decoration:none; padding-left:10px; text-right;" :href="'/profile/'+card.user_id" color="primary">                 
               <v-avatar  size="avatarSize">
                 <img style="width:32px; height:32px;" class="imgUser" :src="root_url +'/webapi/user/photo.php?user_id='+card.user_id" alt="alt">
               </v-avatar>  
            
            {{card.name}} <span style="color:#bfbfbf; font-size:10pt;"> - {{timeSince(card.add_date)}} ago</span>
            </a>                                   
                </div>
                

            
              
              </v-list-item-title>
              
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
</v-row>

    
</template>
<script>

import router from '../router.js' 
  export default {
    name: 'VideoList',
    props: {
        view: {
          type: String
          }
        },
     data: () => ({  
        posterNA: '',
        $root_url:'',
      
     action_menu: [
        { title: 'Add to favorites', icon: "mdi-heart", color:"white"},
        { title: 'Go Premium' ,icon: "mdi-star",color:"#d4af37"},
        { title: 'Help' ,icon: "mdi-help",color:"white"},
        { title: 'Sign Out' ,icon: "mdi-exit-run",color:"white"}
    ]
  }),
  created () {      
        this.posterNA = this.$root_url + '/img/poster_NA.png';
      this.root_url = this.$root_url;
  },
  methods:{
    genres(genres)
      {     
        try{
            let res = "";
            genres = JSON.parse(genres);
            if(genres!==undefined)
            genres.forEach(function(key) {
                res = res + key + ", ";
            });      
            res = res.substring(0, res.length - 2);
            return res;
        } catch(error)  
        {
          return "";
        }
       
    },

      toHHMMSS(mins) {
    var secs = mins * 60;
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60
    if(isNaN(seconds) || isNaN(minutes))
    {
        return "0:00:00";
    }
    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
    },
    onError (card) {
      let self = this;
        var i;   
            for (i = 0; i < this.$parent.cards.length; i++) {
                if(this.$parent.cards[i].id==card.id)
                {                           
                    this.$parent.cards[i].poster_thumbnail = self.$root_url + "/img/poster_NA.png";
                    break;
                }
            }
                               
        },
        action_menu_click (menu,card) {
            switch(menu.title)
            {
                case "Add to favorites": 
                     alert(card.title); 
                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
            }                        
        },
         play_item(card)
        {            
            
          if(card.cat_id>1 && card.cat_id<4)
            if(this.view=="unwatched_episodes")
              router.push({ name: 'play_season', params: {showid: card.show_id, season:card.season, episode:card.episode} });
              else
             router.push({ name: 'season', params: {showid: card.id} });   
          else
             router.push({ name: 'play', params: {vid: card.id} });             
        },
        timeSince(date) {
          var tempDate = new Date(date);
          var seconds = Math.floor((new Date() - tempDate) / 1000);
          var interval = seconds / 31536000;
          if (interval > 1) {
            return Math.floor(interval) + " years";
          }
          interval = seconds / 2592000;
          if (interval > 1) {
            return Math.floor(interval) + " months";
          }
          interval = seconds / 86400;
          if (interval > 1) {
            return Math.floor(interval) + " days";
          }
          interval = seconds / 3600;
          if (interval > 1) {
            return Math.floor(interval) + " hours";
          }
          interval = seconds / 60;
          if (interval > 1) {
            return Math.floor(interval) + " minutes";
          }
          return Math.floor(seconds) + " seconds";
      }

  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    
   
    }
  }
</script>

