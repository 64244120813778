<template>
  
     
      <v-row align="start" class="fill-height">
        <v-col cols="12"
        >
     <v-card width="full" align="start" class="" color="rgba(0,0,0,0.7)">
       <v-card-title >
         <h1 class="display-1 mx-auto" style="color:white;">Account</h1>
       </v-card-title>
       <v-card-text>
         <v-form id="LoginForm" v-model="valid">
           <v-divider></v-divider>    
           <v-container>
             <v-row>
             <v-col cols="8">
               <v-btn v-if="user.vip != 'true'" justify="end" color="rgb(212, 175, 55,0.7)" to="/premium">Go Premium</v-btn>
               <v-btn v-if="user.vip == 'true'" justify="end" color="rgb(212, 175, 55,0.7)" to="/premium">Manage Premium</v-btn>

               <v-btn class="ml-2" justify="end" color="blue" :to="'/profile/'+user.id">View Profile</v-btn>               
             </v-col>             
             <v-col cols="4" >
               <v-row justify="end">
                  
               </v-row>
             </v-col>
             </v-row>
                                              
           </v-container>
      <v-divider></v-divider> 

          <v-container>
             <v-row>
             <v-col cols="8">
               <h4 class="ml-12">Photo</h4>
               <v-avatar class="mt-2" size="avatarSize" @click="browse_file">
                 <img class="imgUser" :src="user.image!=='' ? this.$root_url + '/webapi/user/photo.php?user_id='+user.id : this.$root_url + '/img/user.png'" alt="alt">
               </v-avatar>               
             </v-col>             
             <v-col cols="4">  
               <v-row justify="end">             
                      <v-btn @click="browse_file" text small class="ml-7">Update</v-btn>                      
               </v-row>
             </v-col>             
             </v-row>                                     
           </v-container>

      <v-divider></v-divider> 

          <v-container>
             <v-row>
             <v-col cols="12">
                 <v-text-field label="Name" type="text" required dark name="Name" :value="user.name" readonly />                      
             </v-col>                          
             </v-row>                                     
           </v-container>

      <v-divider></v-divider> 
      <v-container>
             <v-row>
             <v-col cols="12">
                 <v-text-field label="Email" type="text" required dark name="Email" :value="user.mail" readonly />                      
             </v-col>             
             <v-col cols="0">               
               <v-row justify="end">
                                          
             </v-row>
             </v-col>             
             </v-row>                                     
           </v-container>
      <v-divider></v-divider> 
          <v-container>
             <v-row>
             <v-col cols="12">
                 <v-text-field label="New Password" type="text" required dark name="Old_PW" v-model="user.new_password" />                      
             </v-col> 
               <v-col cols="12">
                 <v-text-field label="Old Password" type="text" required dark name="New_PW"  v-model="user.old_password" />                      
             </v-col>                          
             </v-row>                                     
           </v-container>

            <v-container>
             <v-row>
             <v-col cols="12">
                    <v-switch label="Notify me on new episodes, news and more." v-model="user.notify_wall"></v-switch>
             </v-col>             
             <v-col cols="0">               
               <v-row justify="end">
                                           
             </v-row>
             </v-col>             
             </v-row>                                     
           </v-container>

      <v-divider></v-divider> 

         </v-form>
       </v-card-text>

       <v-card-actions>        
        <v-spacer></v-spacer>
        <v-btn color="info"
        dark
        @click="save_user()" 
        :disabled="!valid"
        :loading="data_loading">Save</v-btn>
       </v-card-actions>

     </v-card>    
        </v-col>
        <form id="form_photo" target="iframe" :action="this.$root_url + '/webapi/user/update_photo.php'" method="post" enctype="multipart/form-data">
          <input name="file" type="file" id="fileBrowser" style="display:none;" />    
          <input name="user_id" type="hidden" :value="user.id" />   
          <input name ="token" type="hidden" :value="user.auth_token" />           
      </form>

<iframe name="iframe" id="iframe" style="display:none;" ></iframe>
      <SiteConfig />
      </v-row>
     
</template>


<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'



export default {
  name: 'Account',   
  data: () => ({   
      data_loading : false,   
      valid: true,
      user: []
  }),
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{     
save_user()
      {
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'Make sure everything is correct before you go on',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {            
            var datastring = JSON.stringify(self.user);        
        $.post( self.$root_url+ "/webapi/user/save_account_settings.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
          .done(function( data ) {                        
              let result = $.parseJSON(data);
            if(result.status==0)
            {                             
               self.$swal('Failed', result.reason, "error");                        
            }else
            {
              this.dialog = false;
               self.$swal.fire(
                'Updated!',
                'The user was successfully updated',
                'success'
              )         

            }          
              });             
            }
          })
      },
    browse_file(){
      var fileSelector = $('#fileBrowser');
      fileSelector.click();
    fileSelector.on("change", this.file_selected);
    },
    file_selected()
    {      
      $(".imgUser").attr("src","https://via.placeholder.com/150");
      $("#form_photo").submit();
    }
  },
  components: { 
    "SiteConfig":SiteConfig,
  },
  mounted(){
    let self = this;
    $('#iframe').bind('load', function(){       
       let html = document.getElementById('iframe').contentWindow.document.body.innerHTML;

        let json = JSON.parse(html);
        if(json.status==0)
        {
          
         self.$swal('Photo upload', html.reason, "error");     
        }
        else
        {
          $(".imgUser").attr("src",self.$root_url + '/webapi/user/photo.php?user_id='+self.user.id );
         self.$swal("Photo upload", html.reason, "success");    
        }
    });
     
  }
};
</script>