<template>
  
     
      <v-row align="start" class="fill-height">
        <v-col cols="12"
        >
     <v-card width="full" align="start" class="" color="rgba(0,0,0,0.7)">
       <v-card-title >
         <h1 class="display-1 mx-auto" style="color:white;">Premium</h1>
       </v-card-title>
       <v-card-text>

           <v-container>                       
             <v-row>
             <v-col cols="12">               
               <h2 style="color:#d4af37;">Status</h2>
              <p v-if="this.user!=null && this.user.vip=='true'">You are currently a Premium member and your membership expires {{this.user.vip_expiry}}</p>
              <p v-if="this.user!=null && this.user.vip=='false'">You are currently  not a Premium member. <span v-if="this.user.vip_expiry!='' &&this.user.vip_expiry!=null"><br>Membership Expired: {{this.user.vip_expiry}}</span></p>                   
              <h2 style="color:#d4af37;">Benefits</h2>
              <ul>
                <li>Family share (3 devices on same WIFI/Network).</li>
                <li>High quality stream (720P & 1080P (5.1)</li>                
                <li>Ability to automatically search for Subtitles and download.</li>   
                <li>Subscribe and get notifications to your favorite shows </li>
                <li>Download videos to your device</li>
                <li>Download whole seasons directly</li>
                <li>Access to OnDeck where you can see all your unfinished shows</li>
                <li>Access to Live TV with hundres of channels</li>
                <li>Access to Live TV Schedule</li>
                <li>You will get a Premium Crown next to your name.</li>
                <li>NO ADS</li>
                <li>Most importantly you will support mobilevids and us to keep giving you amazing features and entertainment.</li>
              </ul>
              <h2 class="mt-2" style="color:#d4af37;">How?</h2>
              <p>If you are new,  visit <a href="https://mediatrunk.net">https://mediatrunk.net</a> and create a new account and extend the account to a payed account.<br>Once you have done that, use the form below to link your mediatrunk account to mobilevids, login using the username and not E-mail. </p>

             </v-col>                          
             </v-row>                                     
           </v-container>
<v-divider></v-divider> 
   

         <v-form id="PremiumForm" v-model="valid">    

           <p class="warning black--text pt-2 pb-2 pl-2" v-if="user.mt_user_id>0">You have already linked an account. Only relink account if you have a new MT Account and would like to replace with the new account.</p> 
        <form name="loginForm" id="loginForm" method="POST" action=""
          data-ajax="false">                     
          <v-container>                       
             <v-row>
                 <h3>Use your existing Mediatrunk account to link with your Mobilevids account. You only need to do it once.</h3>
             <v-col cols="12">                 
                              
    
                 <v-text-field label="Username" type="text" v-model="username" required dark name="loginUsername" />                      
             </v-col>                          
             </v-row>                                     
           </v-container>

      <v-divider></v-divider> 
      <v-container>
             <v-row>
                 
             <v-col cols="12">
                 <v-text-field label="Password" type="password" v-model="password" required dark name="loginPassword" />                      
             </v-col>             
             <v-col cols="0">               
               <v-row justify="end">
                                          
             </v-row>
             </v-col>             
             </v-row>                                     
           </v-container>
      <v-divider></v-divider> 
           <input name="user_id" id="user_id" type="hidden" :value="user.id">
        </form>
         </v-form>
       </v-card-text>

       <v-card-actions>        
        <v-spacer></v-spacer>
        <v-btn
        color="#d4af37"
        @click="loginUser()" 
        :disabled="!valid"
        :loading="data_loading">Link Account</v-btn>
       </v-card-actions>

     </v-card>    
        </v-col>
        <SiteConfig />
      </v-row>
     
</template>


<script>
import $ from 'jquery';

import SiteConfig from '../components/SiteConfig'


export default {
  name: 'Premium',   
  data: () => ({   
      data_loading : false,   
      username: "",
      password:"",
      valid: true,
      user: []
  }),
   created () {
        this.user = $.parseJSON(localStorage.getItem('user'));

  },

  methods:{     
      loginUser: function()
      {
          let self = this;
            
          $.post( self.$root_url  + "/webapi/user/login_vip.php?user_id="+self.user.id +"&token="+self.user.auth_token, { username:this.username,password:this.password})
          .done(function( data ) {     
                         
              let result = $.parseJSON(data);              
            if(result.status==0)
            {                             
               self.$swal('Failed', result.reason, "error");     

            }else
            {
              this.dialog = false;
               self.$swal.fire(
                'Updated!',
                'The user was successfully updated, you need to logout and back in for changes to take effect.',
                'success'
              )         

            }          
              });   

      }
  },
  components: { 
    "SiteConfig": SiteConfig
  },

  mounted(){

  }
};
</script>