<template>

      <v-container functional>  
         <h2  style="position:absolute; top:40px; width:100%">Video Reports           
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h2>   
               
      <v-card 
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0">    
   
     <v-divider></v-divider>
       

    <v-card-title >      
      
  
     
    </v-card-title>
            <v-tabs
        class="mt-6"
      background-color="rgba(0,0,0,0.7)"
      color="amber darken-2"
      left           
    >
        <v-tab  @click="changeTab(0)">Unfixed</v-tab>
    <v-tab @click="changeTab(1)">Fixed</v-tab>  
    <v-spacer></v-spacer>
     <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class=""
      ></v-text-field> 
       </v-tabs>
<div v-if="selected_tab==0">
    <v-data-table 
      :headers="headers"
      :items="unfixed_reports"
      :search="search"
       v-model="datatable_unfixed"
    multi-sort
    multiple
    show-select
    
    >    
    </v-data-table>
    <v-btn @click="mark_fixed()" class="mt-2 float-right green">Mark as fixed</v-btn>
</div>
<div v-if="selected_tab==1">
    <v-data-table 
    :headers="headers"
    :items="fixed_reports"
    :search="search"
    v-model="datatable_fixed"
    multi-sort
    multiple
    show-select>    
    </v-data-table>
    <v-btn @click="mark_unfixed()" class="mt-2 mr-2 float-right ">Mark as unfixed</v-btn>
</div>    
    <v-btn @click="delete_reports()" class="mt-2  mr-2  float-right red">Delete</v-btn>      
      </v-card>            



<SiteConfig/>

      </v-container>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Home',    
    props: {
      source: String
    },
     data: () => ({   
        page            : 1,
        user            :[],
        loading         : true,     
        selected_tab    : 0,   
        selected_report :[],
        new_post        :[],
        fixed_reports   :[],
        unfixed_reports   :[],
        datatable_fixed:[],
        datatable_unfixed:[],
        news_dialog :false,
        dialog              :false,
        search: '',
        headers: [
          { text: 'Status', value: 'fixed' },           
          { text: 'Title', value: 'title' },           
          { text: 'Info', value: 'info' },           
          { text: 'Broken Video', value: 'broken_video' },
          { text: 'Broken Audiovisual', value: 'broken_audiovisual' },
          { text: 'Wrong Video', value: 'wrong_video' },
          { text: 'Comment', value: 'comment' }                     
           ]

        }),
  components:{
"SiteConfig":SiteConfig
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{
    changeTab(index)
    {                    
        this.selected_tab = index;
    },
    delete_reports()
      {
        let self = this;
    var datastring = JSON.stringify(self.datatable_unfixed);  
    if(this.selected_tab==1)
    {
            datastring = JSON.stringify(self.datatable_fixed);  
    }

        $.post( self.$root_url + "/webapi/videos/update_video_report.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring,fixed: "delete"})
        .done(function( data ) {               
            
                let result = $.parseJSON(data);
                if(result.status==0)
                {                             
                self.$swal('Failed', result.reason, "error");                        
                }else
                {
                    if(self.selected_tab==0)
                    {
                        self.datatable_unfixed.forEach(function(key) {                            
                            let index = self.unfixed_reports.indexOf(key);
                            if (index > -1) {
                            self.unfixed_reports.splice(index, 1);
                            }                                                        
                        }); 
                    }else
                    {
                        self.datatable_fixed.forEach(function(key) {                            
                            let index = self.fixed_reports.indexOf(key);
                            if (index > -1) {
                            self.fixed_reports.splice(index, 1);
                            }                            
                        }); 
                    }
                

                self.dialog = false;
                self.$swal.fire(
                    'Updated!',
                    'Report deleted.',
                    'success'
                );                                                          
                }
            });  
      },
    mark_unfixed()
    {
    let self = this;
      var datastring = JSON.stringify(self.datatable_fixed);  
    if(this.selected_tab==1)
    {
            datastring = JSON.stringify(self.datatable_unfixed);  
    }  
        $.post( self.$root_url + "/webapi/videos/update_video_report.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring,fixed: "no"})
        .done(function( data ) {               
            
                let result = $.parseJSON(data);
                if(result.status==0)
                {                             
                self.$swal('Failed', result.reason, "error");                        
                }else
                {
                self.dialog = false;
                self.datatable_fixed.forEach(function(key) {                            
                            let index = self.fixed_reports.indexOf(key);
                            if (index > -1) {
                            self.fixed_reports.splice(index, 1);
                            }
                            key.fixed = "-";
                            self.unfixed_reports.push(key);
                });  

                self.$swal.fire(
                    'Updated!',
                    'Reports updated.',
                    'success'
                );                                                          
                }
            });  
    }
    ,
    mark_fixed()
    {
      let self = this;
         var datastring = JSON.stringify(self.datatable_fixed);  
        if(this.selected_tab==1)
        {
                datastring = JSON.stringify(self.datatable_fixed);  
        }           
         $.post( self.$root_url + "/webapi/videos/update_video_report.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring,fixed: "yes"})
         .done(function( data ) {        
             
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    
                    self.datatable_unfixed.forEach(function(key) {                            
                            let index = self.unfixed_reports.indexOf(key);
                            if (index > -1) {
                            self.unfixed_reports.splice(index, 1);
                            }
                            key.fixed = "Fixed";
                            self.fixed_reports.push(key);
                    });  
                    self.$swal.fire(
                      'Updated!',
                      'Reports updated.',
                      'success'
                    );                                                           
                  }
              });                                 
    },
      rowClick: function (item, row) {      
      row.select(true);
      this.selected_report = item;      
      this.dialog = true;
    }   
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/videos/get_video_reports.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                
        self.fixed_reports = data.fixed_reports;
        self.unfixed_reports = data.unfixed_reports;
        self.loading = false;        
    });


    }
  }
</script>

