<template >

            <v-container >  
         <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
            
       <h4  style=" width:100%">TVShows</h4>          
          
       <div style="height:20px;"></div>    
       <h5>Sort & Filter</h5>
       <v-row class="mt-1">       
        <v-col cols="12">
          <div>
        <v-combobox dense
            v-model="sort_order"
            :items="sort_opt"
             @input="refreshPage"
            label="Order TVShows By"            
          ></v-combobox>
            <v-combobox dense
            v-model="genre_sort"
            :items="genres_opt"
            @input="refreshPage"
            label="Genres"   
            multiple flat hide-selected clearable
          ></v-combobox>
          
          <v-btn small v-if="order==0" @click="changeOrder(1)" style="margin-top:0px;">Order: Descending</v-btn>
          <v-btn small v-if="order==1" @click="changeOrder(0)" style="margin-top:0px;">Order: Ascending</v-btn>
          </div>
        </v-col>    
       </v-row>
       <v-switch color="#d4af37" v-model="switch1" inset :label="` ${switch1 ? 'Cards' : 'List'}`"         
        class="float-right"     style="margin-top:-40px;"></v-switch> 
        <VideoList view="tv"  v-if="!switch1" />
        <VideoCards view="tv" v-if="switch1" />

        <v-pagination
        v-model="page"
           color="#d4af37"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12 pr-5 pl-5"
      ></v-pagination>
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';

import VideoCards from '../components/Video_Cards'
import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'TVShows',    
   
    props: {
      source: String
    },
     data: () => ({   
          page: 1,
        total_pages:1,
        switch1: true,
        loading: true,
        sort_order: "Name",
        genre_sort: [],
        order:0,
        user:[],        
         cards: [],
         genres_opt:[          
          'Animation',
          'Action',
          'Adventure',
          'Comedy',
          'Crime',
          'Drama',
          'Documentary',
          'Fantasy',
          'Historical',
          'Historical fiction',
          'Horror',
          'Magical realism',
          'Mystery',
          'Paranoid fiction',
          'Philosophical',
          'Political',
          'Romance',
          'Saga',
          'Satire',
          'Science fiction',                    
          'Social',  
          'Speculative',  
          'Thriller',
          'Urban',
          'Western',
          'Urban',
          'Urban'],
        sort_opt:[
          'Name',
          'Year',
          'Date added',
          'Rating',
          'Duration'],
        }),
  created(){
    this.user =  $.parseJSON(localStorage.getItem('user'));
    if(this.user.view_style!=undefined && (this.user.view_style>0 || this.user.view_style=='true'))
        this.switch1 = true;
    else
        this.switch1 = false;

  },
  components:{
    "VideoCards" : VideoCards,
    "VideoList" : VideoList,
    "SiteConfig" :SiteConfig
  },
  methods:{
               changePage(page)
        {
            var self = this;
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/tvshows.php?p="+page+"&user_id="+self.user.id
             +"&token="+self.user.auth_token+"&sort="+self.sort_order+
             "&genres="+self.genre_sort.join(',') + "&order="+self.order, function(data) {

            self.cards = data.items;                
            self.loading = false;
            });
        }, 
         refreshPage()
        {
           var self = this;
            self.loading = true;
            
            $.getJSON(self.$root_url + "/webapi/videos/tvshows.php?p="+self.page+"&user_id="+
                      self.user.id +"&token="+self.user.auth_token+"&sort="+self.sort_order+
                      "&genres="+self.genre_sort.join(',')+ "&order="+self.order , function(data) {
            self.cards = data.items;                
            self.loading = false;
            });
        },
        changeOrder(order)
        {
          this.order = order;
          this.refreshPage();
        }
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
     $.getJSON(self.$root_url + "/webapi/videos/tvshows.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
        self.loading = false;
        self.cards = data.items;   
        self.total_pages = data.total_pages;
    });
    }, 
     beforeRouteLeave (to, from, next) { 
      this.cards      = [];
      this.user       = [];
      delete this.cards;
      delete this.user;
      next();
     },
  }
</script>

