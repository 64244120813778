<template >
  <v-app style="width:100%; background-color:rgba(0,0,0,0);" >      
    

      <Player ref="player" :options="videoOptions" class="mt-4" />
        
        <v-card
      class="mx-auto mt-8"
        width="100%"    
        style="border-radius:15px; background-color:rgba(0,0,0,.6); padding:15px; color:#FFCC80;"   
      tile
    >
    <h3 style="text-align:center;"> {{video.title}} ({{video.year}})  </h3> 
  
     
     <v-slide-group            
      prev-icon="mdi-minus"
      next-icon="mdi-plus"
      show-arrows
      style="width:100%;"
    >
      <v-slide-item>
        <div id="trailer">
        
        </div>
      </v-slide-item>
      <v-slide-item
        v-for="(item,index) in images"
        :key="index"        
      >
         <img v-if="video.cat_id==4" class="ml-10" :src="item" width="320" height="320"/>
         <img v-else class="ml-10" :src="item" width="420" height="320" />
         
      
      </v-slide-item>
    </v-slide-group>
    
    <p class="float-right movie_imdb" v-if="video.rating!=''">IMDB Rating: <span style="color:#FFFDE7">{{video.rating}}</span> Runtime: <span style="color:#FFFDE7">{{video.runtime}} Min</span> </p>
      <p class="mt-12">Plot: <span style="color:#FFFDE7">{{video.plot}}</span></p>
      <p v-if="genres!=''">Genres: <span v-html="genres"></span></p>
      <p>Cast: <span v-html="casts"></span></p>
      <p v-if="directors!=''">Directors: <span style="color:#FFFDE7" v-html="directors"></span></p>
      <p  v-if="producers!=''">Producers: <span style="color:#FFFDE7" v-html="producers"></span></p>

            
      <v-menu offset-y dark min-width="140" class="" >
            <template v-slot:activator="{ on }" >
            <div class="d-inline-flex float-left mt-1" v-on="on" style="cursor:pointer;" ><v-icon style="    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);">mdi-download</v-icon> <h2>Download</h2></div>                                        
            </template>
            <v-list style="background-color:#000000;" >                     
                <v-list-item style="background-color:#000000;" v-if="video.src_vip_sd != undefined && video.src_vip_sd != ''">
                  <a @click="downloadSingle(video.src_vip_sd)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 480P</span></a>                                                  
                </v-list-item>                                 
                <v-list-item style="background-color:#000000;" v-if="video.src_vip_hd != undefined && video.src_vip_hd != ''">
                  <a @click="downloadSingle(video.src_vip_hd)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 720P</span></a>                                                  
                </v-list-item>                                
                <v-list-item style="background-color:#000000; " v-if="video.src_vip_hd_1080p != undefined && video.src_vip_hd_1080p != ''">
                  <a @click="downloadSingle(video.src_vip_hd_1080p)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 1080P</span></a>                                                  
                </v-list-item>                                  
            </v-list>
            </v-menu>

            <v-btn class="ml-2" color="rgba(10,90,20,0.8)"  @click="getPlaylists()">            
     <v-icon>mdi-playlist-plus</v-icon> Add To Playlist</v-btn>

 
              
      

    </v-card>
 <v-dialog v-model="show_playlists"  transition="dialog-bottom-transition" width="500">      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_playlists = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add To Playlist</v-toolbar-title>
          
          <v-spacer></v-spacer>
          
             <v-toolbar-items>
            
            <v-btn color="green" dark text @click="add_to_playlist()">Done</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>      
          <v-list-item>
            <v-list-item-content >                              
                <v-select v-model="selected_playlist"
                  :items="playlists"
                  item-text="name"
                  
                    return-object></v-select>              
            </v-list-item-content>
            </v-list-item>
          
        </v-list>
      </v-card>
    </v-dialog>

  </v-app>
  
</template>

<script>
    import $ from 'jquery';
    
    import Player from '../components/Player';
    
  export default {
    data: () => ({   
        qualities: [],                        
        btnSearchSubs: false,
        btnSubtitle: "Get Subtitles",        
        next_timer: 10,
        show_playlists:false,
        selected_playlist:null,
        selected_video:null,
        playlists:[],
        video:[],   
        images:[],           
        user:[],
        loaded:false,
        videoOptions:{
          autoplay: true,
          controls: true,
          nativeTextTracks: false,
           sources: [
           ],
           tracks:
           []
        }                
        }),    
   components:{
      "Player": Player
   
    },
    created()
    {
     this.user =  $.parseJSON(localStorage.getItem('user'));
     
    },
    beforeMount()
    {
 

    },
    mounted() {      
      let self = this;    
      $.getJSON(self.$root_url + "/webapi/videos/get_video.php?id="+this.$route.params.vid +"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {          
          let video = data;
          self.video = video;           
          
          if(self.video.media_images!==undefined && self.video.media_images!= null)
          self.video.media_images.forEach(function(key) {          
             self.images.push(self.$root_url + '/media/images/'+key);
          }); 
          
          if(self.video.trailer=="")
          {            
                      $.getJSON(self.$root_url + "/webapi/videos/get_trailer.php?imdb="+self.video.imdb_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                      
              $("#trailer").html('<video controls style="width:420px;" src="'+data.trailer+'"</video>');
            });
          }              
            else                      
              $("#trailer").html('<video controls style="width:420px;" src="'+video.trailer+'"</video>');
      
         
         self.loaded = true;         
         self.$refs.player.loadSources(video);
         
      });     
    },
    computed: {
       currentRouteName() {
        return this.$route.name;
    },
      genres: function()
      {
        let res = "";
        if(this.video.genres!==undefined)
        this.video.genres.forEach(function(key) {                          
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/genre">' + key + '</a>, ';
        });      
        res = res.substring(0, res.length - 2);
        return res;
    },
     casts: function()
      {
        let res = "";        
        if(this.video.cast!==undefined)
          if(this.video.cat_id!=4)
            Object.keys(this.video.cast).forEach(function(key) {
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/cast">' + key + '</a>, ';
            }); 
          else
          {
            this.video.cast.forEach(function(key) {
             res = res + key + ", ";
            }); 
          }

        res = res.substring(0, res.length - 2);
        return res;
    },
    directors: function()
      {
        let res = "";
        
        if(this.video.directors!==undefined)
          Object.values(this.video.directors).forEach(function(key) {
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/director">' + key + '</a>, ';
        }); 
        res = res.substring(0, res.length - 2);
        return res;
    },  
    producers: function()
      {
        let res = "";
        
        if(this.video.producers!==undefined)
          Object.values(this.video.producers).forEach(function(key) {
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/producer">' + key + '</a>, ';
        }); 
        res = res.substring(0, res.length - 2);
        return res;
    }
    },
    beforeRouteLeave (to, from, next) { 
      try{
  
          let self = this;     
          var currentTime =   self.$refs.player.player.currentTime();
          var duration =   self.$refs.player.player.duration();
          
          $.getJSON(self.$root_url + "/webapi/videos/user_watched.php?video_id="+self.video.video_id + "&position="+parseInt(currentTime) + "&length=" + parseInt(duration) +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {              
              
          });

            }
        catch(err) {    
          console.log(err) ;
        }
      
      next();
     },
   
    methods: {
      getPlaylists()
      {
        let self = this;
             $.getJSON(self.$root_url + "/webapi/videos/playlists.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&p=1", function(data) {
                      self.selected_video = self.video;
                      self.show_playlists = true;
                      self.playlists = data.items;                
                      self.loading = false;
                      
                  });
      },
      add_to_playlist()
      {
          let self = this;
      
      
      this.selected_video.id = this.selected_video.movie_id;
      var playlist_data = JSON.stringify(this.selected_playlist);   
      var vid_data = JSON.stringify(this.selected_video);   
      

        $.post( self.$root_url + "/webapi/videos/playlist_add.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { playlist_data: playlist_data, video_data:vid_data})
              .done(function( data ) {              
                  
                  let result = $.parseJSON(data);
                        if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                    self.show_playlists = false;
                  }else
                  {
                    self.show_playlists = false;
                    self.$swal( "Done",self.selected_video.title + " was added to playlist " + self.selected_playlist.name);
                  }         
          }); 
   
      },
       downloadSingle(url)
    {
        if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
        }
        window.location = this.get_download_url(url);
    },
        get_download_url(url)
    {

        var base = new String(url).substring(url.lastIndexOf('/') + 1); 
        if(base.lastIndexOf(".") != -1)       
          base = base.substring(0, base.lastIndexOf("."));
          base = base.replace("_480p","");
          base = base.replace("_720p","");
        return url +"&filename="+base+".mp4";
    },
      changeSrc: function (item) {      
      this.selected_quality = item.title;
        
      },             
        toggle_watch(item,i)
        {
          
          let self = this;
          if(item.watched!=null)
          {
            this.episodes[i].watched = null;
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=unwatch&video_id="+item.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                                                

            });
          }else
          {
            this.episodes[i].watched = "Yes";
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=watch&video_id="+item.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {   

            });
          }
        },    
    }
  }
</script>
<style >
.video_player
{
width:100%; 
background-color:black; 
border-style:none; 
height: 720px;
max-height:720px!important;
outline: none; 

}
.video_controls{
  position: relative;
   margin-top:-8px; 
  width:100%; 
  padding:10px; 
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color:rgba(0,0,6,0.8);
  height:60px;
  margin-left:auto;
  margin-right:auto;
}
.dialog_next_episode
{
  position: absolute; margin-top:200px; margin-left:40%; width:400px; z-index:100;
}
#video_container{
    width:100%; background-color:black; height:480px!important; 
}
@media only screen and (max-width: 600px) {
  .video_player {
    height: 180px;
    max-height:280px!important;
  }
  #video_container
  {
    height:180px!important; 
  }
  .dialog_next_episode
  {
    position: absolute; margin-top:200px; margin-left:5%; width:400px; z-index:100;
  }
}
@media only screen and (max-width: 800px) {
  .video_player {
    height: 180px;
    max-height:280px!important;
  }
  #video_container
  {
    height:180px!important; 
  }
  .dialog_next_episode
  {
    position: absolute; margin-top:200px; margin-left:10%; width:400px; z-index:100;
  }
}
</style>