<template>

      <v-container >  
         <h2  style="position:absolute; top:40px; width:100%">Invites
           <v-btn dark  color="blue" text @click="showInvite()"><span class="mdi mdi-account-plus mdi-24px" style="color:white;" ></span> New</v-btn>
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h2>        
      <v-card
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0"
    >    
      <v-divider></v-divider>

    <v-card-title>      
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="invites"
      :search="search"
       @click:row="rowClick" item-key="id"
    >
    
    </v-data-table>

      
      </v-card>            
 <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Delete Invite</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark  color="red" text @click="deleteInvite()">Delete</v-btn>                        
            <v-btn dark  color="green" text @click="saveInvite()">Update Invite</v-btn>   
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>User Information</v-subheader>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Inviter" v-model="selected_invite.inviter" hint="The person that sent the invite"></v-text-field>              
            </v-list-item-content>
          </v-list-item>
        <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Invite Code" v-model="selected_invite.code" hint="Invite Cdde"></v-text-field>              
            </v-list-item-content>
          </v-list-item>

        </v-list>          
      </v-card>
    </v-dialog>
  </v-row>



<v-row justify="center">
    <v-dialog v-model="new_invite_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="new_invite_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Create new invite</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="SendInvite()">Send Invite</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Invite Information</v-subheader>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="E-Mail" v-model="new_invite.mail" hint="The e-mail addres to send the invite to."></v-text-field>              
            </v-list-item-content>
          </v-list-item>   
            <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Invite Code" min="20" max="20" v-model="new_invite.code" hint="The e-mail addres to send the invite to."></v-text-field>              
            </v-list-item-content>
          </v-list-item>                    


        </v-list>
      </v-card>
    </v-dialog>
  </v-row>


<SiteConfig />

      </v-container>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Home',    
    props: {
      source: String
    },
     data: () => ({   
        page            : 1,
        user            :[],
        loading         : true,        
        invites           :[],
        selected_invite   :[],
        new_invite        :{invite_by:"0",mail:"",code:""},
        new_invite_dialog :false,
        dialog              :false,
        search: '',
        headers: [
          { text: 'Invite ID', value: 'id' },           
          { text: 'Used', value: 'used' },
          { text: 'Invited By', value: 'inviter' },
          { text: 'Inviter User ID', value: 'invite_by' },
          { text: 'Invited User', value: 'invited' },
          { text: 'Invite Mail', value: 'invite_mail' },
          { text: 'Invited User Id', value: 'new_user' },
          { text: 'Created', value: 'created' },
           
           ]

        }),
  components:{
"SiteConfig":SiteConfig,
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{
      showInvite()
      {
            this.new_invite = {invite_by:"0",mail:"",code:this.makeInvite(20)};
            this.new_invite_dialog = true
      },
    SendInvite()
    {
         let self = this;
        if(self.new_invite.code.length<20 || self.new_invite.mail.indexOf("@")<=0)
        {
            self.$swal.fire(
                  'Info missing',
                  'Something was missing, check it again.',
                  'error'
                ); 
        }else{
            var datastring = JSON.stringify(self.new_invite);          
          $.post( self.$root_url + "/webapi/user/new_invite.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
            .done(function( data ) {          
                
                let result = $.parseJSON(data);
              if(result.status==0)
              {                             
                self.$swal('Failed', result.reason, "error");                        
              }else
              {
                self.new_invite_dialog = false;
                self.$swal.fire(
                  'Done',
                  'The invite was sent to ' + self.new_invite.mail,
                  'success'
                );                
                }          
            });  

        }
         
    

    },
      deleteInvite()
      {       
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently remove the invite',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_invite);      
              $.post( self.$root_url + "/webapi/user/delete_invite.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {              
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The invite was successfully delete',
                      'success'
                    );
                    
                    let index = self.invites.indexOf(self.selected_invite);
                    if (index > -1) {
                      self.invites.splice(index, 1);
                      }
                    }          
                });  
            }
          })

      },
      makeInvite(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result.toUpperCase();
        },
      saveUser()
      {
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'Make sure everything is correct before you go on',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {

            var datastring = JSON.stringify(self.selected_user);      
        $.post( self.$root_url + "/webapi/user/save_user.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
          .done(function( data ) {              
              let result = $.parseJSON(data);
            if(result.status==0)
            {                             
               self.$swal('Failed', result.reason, "error");                        
            }else
            {
              this.dialog = false;
               self.$swal.fire(
                'Updated!',
                'The user was successfully updated',
                'success'
              )         

            }          
              });             
            }
          })
      },

      rowClick: function (item, row) {      
      row.select(true);
      this.selected_invite = item;      
      this.dialog = true;
    }   
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/user/invites.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
        
        self.invites = data.invites;
        self.loading = false;
        
    });


    }
  }
</script>

