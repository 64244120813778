<template>

      <v-container >  
         <h2  style="position:absolute; top:40px; width:100%">News
           <v-btn dark  color="blue" text @click="showNew()"><span class="mdi mdi-account-plus mdi-24px" style="color:white;" ></span> New</v-btn>
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h2>        
      <v-card
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0"
    >    
      <v-divider></v-divider>

    <v-card-title>      
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="news"
      :search="search"
       @click:row="rowClick" item-key="id"
    >
    
    </v-data-table>

      
      </v-card>            
 <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Modify Post</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark  color="red" text @click="deleteNews()">Delete</v-btn>                                    
            <v-btn dark  color="green" text @click="updateNews()">Update</v-btn>                                    
          </v-toolbar-items>
        </v-toolbar>
         <v-list three-line subheader>
          <v-subheader>News Information</v-subheader>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Title" hint="Give it a title" v-model="selected_news.title"></v-text-field>              
            </v-list-item-content>
          </v-list-item>   
            <v-list-item>
            <v-list-item-content>       
              <v-textarea label="Message" hint="The main message goes here" v-model="selected_news.message"> </v-textarea>                     
            </v-list-item-content>
          </v-list-item>                    
          <v-list-item>
            <v-list-item-content>     
              <p>End date</p>  
              <v-date-picker v-model="selected_news.end_date"   landscape></v-date-picker>
            </v-list-item-content>
          </v-list-item>         

        </v-list>       
      </v-card>
    </v-dialog>
  </v-row>



<v-row justify="center">
    <v-dialog v-model="news_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="news_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Create news</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="PostNews()">Post news</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>News Information</v-subheader>
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Title" hint="Give it a title" v-model="new_post.title"></v-text-field>              
            </v-list-item-content>
          </v-list-item>   
            <v-list-item>
            <v-list-item-content>       
              <v-textarea label="Message" hint="The main message goes here" v-model="new_post.message"> </v-textarea>                     
            </v-list-item-content>
          </v-list-item>                    
          <v-list-item>
            <v-list-item-content>     
              <p>Select an end date</p>  
              <v-date-picker v-model="new_post.end_date"   landscape></v-date-picker>
            </v-list-item-content>
          </v-list-item>         

        </v-list>
      </v-card>
    </v-dialog>
  </v-row>


<SiteConfig/>

      </v-container>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Home',    
    props: {
      source: String
    },
     data: () => ({   
        page            : 1,
        user            :[],
        loading         : true,        
        selected_news   :[],
        new_post        :[],
        news           :[],
        news_dialog :false,
        dialog              :false,
        search: '',
        headers: [
          { text: 'Title', value: 'title' },           
          { text: 'Message', value: 'message' },
          { text: 'Author', value: 'name' },
          { text: 'Posted', value: 'post_date' },
          { text: 'End Date', value: 'end_date' }                     
           ]

        }),
  components:{
"SiteConfig":SiteConfig
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{
      showNew()
      {            
            this.news_dialog = true;
             this.new_post = {title:"",message:"",end_date:""};
      },
    PostNews()
    {
         let self = this;
        if(self.new_post.message.length<3 || self.new_post.title.length<3)
        {
            self.$swal.fire(
                  'Info missing',
                  'Something was missing, check it again.',
                  'error'
                ); 
        }else{
            var datastring = JSON.stringify(self.new_post);          
          $.post( self.$root_url + "/webapi/user/new_newspost.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
            .done(function( data ) {          
                
                let result = $.parseJSON(data);
              if(result.status==0)
              {                             
                self.$swal('Failed', result.reason, "error");                        
              }else
              {
                self.new_invite_dialog = false;
                self.$swal.fire(
                  'Done',
                  result.reason,
                  'success'
                );                
                }          
            });  

        }
         
    

    },
    updateNews()
    {
let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will update the post with new content',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_news);      
              $.post( self.$root_url + "/webapi/user/new_newspost.php?cmd=update&user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {              
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The post was successfully updated',
                      'success'
                    );                                      
                    }          
                });  
            }
          })

    },
      deleteNews()
      {       
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently remove the invite',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_news);      
              $.post( self.$root_url + "/webapi/user/delete_newspost.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {              
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The post was successfully deleted',
                      'success'
                    );
                    
                    let index = self.news.indexOf(self.selected_news);
                    if (index > -1) {
                      self.news.splice(index, 1);
                      }
                    }          
                });  
            }
          })

      },
      

      rowClick: function (item, row) {      
      row.select(true);
      this.selected_news = item;      
      this.dialog = true;
    }   
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/user/get_news.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {        
        self.news = data.news;
        self.loading = false;
        
    });


    }
  }
</script>

