<template>
    <v-row class="mt-4" style="text-align:center;">

      <div class="custom_card ml-2 mt-2"            
            v-for="card in this.$parent.cards"
            :key="card.id"   
            >  
    
              <a @click="play_item(card)" class="custom_card_link">        
                
                <img :src="card.poster"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>                  
                </div>                
              </a>
              <div class="custom_card_container">                
                <h5><b>{{card.name}}</b></h5>                

              <v-card-actions class="custom_card_actions">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);">mdi-dots-vertical</v-icon></div>                                        
        </template>

      <v-list style="background-color:#000000;" >
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('view_playlist',card)" text small color="white"><v-icon>mdi-playlist-play</v-icon><span class="ml-2">Play</span></v-btn>                                
          </v-list-item>                       
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('delete_playlist',card)" text small color="red"><v-icon>mdi-playlist-remove</v-icon><span class="ml-2">Delete</span></v-btn>                                
          </v-list-item>                       
        </v-list>
       

      </v-menu>                                 
              </v-card-actions>
              <p style="font-size:10pt; color:rgba(0,0,0,50);">{{card.count}} videos</p>
              </div>
              
            </div>

           
          
        </v-row>
</template>
<style  scoped>
.video_card
{
  width:240px;
  height: 380px;

  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .video_card{
    min-width:160px!important;
    min-height:280px!important;
    max-height:280px!important;
  }
}
@media only screen and (min-width:700px) and (max-width:920px) {
  .video_card{
    min-width:160px!important;
    min-height:280px!important;
    max-height:280px!important;
  }
}


</style>

<script>
import $ from 'jquery';
import router from '../router.js' 
  export default {
    name: 'PlaylistCards',
    props: {view: String},
     data: () => ({
       posterNA: '',
     action_menu: [],
     user:[]
  }),
  created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
        this.posterNA = this.$root_url + '/img/poster_NA.png';
  },
  mounted()
  {

      
  },
  methods:{
        onError (card) {
        var i;   
            for (i = 0; i < this.$parent.cards.length; i++) {
                if(this.$parent.cards[i].id==card.id)
                {                           
                    this.$parent.cards[i].poster_thumbnail = this.$root_url + "/img/poster_NA.png";
                }
            }                               
        },
        
        action_menu_click (menu,card) {
         
         let self = this;
            switch(menu)
            {

                case "view_playlist":
                 router.push({ name: 'play_list', params: {listid: card.id, index:1} });      
                  break;
                case "delete_playlist": 
                    
                     this.$swal.fire({
                      title: 'Are you sure?',
                      text: 'This will permanently remove ' + card.name,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it' 
                      }).then((result) => {        
                        if (result.value) {
                           var datastring = JSON.stringify(card); 
                          var res = self._post(self.$root_url + "/webapi/videos/playlist_delete.php?user_id="+self.user.id +"&token="+self.user.auth_token,datastring);
                           if(res.status==0)
                            {                             
                              self.$swal('Failed', res.reason, "error");                        
                            }else
                            {
                              this.dialog = false;
                              self.$swal.fire(
                                'Updated!',
                                'The playlist was successfully deleted',
                                'success'
                              );
                            }

                        }
                      });
                      
                break;                
                
            }
            },
        play_item(card)
        {   
             router.push({ name: 'play_list', params: {listid: card.id, index:1} });             
        },   
        _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        }
        

  },
  
  }
</script>

