<template>
  
     
      <v-row align="start" class="fill-height">
        <v-col cols="12"
        >
     <v-card width="full" align="start" class="" color="rgba(0,0,0,0.7)">
       <v-card-title >
         <h1 class="display-1 mx-auto" style="color:white;">Admin Panel</h1>
       </v-card-title>
       <v-card-text>
         <v-form id="LoginForm" v-model="valid">
           <v-divider></v-divider>    
                 <v-container>
             <v-row>
             <v-col cols="12" style="text-align:center;">
               <h2>Welcome {{user.name}}</h2>
               <v-avatar class="mt-2" size="avatarSize" >                   
                 <img class="imgUser" :src="user.image!=='' ? this.$root_url + '/webapi/user/photo.php?user_id='+user.id : this.$root_url + '/img/user.png'" alt="alt">
               </v-avatar>                
             </v-col>                                                
             </v-row>             
              <div style="width:200px;">
                <v-switch @change="updateSiteSettings" v-model="require_invite" label="Require Invite"></v-switch>                 
               </div>                         
               <div style="width:200px; margin-left:150px; margin-top:-64px;">
                <v-switch @change="updateSiteSettings" v-model="sign_up_allowed" label="Allow Registrations"></v-switch>                 
               </div>                         
               
           </v-container>

      <v-divider></v-divider> 

           <v-container>
             <v-row>
             <v-col cols="12">
                 
                 <v-btn x-large to="/support_box"  class="ml-2 mt-3">
                 <v-badge color="red accent-4" v-if="stats.support_cases>0" :content="stats.support_cases" left overlap transition="slide-x-transition" style="margin-left: 10px; margin-right:10px;"></v-badge>
                 <span class="mdi mdi-mailbox" style="font-size:20pt; padding-right:5px;"></span> Support Inbox</v-btn>               
                 
               <v-btn x-large to="/users"  class="ml-2 mt-3"><span class="mdi mdi-account" style="font-size:20pt; padding-right:5px;"></span> Users</v-btn>               
               <v-btn x-large to="/video_manager" class="ml-2 mt-3"><span style="font-size:20pt; padding-right:5px;" class="mdi mdi-movie-edit"></span> Video Manager</v-btn>               
               <v-btn x-large  to="/conversions" class="ml-2 mt-3">
               <v-badge color="red accent-4" v-if="stats.active_conversions>0" :content="stats.active_conversions" left overlap transition="slide-x-transition" style="margin-left: 10px; margin-right:10px;"></v-badge>            
               <span style="font-size:20pt; padding-right:5px;" class="mdi mdi-movie-roll"></span> Video Conversions</v-btn>
              
              <v-btn x-large  to="/video_reports" class="ml-2 mt-3">
               <v-badge color="red accent-4" v-if="stats.video_reports>0" :content="stats.video_reports" left overlap transition="slide-x-transition" style="margin-left: 10px; margin-right:10px;"></v-badge>            
               <span style="font-size:20pt; padding-right:5px;" class="mdi mdi-image-broken-variant"></span> Video Reports</v-btn>

               <v-btn x-large  to="/admin_invites" class="ml-2 mt-3" ><span style="font-size:20pt; padding-right:5px;" class="mdi mdi-barcode"></span> Invites</v-btn>
               <v-btn x-large  to="/System" class="ml-2 mt-3" ><span style="font-size:20pt; padding-right:5px;" class="mdi mdi-server"></span> System Overview</v-btn>
               <v-btn x-large  to="/news_manager"  class="ml-2 mt-3" ><span style="font-size:20pt; padding-right:5px;" class="mdi mdi-movie-edit"></span> News Manager</v-btn>
               <v-btn x-large  to="/IMDB_manager" class="ml-2 mt-3" ><span style="font-size:20pt; padding-right:5px;" class="mdi mdi-file-video"></span> IMDB Manager</v-btn>
               <v-btn x-large  to="/cookie_opensub" class="ml-2 mt-3" ><span style="font-size:20pt; padding-right:5px;" class="mdi mdi-cookie"></span> Opensub Cookie</v-btn>
               
             </v-col>             
             <v-col cols="4" >
               <v-row justify="end">
                  
               </v-row>
             </v-col>
             </v-row>
                                              
           </v-container>
      <v-divider></v-divider> 
    <h1 class="mt-2">User Stats</h1>
    <canvas id="user_chart" width="200" height="80" style="background-color: rgba(255,255,255,0.9); margin-top:10px; border-radius:15px;"></canvas>
    <h1 class="mt-2">Video Stats</h1>
    <canvas id="video_chart" width="400" height="80" style="background-color: rgba(255,255,255,0.9); margin-top:10px; border-radius:15px;"></canvas>
    

         

      <v-divider></v-divider> 

         </v-form>
       </v-card-text>

     </v-card>    
        </v-col>
        <form id="form_photo" target="iframe" :action="this.$root_url + '/webapi/user/update_photo.php'" method="post" enctype="multipart/form-data">
          <input name="file" type="file" id="fileBrowser" style="display:none;" />    
          <input name="user_id" type="hidden" :value="user.id" />   
          <input name ="token" type="hidden" :value="user.auth_token" />           
      </form>

<iframe name="iframe" id="iframe" style="display:none;" ></iframe>
      <SiteConfig/>
      </v-row>
     
</template>


<script>
import $ from 'jquery';

import SiteConfig from '../components/SiteConfig'


export default {
  name: 'Account',   
  data: () => ({   
      data_loading : false,   
      valid: true,
      stats:[],
      require_invite:false,
      sign_up_allowed: false,
      user: []

  }),
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{
"SiteConfig":SiteConfig
  },
  methods:{     

    updateSiteSettings(){
    let self = this;
      var obj = {allow_invite:self.require_invite, allow_sign_up:self.sign_up_allowed};
      var datastring = JSON.stringify(obj);   
        $.post( self.$root_url + "/webapi/backend/save_sitesettings.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
         .done(function() {    
        });
        

    },
  /*loadUserChart()
  {

      var ctx = document.getElementById('user_chart').getContext('2d');
var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['Total', 'New Today', 'Free','Logins Today','VIP', 'Expired VIP'],
        datasets: [{
            label: 'User Count',
            data: [this.stats.users_count, this.stats.new_users_count,
             this.stats.free_user,this.stats.login_count,
              this.stats.vip_count, this.stats.vip_expire],
            backgroundColor: [
                'rgba(255, 99, 132, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(75, 192, 192, 0.8)',
                'rgba(153, 102, 255, 0.8)',
                'rgba(255, 159, 64, 0.8)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
});

  return myChart;
  },
   loadVideoChart()
  {

      var ctx = document.getElementById('video_chart').getContext('2d');
var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['Plays', 'Active Conversions', 'Conversions Today'],
        datasets: [{
            label: 'Count',
            data: [this.stats.plays_count, this.stats.active_conversions,
             this.stats.done_conversions],
            backgroundColor: [
                'rgba(255, 99, 132, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
});

  return myChart;
  }*/
  },

  mounted(){
    
      let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/backend/getStats.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {        
        self.stats = data.stats;      
       
        var site_settings = data.site_settings;
        
        if(site_settings.require_invite=="0") 
          self.require_invite = false; 
        else self.require_invite = true;

        if(site_settings.sign_up_allowed=="0") 
          self.sign_up_allowed = false; 
        else 
          self.sign_up_allowed = true;
        
        self.loading = false;
        self.loadUserChart();
        self.loadVideoChart();
        
    });

     
  }
};
</script>