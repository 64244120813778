<template>
<div class="mt-12">
     <h3  style="color:#d4af37;"><v-icon style="font-size:22pt; color:#d4af37; margin-top:-5px;">mdi-movie-roll</v-icon> On Deck</h3>
     <v-divider></v-divider>

<div v-if="!show_vip" style="text-align:center;">

  <h2 class="mt-10" style="text-align:center; color:#d4af37;">On Deck is a Premium feature, upgrade now to maximize your experience and get access to all the amazing features.</h2>
  <a class="v-btn v-btn--contained v-btn--router theme--dark v-size--default" style="background-color: rgb(212, 175, 55);" href="/premium">Go Premium!</a>
</div>
<v-slide-group      
      style="margin-top:5px;"
      v-if="show_vip"
      center-active
      
      mobile-breakpoint="2"
    >    
    <h3 class="mx-auto orange--text text--lighten-4 pl-2" v-if="this.$parent.on_deck.length<=0 && this.$parent.loading==false">You have no unfinished videos, yeay!</h3>       
      <v-slide-item
        v-for="card in this.$parent.on_deck"
        :key="card.id"
      >
        
            <div class="custom_card">  
              <v-progress-linear 
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear>   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                <h5 v-if="card.cat_id>1"><b>{{card.title}}</b><br>
                {{" S" + card.season + "E" + card.episode}}</h5>
                
                <h5 v-else><b>{{card.title}}</b><br>
                {{card.year}}</h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on" style="    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" v-if="card.cat_id>1">
       

              <v-list-item style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px;" text small color="white" @click="action_menu_click('Gotoshow',card)" ><v-icon>mdi-remote-tv</v-icon> <span class="ml-2">Go to show</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id<=0" style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Sub',card)" text small color="#d4af37"><v-icon>mdi-heart</v-icon><span class="ml-2"> Subscribe</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id>0"  style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Unsub',card)" text small color="#d4af37"><v-icon>mdi-heart-off</v-icon><span class="ml-2">Unsubscribe</span></v-btn>
            </v-list-item>  
     
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Mark_ep_watched',card)" text small color="#d4af37"><v-icon>mdi-eye-check</v-icon><span class="ml-2">Mark as watched</span></v-btn>                
                
            </v-list-item>
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Mark_ep_unwatched',card)" text small color="#d4af37"><v-icon>mdi-eye-off</v-icon><span class="ml-2">Mark as unwatched</span></v-btn>                
                
            </v-list-item>
       
                    
        </v-list>

        
        
        <v-list style="background-color:#000000;" v-if="card.cat_id<=1">
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('GotoMovie',card)" text small color="#d4af37"><v-icon>mdi-movie</v-icon><span class="ml-2">Go to Movie</span></v-btn>                                
            </v-list-item>
            <v-list-item style="background-color:#000000;">                                
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Mark_ep_watched',card)" text small color="#d4af37"><v-icon>mdi-eye-check</v-icon><span class="ml-2">Mark as watched</span></v-btn>
            </v-list-item>
            <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Mark_ep_unwatched',card)" text small color="#d4af37"><v-icon>mdi-eye-off</v-icon><span class="ml-2">Mark as unwatched</span></v-btn>                
                
            </v-list-item>
           
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>
              
            </div>
           
      </v-slide-item>
</v-slide-group>


        
</div>  
</template>
<style lang="scss" scoped>
.col-lg-3{
    max-width: 20%;
}


</style>
<script>
import $ from 'jquery';
 


import router from '../router.js' 
  export default {
    name: 'OnDeck',
    user: [],
    props: {
      source: String
    },
     data: () => ({
       posterNA:'',
     action_menu: [
        { title: 'Add to favorites', icon: "mdi-heart", color:"white"},
        { title: 'Go Premium' ,icon: "mdi-star",color:"#d4af37"},
        { title: 'Help' ,icon: "mdi-help",color:"white"},
        { title: 'Sign Out' ,icon: "mdi-exit-run",color:"white"}
    ]
  }),
  computed:{
     show_vip:function(){
        if(this.$parent.user.vip == 'true')
          return true;
        else 
          return false;
      }
  },
  created () {  
        this.posterNA = this.$root_url + '/img/poster_NA.png';
        this.user =  $.parseJSON(localStorage.getItem('user'));    

  },mounted(){



  },
  methods:{     
   action_menu_click (menu,card) {
         var datastring ="";
         var result = false;
         var x;
            switch(menu)
            {
                case "GotoMovie":
                   router.push({ name: 'play', params: {vid: card.id} }); 
                  break;
                case "Gotoshow": 
                     router.push({ name: 'season', params: {showid: card.id} }); 
                break;
                case "Mark_ep_watched": 
                  
                    $.getJSON(this.$root_url + "/webapi/videos/user_unwatch.php?cmd=watch&video_id="+card.video_id +"&user_id="+this.user.id +"&token="+this.user.auth_token, function() {                       

                    });
                    
                    for(x=0; x<this.$parent.on_deck.length; x++)
                    {
                      if(this.$parent.on_deck[x].id == card.id)
                      {
                        this.$parent.on_deck.splice(x,1);
                      }
                    }


                break;
                case "Mark_ep_unwatched":                  
                    $.getJSON(this.$root_url + "/webapi/videos/user_unwatch.php?cmd=unwatch&video_id="+card.video_id +"&user_id="+this.user.id +"&token="+this.user.auth_token, function() {                  

                    });                    
                    for(x=0; x<this.$parent.on_deck.length; x++)
                    {
                      if(this.$parent.on_deck[x].id == card.id)
                      {
                        this.$parent.on_deck.splice(x,1);
                      }
                    }
                break;


                 case "Mark_watched": 
                  
                    $.getJSON(this.$root_url + "/webapi/videos/user_unwatch.php?cmd=watch&video_id="+card.video_id +"&user_id="+this.user.id +"&token="+this.user.auth_token, function() { 
                  

                    });                    
                    for(x=0; x<this.$parent.on_deck.length; x++)
                    {
                      if(this.$parent.on_deck[x].id == card.id)
                      {
                        this.$parent.on_deck.splice(x,1);
                      }
                    }


                break;

                case "Sub": 
                 
                 if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
                  }
                      datastring = JSON.stringify({show_id:card.id});
                       result = this._post(this.$root_url + "/webapi/videos/follow.php",datastring);
                  if(result.status==0)                  
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show subscribed','You will now start receiving new episodes notifications from this show.','success');
                    
                    card.follow_id = result.follow_id;
                  }

                break;
                case "Unsub":                                            
                    datastring = JSON.stringify({follow_id:card.follow_id});
                    result = this._post(this.$root_url + "/webapi/videos/unfollow.php",datastring);
                  if(result.status==0)      
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show unsubscribed','You will no longer receive new episodes notifications from this show.','success');        
                    card.follow_id = 0;
                  }
                  


                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
            }
            },
        play_item(card)
        {                        
            if(card.cat_id>1 && card.cat_id<4)
             router.push({ name: 'play_season', params: {showid: card.id,season:card.season,episode:card.episode} });    
             else
             router.push({ name: 'play', params: {vid: card.id} });             
        },        
          _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        }

  },
  
  }
</script>

