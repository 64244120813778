<template >
      <v-container >  
      <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
       <h2  style="width:100%">Rewards</h2>  
       <p>Welcome to rewards<br />
       Our new, fun and free way to get Premium on MV. Pick any of the offers below and collect coins which youy can use to buy premium.</p>        
       <p>Your balance: {{balance}}</p>   
       
       
<v-switch color="#d4af37" v-model="switch1" inset :label="` ${switch1 ? 'Cards' : 'List'}`"         
        class="float-right"     style="margin-top:-40px;"></v-switch>      

            <VideoList view="movies" v-if="!switch1" />
            <RewardCards  v-if="switch1" />
     
          <SiteConfig />
      </v-container>     
</template>
<script>
import $ from 'jquery';

import RewardCards from '../components/Reward_Cards'
import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Movies',        
    props: {
      source: String
    },
     data: () => ({   

        switch1: true,
        loading: true,      
        balance: 0,    
        user:[],
        cards: [],
      
     }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  
  components:{
    "RewardCards" : RewardCards,
    "VideoList" : VideoList,
    "SiteConfig": SiteConfig
  },
  methods:{
        
  },
  beforeRouteLeave (to, from, next) { 
      this.cards      = [];
      this.user       = [];
      delete this.cards;
      delete this.user;
      next();
     },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
     $.getJSON(self.$root_url + "/webapi/rewards/get_offers.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {         
      self.cards = data.offers;      
      self.loading = false;
    });
     $.getJSON(self.$root_url + "/webapi/rewards/get_wallet.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
         console.log(data);   
         self.balance = data.currency_count;
    });

    }
  }
</script>

