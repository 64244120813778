<template>

      <v-container >  
         <h2  style="position:absolute; top:40px; width:100%">Opensubs Cookie File           
      </h2>        
      <v-card
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0"
    >    
      <v-divider></v-divider>
            <v-textarea style="background-color:rgba(0,0,0,0.2); border-radius: 25px; padding-top:30px; padding-left:10px; padding-right:10px; "
          prepend-icon="mdi-cookie"
          label="Cookie File Content"
          v-model="cookie_content"
          rows="9"
        ></v-textarea>
            <v-btn dark  color="blue" text @click="saveCookie()" ><span class="mdi mdi-cookie-check mdi-24px" style="color:white;" ></span> Save Cookie</v-btn>
            <h4 style="color:orange;">Current status: <span style="color:white;">{{current_status}}</span></h4>
            <h4 style="color:orange;">Last modified: <span style="color:white;">{{modified_date}}</span></h4>
      </v-card>            

<SiteConfig/>
      </v-container>
</template>

<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Cookies',    
    props: {
      source: String,
    },
     data: () => ({   
        page            : 1,
        user            :[],
        cookie_content: "",
        current_status: "",
        modified_date: ""

        }),
  components:{
"SiteConfig":SiteConfig,
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{
    saveCookie(){
        let self = this;
        let cookie = {cookie: self.cookie_content};
        var datastring = JSON.stringify(cookie); 
        var res = self._post(self.$root_url + "/webapi/backend/set_cookie.php?user_id="+self.user.id +"&token="+self.user.auth_token,datastring);
         self.$swal.fire(
                      'Updated!',
                      res,
                      'success'
                    );
    },
     _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = data   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
     handler(params) {
        console.log(params);
        this.video_ended();
    }
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/backend/get_cookie.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {        
        self.cookie_content = data.cookie;
        self.current_status = data.status;
        self.modified_date = data.modified_date;
        self.loading = false;
        
    });

    }
  }
</script>

