<template >

<v-container class="mt-4" >  
<v-progress-linear color="deep-purple accent-4" indeterminate height="10" v-if="loading" style="position:absolute; top:0px; right:0px;"></v-progress-linear>          

    <v-btn color="rgba(0,0,0,0.5)"  @click="show_dialog=!show_dialog">        
        <v-icon>mdi-playlist-plus</v-icon> 
     New
     </v-btn>


<v-switch color="#d4af37" v-model="switch1" inset :label="` ${switch1 ? 'Cards' : 'List'}`"         
        class="float-right" ></v-switch>      
        <v-pagination
        color="#d4af37"
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-4"
         :total-visible="10"
         v-if="total_pages>1"

      ></v-pagination>
      
            
            <PlaylistCards  v-if="switch1" />
             <PlaylistList  v-if="!switch1" />
      <v-pagination
        v-model="page"
           color="#d4af37"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12"
         :total-visible="10"
        v-if="total_pages>1"
      ></v-pagination>











     <v-row>    

 
      <v-card v-if="show_dialog" class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>New Playlist</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="create_playlist()">Create Playlist</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>                    
               <v-list-item>
                   <v-list-item-content>              
                      <v-text-field label="Playlist Name" v-model="playlist_name" hint="Give your new playlist a name" dense></v-text-field>              
                  </v-list-item-content>
              </v-list-item>  
               
        </v-list>
      </v-card>
    

  </v-row>


      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';
import PlaylistCards from '../components/Playlist_Cards'
import PlaylistList from '../components/Playlist_List'

import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'TVShows',    
   
    props: {
      source: String
    },
     data: () => ({   
          page: 1,
        total_pages:1,
        playlist_name:"",
        switch1: true,
        loading: true,
        show_dialog: false,
        user:[],
         cards: [     
        ]
        }),
  created(){
    this.user =  $.parseJSON(localStorage.getItem('user'));
    if(this.user.view_style!=undefined && (this.user.view_style>0 || this.user.view_style=='true'))
        this.switch1 = true;
    else
        this.switch1 = false;

  },
  components:{    
    "SiteConfig": SiteConfig,
    "PlaylistCards": PlaylistCards,
    "PlaylistList" : PlaylistList
  },
  methods:{
      create_playlist()
      {
            var self = this;
            self.loading = true;
         $.getJSON(self.$root_url + "/webapi/videos/new_playlist.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&name="+encodeURIComponent(self.playlist_name), function(data) {
            
            self.loading = false;
              if(data.status==0)
              {                             
                self.$swal('Failed', data.reason, "error");                        
              }else
              {                                
                self.show_dialog = false;
                self.$swal.fire(
                  'Playlist Successfully Created',
                  '',
                  'success'
                );                
                }  

            });  
      },
        changePage()
        {
            var self = this;
            self.loading = true;
         $.getJSON(self.$root_url + "/webapi/videos/playlists.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&p="+self.page + "&timezone="+self.getTimeZone(), function(data) {
            
            self.cards = data.items;                
            self.loading = false;
            });
        },
         getTimeZone()
        {    
            return encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
        },
        
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
        $.getJSON(self.$root_url + "/webapi/videos/playlists.php?user_id="+self.user.id +"&token="+self.user.auth_token + "&timezone="+self.getTimeZone(), function(data) {
        self.loading = false;
        self.cards = data.items;   
        self.total_pages = data.total_pages;
         
    });
    },
     beforeRouteLeave (to, from, next) { 
        this.cards      = [];
        this.user       = [];
        delete this.cards;
        delete this.user;
      next();
     }
  }
</script>

