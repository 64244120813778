<template>
<v-app style="overflow-x:hidden;">
  
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped     
      class="side_menu"      
      >
      <v-treeview      
      rounded
      v-model="tree"          
      @update:active="goToPage"
      @update:open="openNode"           
      return-object
      :open="initiallyOpen"
      :items="pages"      
      item-key="name"
      open-on-click
      activatable            
      class="side_menu_item mt-2"
    >
    <template v-slot:prepend="{ item }">        
        <v-icon >
          {{item.icon}}
        </v-icon>                
   </template>
   <template v-slot:append="{ item }">        
     <v-badge color="red accent-4" v-if="item.notify>0" :content="item.notify"></v-badge>
   </template>
   


    </v-treeview>


    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      
       class="top_bar"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"  color="white"></v-app-bar-nav-icon>
      <v-toolbar-title class="hidden-sm-and-down">{{applicationTitle}}</v-toolbar-title>
      <div class="ml-12 hidden-sm-and-down"></div>
       <v-text-field style="padding:4px;"
            prepend-inner-icon="mdi-magnify"  class="ml-8 " 
            clearable single-line dense
            placeholder="Search..."
             rounded  
            :class="searchFocused ? 'search_focus' : 'search_default'"
           v-model="query"
            @focus="searchFocused = true;"
            @blur="searchFocused = false;"            
            @keypress.native.13="search()"  
           @click:prepend-inner="search()"
            />                      
      
      
         <v-menu offset-y dark  min-width="320" style="margin-left:-106px;">
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex" >
                  <v-badge color="red accent-4" v-if="notifications_count>0" :content="notifications_count" left overlap transition="slide-x-transition" style="margin-left: 10px; margin-right:10px;">
            <v-icon v-on="on" color="rgb(212, 175, 55)" @click=" getNotifications()">mdi-bell</v-icon>                  
        </v-badge>          
        <div text v-else  left overlap transition="slide-x-transition" style="margin-left: 10px; margin-right:10px;">
            <v-icon v-on="on" color="white" >mdi-bell</v-icon>                  
        </div>         
          
          </div>
                                        
        </template>

     <v-list  v-if="inbox.length>0"  subheader style="background-color:#000000;" >     
         <v-subheader>You have new messages</v-subheader>   
          <v-list-item style="background-color:#000000;">
          <div style="width:100%;">
            <a href="/inbox" v-for="(item, index) in inbox" :key="index" style="min-width:100%; text-decoration:none;"><p style ="font-size:11pt; margin-top:-14px;  "
               
             >{{ item.name }} <span style="font-size:9pt; color:grey;">- {{timeSince(item.post_date)}} ago</span><br> <span class="orange--text" style="font-size:10pt;">{{shortMessage(item.message)}}</span> <span class="grey--text" ></span></p>
            </a>
             
          </div>
          </v-list-item>
     </v-list>
     <v-list  v-if="video_reports.length>0"  subheader style="background-color:#000000;" >     
         <v-subheader>The following videoreports has been fixed.</v-subheader>   
          <v-list-item style="background-color:#000000;">
          <div >
            <div style="width:100%;" v-for="(item, index) in video_reports" :key="index">
            <a v-if="item.show_id>0" :href="'/play_season/'+item.item_id+'/'+item.season+'/'+item.episode"  style="min-width:100%; text-decoration:none;"><p style ="font-size:11pt; margin-top:-14px;"> 
               {{ item.title }} <span style="font-size:9pt; color:grey;">- {{timeSince(item.fixed_date)}} ago</span><br> <span class="orange--text" style="font-size:10pt;">{{shortMessage(item.title)}}</span> <span class="grey--text" ></span></p>
            </a>
           
            <a v-else :href="'/play/'+item.item_id"  style="min-width:100%; text-decoration:none;"><p style ="font-size:11pt; margin-top:-14px;"> 
               {{ item.title }} <span style="font-size:9pt; color:grey;">- {{timeSince(item.fixed_date)}} ago</span><br> <span class="orange--text" style="font-size:10pt;">{{shortMessage(item.title)}}</span> <span class="grey--text" ></span></p>
            </a>
              </div>
          </div>
          </v-list-item>
     </v-list>
     
    <v-divider></v-divider>

        <v-list subheader style="background-color:#000000;" >     
          <div v-if="news!==0">
         <v-subheader>News</v-subheader>   
          <v-list-item style="background-color:#000000;">
          <div style="width:100%;">
            <a v-for="(item, index) in news" :key="index" @click="show_news(item)" style="min-width:100%;"><p style ="font-size:11pt; margin-top:-14px;  "
               
             >{{ item.title }} <span style="font-size:9pt; color:grey;">- {{timeSince(item.post_date)}} ago</span><br> <span class="orange--text" style="font-size:10pt;">{{shortMessage(item.message)}}</span> <span class="grey--text" ></span></p>
            </a>
             
          </div>
          </v-list-item>
          </div>
          
    <v-divider></v-divider>
        <v-subheader v-if="notifications.length>0">New Episodes</v-subheader>         
          <v-list-item style="background-color:#000000;"
                         
          >
          <div>
            <a><p style ="font-size:11pt; margin-top:-14px;" v-for="(item, index) in notifications"
            :key="index"    
            @click="play_item(item)"   >{{ item.title }} <span style="font-size:9pt; color:grey;">- {{timeSince(item.add_date)}} ago</span> <br> <span class="orange--text" style="font-size:10pt;">S{{item.season}}E{{item.episode}} </span> <span class="grey--text" >- {{item.episode_title}}</span></p>
            </a>
             
          </div>
          </v-list-item>
        </v-list>
      </v-menu>

         <v-menu offset-y dark min-width="140" class="ml-2" style="margin-left:-16px;">
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex" >
                 <v-btn icon   v-on="on"  ><v-avatar             
          >
                 
          <img width="150" height="150" class="imgUser" :src="user.image!=='' ? root_url + '/webapi/user/photo.php?user_id='+user.id : root_url + '/img/user.png'" alt="alt">

          </v-avatar>          
          </v-btn>  
          
          </div>
                                        
        </template>
        <v-list style="background-color:#000000;" >
          <v-list-item style="background-color:#000000;"
            v-for="(item, index) in user_menu"
            :key="index"    
            @click="user_menu_click(item)"                
          >
            <v-btn text small :color="item.color" style="min-width:180px; text-align:left;"><v-icon>{{item.icon}}</v-icon>  <v-list-item-title style="font-size:10pt;" class="ml-2">  {{ item.title }}  </v-list-item-title></v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

       </v-app-bar>
       
    <v-main id="main-page" >
     
      <v-container
     
        fluid        
      >
  

    <v-row>
    <v-dialog v-model="show_settings"  transition="dialog-bottom-transition" >      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_settings = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="save_settings()">Save</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Playback</v-subheader>
               <v-list-item>
                  <v-list-item-content >   
            <div >
           <p class="float-left">Player Mode</p>
            <v-switch style="margin-top:-6px;"  class="float-right "
                 v-model="compatibibilty_mode"
                :label="`${compatibibilty_mode ? 'Compatible' : 'Normal'}`">
          </v-switch>
             </div>

          <div >
           <p class="float-left">Autoplay videos </p>
            <v-switch style="margin-top:-6px;"  class="float-right mr-10"
                 v-model="autoplay"
                :label="`${autoplay ? 'Yes' : 'No'}`">
          </v-switch>
             </div>
                  </v-list-item-content>
          </v-list-item>  

          <v-list-item >
            <v-list-item-content >              
                <p class="float-left">Default Quality: </p>
                <v-select v-model="default_quality"
                  :items="qualities"
                  
                  :label="default_quality" ></v-select>              
            </v-list-item-content>
            </v-list-item>
          
        </v-list>
        <v-divider></v-divider>
        <v-subheader>Lisiting</v-subheader>
         <v-list-item >
           <v-list-item-content >  
             <div >
           <p class="float-left">Default Viewstyle: </p>
            <v-switch style="margin-top:-6px;"  class="float-right mr-10"
                 v-model="viewstyle"
                :label="`${viewstyle ? 'Cards' : 'List'}`">
          </v-switch>
             </div>
           </v-list-item-content>           
          </v-list-item>  
           <v-subheader>Privacy</v-subheader>
           <v-list-item >
           <v-list-item-content >  
             <div >
           <p class="float-left">Show my playback history in Now Watching</p>
            <v-switch style="margin-top:-6px;"  class="float-right mr-10"
                 v-model="show_my_playback"
                :label="`${show_my_playback ? 'Yes' : 'No'}`">
          </v-switch>
             </div>
           </v-list-item-content>           
          </v-list-item>  

          <v-subheader>Theme</v-subheader>
              <v-list-item >
            <v-list-item-content >              
                <p class="float-left">Select a theme</p>
                <v-select v-model="default_theme"
                  :items="themes"
                  
                  :label="default_theme" ></v-select>              
            </v-list-item-content>
            </v-list-item>

      </v-card>
    </v-dialog>


 <v-dialog v-model="show_help"  transition="dialog-bottom-transition">      
      <v-card class="mx-auto" width="500">
        <v-toolbar dark>
          <v-btn icon dark @click="show_help = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Help</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="send_support_inquiry()">Send</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Support Inquiry</v-subheader>
          <p class="ml-4">Please describe what you need help with and the more info you give us the easier it will be to help you faster.</p>
               <v-list-item>
                   <v-list-item-content>              
                      <v-text-field label="Subject" v-model="support_subject" hint="Write a subject" dense></v-text-field>              
                  </v-list-item-content>
              </v-list-item>  
               <v-list-item>
                   <v-list-item-content>              
                      <v-textarea label="Message" v-model="support_message" hint="Explain what you need help with" dense></v-textarea>              
                  </v-list-item-content>
              </v-list-item>  
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_news_dlg"  transition="dialog-bottom-transition" width="60%">      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_news_dlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selected_news.title}}</v-toolbar-title>
        </v-toolbar>
        <v-list min-height="auto">
          <v-textarea  dark color="orange" readonly="" class="ml-4 mt-2 news_text" v-model="selected_news.message"></v-textarea>
          <p class="ml-2" style="font-size:10pt; color:grey;">Posted by <span class="orange--text">{{selected_news.name}}</span> {{ timeSince(selected_news.post_date)}} ago </p>
          
               
        </v-list>
      </v-card>
    </v-dialog>

  </v-row>
  <router-view class="view two" name="b">
            
        </router-view>
      </v-container>
    </v-main>
  
</v-app>

</template>

<script>
import router from '../router.js' 
import $ from 'jquery';

  export default {
    name: 'Menu',     
    props: {
      source: String
    },

    data: () => ({
      notifications:[],
      support_subject:"",
      support_message:"",
      social_notify: 0,      
      notifications_count:0,
      show_notifications: false,
      show_help:false,
      show_news_dlg: false,
      selected_news:[],
      qualities: ['1080P', '720P', '480P'],
      themes: ['Default', 'Mountain', 'Brown Love', "Dark Mode","Christmas", "Purple Void"],
      //themes: ['Default', 'Mountain', 'Brown Love', "Dark Mode"],
      default_quality: "1080P",
      default_theme: "Default",
      autoplay: false,
      compatibibilty_mode: false,
      viewstyle:false,
      show_my_playback:false,
      query:"",
      drawer: null,
      show_settings:false,
      user: [],
      root_url: 'https://mobilevids.org',
      applicationTitle: "MobileVids",
      searchFocused: false,
      news:[],
      inbox:[],
      tree:[],
      pages: [],
      
      initiallyOpen:[],

      video_reports:[],
      user_menu: [
        { title: 'Account', icon: "mdi-account-edit", color:"white"},        
        { title: 'My TVShows' ,icon: "mdi-remote",color:"#d4af37"},
        { title: 'Inbox' ,icon: "mdi-mailbox",color:"white"},
        { title: 'Premium', icon: "mdi-crown-outline", color:"#d4af37"},
        { title: 'Invite', icon: "mdi-barcode", color:"#d4af37"},
        { title: 'Request Movie/Show', icon: "mdi-discord", color:"#d4af37"},       
        { title: 'Settings' ,icon: "mdi-wrench",color:"white"},
        { title: 'Help' ,icon: "mdi-help",color:"white"},
        { title: 'Sign Out' ,icon: "mdi-exit-run",color:"white"}
    ]
    }),
    computed:{

    },
    methods: 
    {    
      getTimeZone()
        {    
            return encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
        },

       getNotifications()
       {
         let self = this;
         
         $.getJSON(self.$root_url + "/webapi/user/notifications.php?user_id="+self.user.id +"&token="+self.user.auth_token + "&cmd=read", function() {           
             self.news.forEach(function(key) {
                key.read = true;
            });     
            self.notifications.forEach(function(key) {
                key.read = true;
            });
             self.inbox.forEach(function(key) {
                key.read = true;
            });
             self.video_reports.forEach(function(key) {
                key.read = true;
            });
            self.notifications_count = 0;
        });
       },
       timeSince(date) {
          var tempDate = new Date(date);
          var seconds = Math.floor((new Date() - tempDate) / 1000);
          var interval = seconds / 31536000;
          if (interval > 1) {
            return Math.floor(interval) + " years";
          }
          interval = seconds / 2592000;
          if (interval > 1) {
            return Math.floor(interval) + " months";
          }
          interval = seconds / 86400;
          if (interval > 1) {
            return Math.floor(interval) + " days";
          }
          interval = seconds / 3600;
          if (interval > 1) {
            return Math.floor(interval) + " hours";
          }
          interval = seconds / 60;
          if (interval > 1) {
            return Math.floor(interval) + " minutes";
          }
          return Math.floor(seconds) + " seconds";
      },
       shortMessage(message)
       {
         if(message.length>40)
         return message.substring(0,40) + "...";
         else return message;
       },
        show_news(item)
        {
          this.selected_news = item;
          this.show_news_dlg = true;
        },
        
         play_item(card)
        {                             
             router.push({ name: 'play_season', params: {showid: card.showid,season:card.season,episode:card.episode} });                     
        }
        ,
        send_support_inquiry()
        {

          let self = this;
            let dataobj = {subject:this.support_subject, message: this.support_message,user:this.user};        
            var datastring = JSON.stringify(dataobj);          

          $.post( self.$root_url + "/webapi/user/support.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
            .done(function( data ) {           
              
                let result = $.parseJSON(data);
              if(result.status==0)
              {                             
                self.$swal('Failed', result.reason, "error");                        
              }else
              {
                self.$swal('Success', result.reason, "success"); 
              }});
        },

      save_settings()
      {
        let self = this;        
        let  _default_quality  = 3;
        let  _default_theme  = 1;

        if(this.default_quality=="720P")
          _default_quality = 2;
        else if(this.default_quality=="1080P")
          _default_quality = 1;

      if(this.default_theme=="Mountain")
        _default_theme = 2;
      else if(this.default_theme=="Brown Love")
        _default_theme = 3;
      else if(this.default_theme=="Dark Mode")
        _default_theme = 4;
      else if(this.default_theme=="Christmas")
        _default_theme = 5;
      else if(this.default_theme=="Purple Void")
        _default_theme = 6;
        
        //_default_theme = 1;
      


        let dataobj = {default_quality:_default_quality, auto_play: this.autoplay,compatibibilty_mode:this.compatibibilty_mode, view_style: this.viewstyle,show_my_playback:this.show_my_playback, theme: _default_theme};        
        var datastring = JSON.stringify(dataobj);          
          $.post( self.$root_url + "/webapi/user/save_settings.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
            .done(function( data ) {            
                        
                let result = $.parseJSON(data);
              if(result.status==0)
              {                             
                self.$swal('Failed', data, "error");                        
              }else
              {
                  self.show_settings = false;
                  
                  if(self.autoplay==true)
                     self.user.auto_play  = 1;
                  else
                    self.user.auto_play  = 0;
                  
                  if(self.compatibibilty_mode==true)
                     self.user.compatibibilty_mode  = 1;
                  else
                    self.user.compatibibilty_mode  = 0;                  

                  if(self.viewstyle==true)
                    self.user.view_style = 1;
                  else
                    self.user.view_style = 0;

                  if(self.show_my_playback == true)
                    self.user.show_my_playback = 1;
                  else
                    self.user.show_my_playback = 0;

                  
                  self.user.default_quality = _default_quality;  
                  self.user.theme = _default_theme;                
                  localStorage.setItem('user',JSON.stringify(self.user));   
                  

                this.dialog = false;
                self.$swal.fire(
                  'Settings updated',
                  '',
                  'success'
                );                
                }          
            });  
      },

      search()
          {
            router.push({ name: 'search', params: {query: this.query} });   
          },
        user_menu_click (menu) {
            switch(menu.title)
            {
                case "Sign Out": 
                      localStorage.removeItem('user');
                      router.push({ name: 'login' });
                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
                case "Inbox" :  router.push({ name: 'inbox' });
                break;
                case "Premium":
                  router.push({ name: 'premium' });
                break;
                case "Invite":
                  if(this.user.vip == 'true')
                    router.push({ name: 'invites' });
                  else
                    this.$swal("Premium Feature","This feature requires Premium membership, upgrade now to get full access to Mobilevids.","error");                 
                break;
                case "Request Movie/Show":     
                  if(this.user.vip == 'true')
                    window.location = "https://discord.gg/R2w3QzF";
                  else
                    this.$swal("Premium Feature","This feature requires Premium membership, upgrade now to get full access to Mobilevids.","error");                   
                break;

                case "Settings": this.show_settings=true;

                 break;
                case "Help": this.show_help = true;
                 break;
                case "My TVShows": 
                 if(this.user.vip == 'true')
                    router.push({ name: 'myshows' });
                  else
                  this.$swal("Premium Feature","This feature requires Premium membership, upgrade now to get full access to Mobilevids.","error");
                break;                
            }            
        },
        openNode(item)
        {
          
          this.initiallyOpen = item;       
          this.setCookie("menuNodes",JSON.stringify(this.initiallyOpen),30); 
        },
        setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays*24*60*60*1000));
          let expires = "expires="+ d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
          },
        getCookie(cname) {
          let name = cname + "=";
          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.split(';');
          for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        },
        goToPage(item)
        {          
           router.push({ path: item[0].path});

        },
        buildPages()
          {
            this.pages = [];                      

            //Movies
            let Movies = {name:"Movies",icon:"mdi-movie", children:[]};
              Movies.children.push({name: "Browse", path: "/movies", icon:"mdi-view-list" });        
              Movies.children.push({name: "Popular", path: "/top_movies", icon: "mdi-movie-filter"});                                
            // TV
            let TVShows = {name:"TV Shows",icon: "mdi-remote-tv", children:[]};
              TVShows.children.push({name: "Browse ", path: "/tvshows", icon: "mdi-view-list"});      
              TVShows.children.push( {name: "Popular ", path: "/top_shows", icon: "mdi-monitor-star"});  
              TVShows.children.push({name: "Calendar", path: "/show_calendar", icon: "mdi-calendar"});              
             //Sports
             let Sports = {name:"Sports",icon: "mdi-basketball", children:[]};
                Sports.children.push({name: "Live TV", path: "/livetv",icon: "mdi-radio-tower"}); 
                Sports.children.push({name: "PPV", path: "/ppv",icon:"mdi-tennis-ball"});                         
             //People 
            let People = {name:"People",icon: "mdi-account-cowboy-hat", children:[]};
               People.children.push({name: "Now Watching", path: "/now_watching", icon: "mdi-account-group"});  
               People.children.push({name: "Chat", path: "/social", icon: "mdi-chat",notify:this.social_notify});        
              
              //MyStuff         
              let MyStuff = {name:"My Stuff",icon:"mdi-account-star", children:[]};                                 
                MyStuff.children.push({name: "Favorite Shows", path: "/myshows", icon: "mdi-star"});  
                MyStuff.children.push({name: "Playlists", path: "/playlists", icon: "mdi-playlist-play"});  

              //Other
              let Other = {name:"Other",icon: "mdi-tools", children:[]};                                 
                Other.children.push( {name: "Apps", path: "/apps", icon: "mdi-application"});        
               if (this.user.users_permission == 1)
                {
                    Other.children.push({name: "Admin", path: "/manage", icon: " mdi-account-tie"});
                }  
              
              this.pages.push({name:"Home",icon:"mdi-home-circle", path:"/home", children:[]});
              this.pages.push(MyStuff);
              this.pages.push(Movies);
              this.pages.push(TVShows);
              this.pages.push(Sports);
              this.pages.push(People);
              
              this.pages.push(Other);
            
            
            

            let str = this.getCookie("menuNodes");
            if(str!=="")
            {
              let vals = JSON.parse(str);              
              this.initiallyOpen = vals;
            }else            
            this.initiallyOpen.push(Movies,TVShows);              

          }
    },
    
  mounted(){

           
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));        
        
        if(this.user.view_style!=undefined && this.user.view_style>0)
        this.viewstyle = true;
        else
        this.viewstyle = false;
        
        if(this.user.show_my_playback!=undefined && this.user.show_my_playback>0)
        this.show_my_playback = true;
        else
        this.show_my_playback = false;

        
        
        if(this.user.compatibibilty_mode!=undefined && this.user.compatibibilty_mode>0)
          this.compatibibilty_mode = true;
        else
          this.compatibibilty_mode = false;
        
        if(this.user.auto_play!=undefined && this.user.auto_play>0)
          this.autoplay = true;
        else
          this.autoplay = false;


        if(this.user.default_quality!=undefined && this.user.default_quality>0)
          switch(this.user.default_quality)
          {
              case 1: this.default_quality = "1080P"; break;
              case 2: this.default_quality = "720P"; break;              
              case 3: this.default_quality = "480P"; break;
          }          
        else
        this.default_quality = "480P";    
        
        if(this.user.theme!=undefined && this.user.theme>0)
          switch(this.user.theme)
          {
              case 1: this.default_theme = "Default"; break;
              case 2: this.default_theme = "Mountain"; break;              
              case 3: this.default_theme = "Brown Love"; break;
              case 4: this.default_theme = "Dark Mode"; break;
              case 5: this.default_theme = "Christmas"; break;
              case 6: this.default_theme = "Purple Void"; break;
          }          
        else
        this.default_theme = "Default";    


            let self = this;
    
      $.getJSON(self.$root_url + "/webapi/user/notifications.php?user_id="+self.user.id +"&token="+self.user.auth_token+ "&timezone="+self.getTimeZone(), function(data) {
            
            
            if(self.user.vip == 'true')            
            {
                self.notifications = data.items;
                if(self.notifications!=undefined & self.notifications.length>0)
                self.notifications.forEach(function(key) {
                if(key.read==false) self.notifications_count++;                  
              });     
            
            }
          
            self.social_notify = data.social_notify;
            self.news          = data.news;
            self.inbox         = data.inbox; 
            self.video_reports = data.video_reports;
    
            
            self.loading = false;
            if(self.news!==0)
            self.news.forEach(function(key) {
              if(key.read==false) self.notifications_count++;                  
            });     
        
            if(self.inbox!=undefined & self.inbox.length>0)
            self.inbox.forEach(function(key) {
                if(key.read==false) self.notifications_count++;                  
              });   
            
            if(self.video_reports!=undefined & self.video_reports.length>0)
               self.video_reports.forEach(function(key) {
                if(key.read==false) self.notifications_count++;                  
              });                 
             self.buildPages();           
           });
       
           self.buildPages();   
        
      this.$vuetify.theme.dark = true
    },
  }
</script>
<style>
    textarea {
        color: #fff;
    }
  
</style>
