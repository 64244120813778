<template>
<div>
  <div class="player_topbar" v-if="playlist===undefined"> 
  <v-btn rounded :to="'/show/' + current_video.show_id" v-if="current_video.episode!==undefined"  color="grey darken-4">
    <v-icon dark v-on="on" class="mr-2 " style="margin-top:0px;">mdi-arrow-left-bold  </v-icon>
    {{this.current_video.title}}
  </v-btn> 

  </div>

  <div id="player_control">    
  </div>
<div class="player_dashboard">     
  <div class="float-left mt-2">    
    <span  v-if="current_video.episode!==undefined"> Episode {{current_video.episode}}: {{current_video.episode_title}} </span>      
  </div>      
  <div v-if="!video_like">   
    <v-btn plain  @click="disLike()" class="float-right ma-1 " color="red" >    
      <v-icon >mdi-thumb-down</v-icon> <span style="color:white;">{{current_video.dislikes}}</span>
    </v-btn >              

    <v-btn plain  @click="like()" class="float-right ma-1" color="blue">
      <v-icon>mdi-thumb-up</v-icon> <span style="color:white;">{{current_video.likes}}</span>
    </v-btn >  
  </div>  
  <p v-if="video_like" class="float-right">Thanks for your vote</p>


        
  </div>
<SiteConfig />
</div>


  
</template>


<script>
import $ from 'jquery';
import videojs from 'video.js';
var ModalDialog = videojs.getComponent('ModalDialog');                
 
                      
                      

import SiteConfig from '../components/SiteConfig';
export default {
  name: 'Player',   
   props: {
      playlist: String,
        options: {
            type: Object,
            default() {
                return {};
            }
        }
  },
  data: () => ({   
      btnSearchSubs: false,
      subtitle_search: false,
      subtitle_offset_dlg: false,
      show_report_error: false,
      report_data: {broken_video: false, broken_audiovisual: false, wrong_video: false, comment: ""},
      subtitle_offset: 0,
      btnTogglePlay: "mdi mdi-play mdi-36px",
      isPlaying: false,
      btnSubtitle: 'mdi mdi-24px mdi-comment-text-outline',
      resume_time: "0:00",
      selected_quality:"mdi mdi-quality-low mdi-24px",
      selected_quality_color: "white",
      video: [{"start_time": 0}],
      qualities: [],
      subtitles:[],
      subs_available:[],
      selected_sub_download: "",
      video_loaded:false,
      video_buffer: 0,
      scroll_Position:0,
      video_progress: 0,    
      current_video: [],  
      user          :[],
      modal: [],
      showVolume: false,
      video_length: "0:00",
      video_position: "0.00",
      video_volume: 50,
      current_src: "",
      current_time: 0,
      play_nextDialog: false,
      cancel_autoplay: false,
      resume_dialog:false,
      next_episode: 0,
      next_timer: 0,
      last_input: 0,
      player: null,
      video_like:false,
  }),
  created () {
        this.user = $.parseJSON(localStorage.getItem('user'));        
        let self = this;
        $( window ).scroll(function() {
          self.scroll_Position = $(document).scrollTop();
        });
        
  },
  methods:{
    
    toHHMMSS(secs) {
    
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60
    if(isNaN(seconds) || isNaN(minutes))
    {
        return "0:00:00";
    }
    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
    },
    loadNext(video)
    { 
        let self = this;
        self.modal.close();
        var videoTrackList = this.player.videoTracks();
        
         for (var i = 0; i < videoTrackList.length; i++) {
          let track = videoTrackList[i];
          self.player.videoTracks().removeTrack(track);
         }

         var tracks = this.player.textTracks();
          console.log(tracks) ;
        for (var x = 0; x < tracks.length; x++) {
          var track = tracks[x];
          console.log("Removing" + x) ;
          self.player.textTracks().removeTrack(track);
        }


        this.current_video = video;
        this.video_progress = 0;
        this.video_length = "0:00";
        this.video_position = "0:00";   
        
         this.qualities = [];
        this.subtitles = [];
        if(video.src_vip_hd_1080p!==undefined && video.src_vip_hd_1080p!=='')  
        {
          let track  = {kind: "Alternative", label: "1080P", language: "en", src: video.src_vip_hd_1080p}            
          this.player.videoTracks().addTrack(track);
          this.qualities.push({title:"1080P", icon:"mdi mdi-quality-high mdi-24px", src: video.src_vip_hd_1080p,color:"#fcba03",VIP:true});
        }      
            
        if(video.src_vip_hd!==undefined && video.src_vip_hd!=='')
        {
          let track  = {kind: "Alternative", label: "720P", language: "en", src: video.src_vip_hd}            
          this.player.videoTracks().addTrack(track);
          this.qualities.push({title:"720P", icon:"mdi mdi-quality-medium mdi-24px", src: video.src_vip_hd,color:"#fcba03",VIP:true});      
        }
            
        if(video.src_vip_sd!==undefined && video.src_vip_sd!=='')       
        {
           let track  = {kind: "Alternative", label: "480P", language: "en", src: video.src_vip_sd}            
            this.player.videoTracks().addTrack(track);
            this.qualities.push({title:"480P", icon:"mdi mdi-quality-low mdi-24px", src: video.src_vip_sd,color:"dark",VIP:true});
        }     
            
        
            
        
        if(self.user.vip == 'true')
        {
            switch(self.user.default_quality)
            {
              case 1: 
                  if(video.src_vip_hd_1080p!=undefined && video.src_vip_hd_1080p!=='')
                  {               
                      self.selected_quality = "mdi mdi-quality-high mdi-24px";
                      self.current_src = video.src_vip_hd_1080p;
                      self.selected_quality_color ="#fcba03";
                  }else if(video.src_vip_hd!=undefined && video.src_vip_hd!=='')
                  {               
                          self.selected_quality = "mdi mdi-quality-medium mdi-24px";
                          self.selected_quality_color ="#fcba03";
                          self.current_src = video.src_vip_hd;
                  }else
                  {                     
                          self.selected_quality = "mdi mdi-quality-low mdi-24px";
                          self.current_src = video.src_vip_sd;
                  }
              
              break;
              case 2: 
                      if(video.src_vip_hd!=undefined && video.src_vip_hd!=='')
                      {                              
                              self.selected_quality = "mdi mdi-quality-medium mdi-24px";
                              self.current_src = video.src_vip_hd;
                              self.selected_quality_color ="#fcba03";
                      }else
                      {
                              self.selected_quality = "mdi mdi-quality-low mdi-24px";
                              self.current_src = video.src_vip_sd;
                      }                    
              break;
              case 3:
                      self.selected_quality = "mdi mdi-quality-low mdi-24px";
                      self.current_src = video.src_vip_sd;
              break;
              default: 
                      self.selected_quality = "mdi mdi-quality-low mdi-24px";
                      self.current_src = video.src_vip_sd;
              break;
            }

        }else
        {
          self.current_src = video.src_free_sd;
        }
        
              
    

        self.player.src({
          src: self.current_src,
          type: "video/mp4"
        });
     
        if(video.subs!=null)
        video.subs.forEach(function(element) {          
               let track  = {kind: "captions", label: element.language, srclang: element.iso_lang, src: element.url}                            
                if( element.iso_lang ==="en")
                 track.mode = 'showing';
                 self.player.addRemoteTextTrack(track,false);
                
        });

        self.player.currentTime(0);
        self.player.play();

    },
    loadSources(video)
    {
      
       if (this.player) {
            this.player.dispose();
            this.video_like = false;
        }
        this.showVolume = false;
        this.isPlaying = false;
        this.current_video = video;
        this.video_progress = 0;
        this.video_length = "0:00";
        this.video_position = "0:00";
        
        let subs   = "";
        let sources     = "";
         let self = this;
        // Check start time
        
        if(video.start_time>0)
        {                        
            this.resume_time = self.toHHMMSS(video.start_time);
            
        } 
          
        this.qualities = [];
        this.subtitles = [];
        if(video.src_vip_hd_1080p!==undefined && video.src_vip_hd_1080p!=='')        
            this.qualities.push({title:"1080P", icon:"mdi mdi-quality-high mdi-24px", src: video.src_vip_hd_1080p,color:"#fcba03",VIP:true});
        if(video.src_vip_hd!==undefined && video.src_vip_hd!=='')
            this.qualities.push({title:"720P", icon:"mdi mdi-quality-medium mdi-24px", src: video.src_vip_hd,color:"#fcba03",VIP:true});      
        if(video.src_vip_sd!==undefined && video.src_vip_sd!=='')            
            this.qualities.push({title:"480P", icon:"mdi mdi-quality-low mdi-24px", src: video.src_vip_sd,color:"dark",VIP:true});
        
            
        
        if(self.user.vip == 'true')
        {
            switch(self.user.default_quality)
            {
              case 1: 
              if(video.src_vip_hd_1080p!=undefined && video.src_vip_hd_1080p!=='')
              {
                  sources = '<source id="video_src" src="'+video.src_vip_hd_1080p +'" type="video/mp4">'
                  self.selected_quality = "mdi mdi-quality-high mdi-24px";
                  self.current_src = video.src_vip_hd_1080p;
                   self.selected_quality_color ="#fcba03";
              }else if(video.src_vip_hd!=undefined && video.src_vip_hd!=='')
              {
                      sources = '<source id="video_src" src="'+video.src_vip_hd +'" type="video/mp4">'
                      self.selected_quality = "mdi mdi-quality-medium mdi-24px";
                      self.selected_quality_color ="#fcba03";
                      self.current_src = video.src_vip_hd;
              }else
              {
                     sources = '<source id="video_src" src="'+video.src_vip_sd +'" type="video/mp4">'
                      self.selected_quality = "mdi mdi-quality-low mdi-24px";
                      self.current_src = video.src_vip_sd;
              }
              
              break;
              case 2: 
                      if(video.src_vip_hd!=undefined && video.src_vip_hd!=='')
                      {
                              sources = '<source id="video_src" src="'+video.src_vip_hd +'" type="video/mp4">'
                              self.selected_quality = "mdi mdi-quality-medium mdi-24px";
                              self.current_src = video.src_vip_hd;
                              self.selected_quality_color ="#fcba03";
                      }else
                      {
                            sources = '<source id="video_src" src="'+video.src_vip_sd +'" type="video/mp4">'
                              self.selected_quality = "mdi mdi-quality-low mdi-24px";
                              self.current_src = video.src_vip_sd;
                      }                    
              break;
              case 3: sources = '<source id="video_src" src="'+video.src_vip_sd +'" type="video/mp4">'
                      self.selected_quality = "mdi mdi-quality-low mdi-24px";
                      self.current_src = video.src_vip_sd;
              break;
              default: 
                      sources = '<source id="video_src" src="'+video.src_vip_sd +'" type="video/mp4">'
                      self.selected_quality = "mdi mdi-quality-low mdi-24px";
                      self.current_src = video.src_vip_sd;
              break;
            }

        }else
        {
          sources = '<source id="video_src" src="'+video.src_free_sd +'" type="video/mp4">'
          self.current_src = video.src_free_sd;
        }
        
        self.subtitles.push({title:"Get subtitles", src:"",color:"#fcba03"});
        
        if(video.subs!=null)
        video.subs.forEach(function(element) {        
            if(element.iso_lang === "en" || element.iso_lang === "eng")            
                subs = subs + '<track label="'+element.language+'" kind="subtitles" src="'+element.url+'" srclang="'+element.iso_lang+'" default>';
            else
                subs = subs + '<track label="'+element.language+'" kind="subtitles" src="'+element.url+'" srclang="'+element.iso_lang+'">';
            self.subtitles.push({title:element.language, src: element.url,srcLang:element.iso_lang, color: "white"});
        });
        self.subtitles.push({title:"Off", src:"off",color:"white"});
        var poster = self.$root_url +'/media/posters/'+video.poster;
        if(video.media_images!= undefined)
        {
          poster = self.$root_url +'/media/images/'+video.media_images[0]
        }
                 
       let auto_play = "";
       if(self.user.auto_play==1) auto_play = "autoplay";

       $("#player_control").html( '<video id="video_player" class=" mt-6 video-js  vjs-default-skin vjs-big-play-centered"  crossorigin="anonymous" controls  x-webkit-airplay="allow" playsinline controlsList="nodownload" oncontextmenu="return false;" '+auto_play+' style="width:100%; min-height:500px;" poster="'+poster+'"> '
        +sources+  subs +      
        '</video> ');         
        
       if(self.user.compatibibilty_mode<1) 
        self.buildPlayer();
        

        if(self.current_time<2)
        {
          this.video_loaded = true;
        }
        
          let vol = this.user.default_volume;
          if(vol>0)
            this.setVolume(vol);
        
    },like(){
      let self = this;
      let api_url = this.$root_url + "/webapi/videos/rateVideo.php?like=1&vid="+this.current_video.video_id + "&user_id=" + this.user.id+"&token="+this.user.auth_token;
      $.getJSON(api_url, function() {                 
        self.video_like = true;
        self.current_video.likes++;
        if(self.current_video.dislikes>0)
          self.current_video.dislikes--;
       });

    },
    disLike(){      
      let self = this;
      let api_url = this.$root_url + "/webapi/videos/rateVideo.php?like=0&vid="+this.current_video.video_id + "&user_id=" + this.user.id+"&token="+this.user.auth_token;
      $.getJSON(api_url, function() {           
        self.video_like = true;
        self.current_video.dislikes++;
        if(self.current_video.likes>0)
          self.current_video.likes--;
       });
    },
    showPlayNextDialog()
    {
    
      let self = this;
      self.modal = new ModalDialog( self.player, {

            content:'Coming next..',
            description:"",
            pauseOnOpen:true,
            temporary: true
          });       
            self.modal.addClass('vjs-my-fancy-modal'); 
           self.player.addChild(self.modal); 
          self.modal.open();
          let src = '<div class="play_next_dialog"><p id="next_time_text"></p>';
          src+= '<button class="playNext mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default blue--text">Play Now</button>';
          src+= '<button class="cancelNext mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default blue--text">Cancel</button></div>';
          self.modal.el().children[1].innerHTML+= src;

        
            $(".playNext").click(function(){   
                self.next_timer = 0;
                self.modal.close();
            });
             $(".cancelNext").click(function(){   
               self.cancel_autoplay = true;
                self.modal.close();
            });
    },
    showResumeModal()
    {
       
      let self = this;
      self.modal = new ModalDialog( self.player, {

            content:'Pick up where you left off?',
            description:"",
            pauseOnOpen:true,
            temporary: true
          });       
            self.modal.addClass('vjs-my-fancy-modal'); 
           self.player.addChild(self.modal); 
          self.modal.open();
          let src = '<div class="resume_dialog"><p>Do you wish to resume playback at ' + self.resume_time + '?</p>';
          src+= '<button class="resumePlayback mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default blue--text">Sure</button>';
          src+= '<button class="restartPlayback mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default blue--text">Play From Start</button></div>';
          self.modal.el().children[1].innerHTML+= src;

        
            $(".resumePlayback").click(function(){   
                self.player.currentTime(self.current_video.start_time); 
                self.modal.close();
            });
             $(".restartPlayback").click(function(){   
                self.modal.close();
            });

    },
    search_subs()
    {
      let self = this;
      self.modal = new ModalDialog( self.player, {

            content:'Search Subtitles',
            description:"MV will search the web for subtitles for this video.",
            pauseOnOpen:true,
            temporary: true
          });       
            self.modal.addClass('vjs-my-fancy-modal'); 
           self.player.addChild(self.modal); 
          self.modal.open();      
          
  
          if(self.user.vip != 'true'){                        
          this.$swal("Premium Feature","This feature requires Premium membership, upgrade now to get full access to Mobilevids.","error");
          return;
          }

          let api_url = self.$root_url + "/webapi/subtitle/search.php?vid="+self.current_video.movie_id + "&user_id=" + self.user.id+"&token="+self.user.auth_token;
            if(self.current_video.episode != null)                            
                api_url = self.$root_url + "/webapi/subtitle/search_show.php?vid="+self.current_video.video_id + "&user_id=" + self.user.id+"&token="+self.user.auth_token
                
                                   
                self.subtitle_search = true;
                $.getJSON(api_url, function(data) {                    
                    self.subs_available = data.subs;
                       let b = false;     
                       if(data.subs!==undefined)      
                       data.subs.forEach(function(element){                         
                            let src = '<div class="subSearchItem btnDownloadSub" data-id="'+element.download_id+'"><img src="'+element.lang_icon+'" />';
                             src+= '<p>'+element.title+ ' ' + element.release + '</p>';
                             src+= '</div>'
                            if(b)
                            {                      
                            self.modal.el().children[1].innerHTML+= '<div class="mt-2" style="border-radius: 5px; background-color:rgba( 56, 56, 56,0.1);">'+src+'</div>';
                            }else{
                            self.modal.el().children[1].innerHTML+= '<div class="mt-2" style="border-radius: 5px; background-color:rgba( 56, 56, 56,0.6);">'+src+'</div>';
                            }
                            b = !b;
                       
                          });
                          else
                          self.modal.el().children[1].innerHTML+= '<div class="mt-2" style="border-radius: 5px; background-color:rgba( 56, 56, 56,0.6); text-align:center; padding:120px; margin-auto;">'+data.reason+'</div>';
                          
                
             $(".btnDownloadSub").click(function(){   
           self.modal.close();
           let download_id = $(this).attr("data-id");
                                      
            self.subs_available.forEach(function(element){
              if(element.download_id==download_id)
              {
                self.modal.close();
                 self.modal = new ModalDialog( self.player, {
                  content:'Download Options',
                  description:"MV will search the web for subtitles for this video.",
                  pauseOnOpen:true,
                  temporary: true
                });
                self.modal.addClass('vjs-my-fancy-modal'); 
                self.player.addChild(self.modal); 
                self.modal.open();      
                let src =  element.title + element.release + "[" + element.language + "]";
                src+='<br><span style="font-size:10pt; font-weight:bold;" class="orange--text text--lighten-1 ">Optional: </span><br><span style="font-size:10pt;">If you want to set an offset to the subtitle you can do it here.</span></p>';
                src+= '<div class="slidecontainer"><p id="sliderOutput">0 Seconds</p> <input type="range" min="-60" max="60" value="0" class="slider" id="myRange"></div>';
                src+= '<button id="btnDownloadSelectedSub" type="button" class="success v-btn v-btn--flat v-btn--text theme--dark v-size--default "><span class="v-btn__content">Download</span></button>';
          
                self.modal.el().children[1].innerHTML+= '<div class="mt-2" style="border-radius: 5px; background-color:rgba( 56, 56, 56,0.6); padding:10px;">'+src+'</div>';

                var slider    = document.getElementById("myRange");
                var output    = document.getElementById("sliderOutput");
                var btnSubDl  = document.getElementById("btnDownloadSelectedSub"); 
                output.innerHTML = slider.value; // Display the default slider value
                // Update the current slider value (each time you drag the slider handle)
                slider.oninput = function() {
                  output.innerHTML = this.value + " seconds";
                }
                btnSubDl.onclick = function(){
                  self.selected_sub_download = element;
                  self.selected_sub_download.offset = slider.value;
                  self.download_subtitle();
                };

              return;
              }
            });
              
          });

                });
              
    },
      download_subtitle()
      {
          let self = this;
          let vid_type = "movie";
         if(this.current_video.episode != null)
            vid_type = "tv";
        

          var datastring = JSON.stringify(this.selected_sub_download);   
          var vid_data = JSON.stringify(this.current_video);   

          $.post( self.$root_url + "/webapi/subtitle/download.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring, video_data:vid_data, video_type:vid_type})
                .done(function( data ) {                                  
                    let result = $.parseJSON(data);
                    console.log(data);
                    if(result.status==0)
                    {
                      self.$swal("Subtitle could not be downloaded",result.reason,"error");
                    }else
                    {
                        if(result!=null && result.subtitle!=null)
                        {                 
                            let entry = result.subtitle;                            
                            if(self.current_video.subs==null)
                              self.current_video.subs = [];                            
                            self.current_video.subs.push({url:entry.url, iso_lang:entry.iso_lang,language:entry.language}); 
                           
                            self.player.dispose();
                            self.loadSources(self.current_video);
                        }
                        
                                           
                    }
                   
                    
            }); 
      },
      buildPlayer()
      {
        let self = this;
        this.player = videojs('video_player',{
          responsive:true,
          fluid:true,
          controlBar:{ 
          children: [
            'playToggle',
            'muteToggle',
            'volumeControl',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'liveDisplay',
            'flexibleWidthSpacer',
            'progressControl',          
            'subsCapsButton',
            'fullscreenToggle'
            ]
          }
        });       

        this.player.addClass('vjs-matrix');
   

       
    
        //btnRewind
        var interval_btnRew;
        var btnRew = this.player.controlBar.addChild("button",{},0).el();
        btnRew.innerHTML = '<span class="mdi mdi-skip-backward" style="font-size:22pt;"></span>';
        btnRew.onclick = function(){   

          let currentTime = self.player.currentTime();
          if(currentTime>15)
          {                
                self.player.currentTime(currentTime-15);                
          }                               
        }  
        btnRew.onmousedown = function() {
          interval_btnRew = setInterval(function(){  
            
            let currentTime = self.player.currentTime();          
            if(currentTime>15)
              self.player.currentTime(currentTime-15);
            }, 
            500);
        }
          btnRew.onmouseup = function() {
            clearInterval(interval_btnRew);
          }


        //btnSkip
         var interval_btnSkip;                  
        var btnSkip = this.player.controlBar.addChild("button",{},2).el();
        btnSkip.innerHTML = '<span class="mdi mdi-skip-forward" style="font-size:22pt;"></span>';
        btnSkip.onclick = function(){                    
          let currentTime = self.player.currentTime();                    
          self.player.currentTime(currentTime+15);          
        }  
        

        btnSkip.onmousedown = function() {
          interval_btnSkip = setInterval(function(){  
            let currentTime = self.player.currentTime();          
            self.player.currentTime(currentTime+15);
            }, 
            500);
        }
          btnSkip.onmouseup = function() {
            clearInterval(interval_btnSkip);
          }
      
            // get the current players VideoTrackList object
         let srcs = "";
          let track = {};
          let video = this.current_video;
          
          let defaultQualitySet = false;
        if(video.src_vip_hd_1080p!==undefined && video.src_vip_hd_1080p!=='' && self.user.vip == 'true')     
        {
          track  = {kind: "Alternative", label: "1080P", language: "en", src: video.src_vip_hd_1080p}            
          this.player.videoTracks().addTrack(track);
          srcs = '<button class="ml-2  v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--dark v-size--default light-green darken-3 btnSourceSelect" data-val="1080P" style="width: 25%;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-quality-high mdi-24px theme--dark"></i> 1080P</span></button><br>';
          
          defaultQualitySet = true;
        }else
        {          
          srcs = '<button disabled class="ml-2 v-btn v-btn--disabled theme--dark v-size--default" ><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-quality-high mdi-24px theme--dark"></i> 1080P</span></button><br>';
        }
            
        if(video.src_vip_hd!==undefined && video.src_vip_hd!=='' && self.user.vip == 'true')
        {
            track  = {kind: "Alternative", label: "720P", language: "en", src: video.src_vip_hd}
            this.player.videoTracks().addTrack(track);
            let defSrc = "";
            if(!defaultQualitySet)
            {
              defaultQualitySet = true;
              defSrc = "light-green darken-3";
            }
              
            srcs = srcs  + '<button class="mt-1 ml-2  v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--dark v-size--default '+defSrc+' btnSourceSelect" data-val="720P" style="width: 25%;" ><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-quality-medium mdi-24px theme--dark"></i> 720P</span></button><br>';
            
        }else
        {
           srcs = srcs  + '<button disabled class="mt-1 ml-2 v-btn v-btn--disabled theme--dark v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-quality-medium mdi-24px theme--dark"></i> 720P</span></button><br>';
        }
        if(video.src_vip_sd!==undefined && video.src_vip_sd!=='') 
        {
          track  = {kind: "Alternative", label: "480P", language: "en", src: video.src_vip_sd}
          this.player.videoTracks().addTrack(track);
          let defSrc = "";
            if(!defaultQualitySet)
              defSrc = "light-green darken-3";

          srcs = srcs  + '<button class=" mt-1 ml-2  v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--dark v-size--default'+defSrc+' btnSourceSelect"  data-val="480P" style="width: 25%;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-quality-low mdi-24px theme--dark"></i> 480P</span></button>';
          
        }       
        // btnDownloadSubs
        
        let settingsMenuSrc = '<button id="srcSelect"><span class="mdi mdi-cog" style="font-size:22pt;"></span></button>';
        settingsMenuSrc+= '<div class="mvSettingsMenu" data-state="1"><span class="mdi mdi-movie-cog" style="font-size:12pt;"> Quality</span>';
        settingsMenuSrc += '<div class="sourceMenu">'+srcs+'</div>';
        settingsMenuSrc+= '<div class="mt-1 sourceSubs"><span class="mdi mdi-text-search" style="font-size:12pt;"> Subtitles</span></div>';
        if(self.user.vip == 'true')
          settingsMenuSrc+='<button class="btnSearchSubs mt-4 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-comment-search mdi-24px theme--dark"></i> Search subtitles</span></button>'
        else
          settingsMenuSrc+='<button disabled class="v-btn v-btn--disabled theme--dark v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-comment-search mdi-24px theme--dark"></i> Search subtitles</span></button>'
        settingsMenuSrc+= '<div class="mt-2 sourceMark"><span class="mdi mdi-eye" style="font-size:12pt;"> Mark</span></div>';
        settingsMenuSrc += '<div class="sourceMenu">';
        if(self.current_video.watched!=null && self.current_video.watched==1)
        {
            settingsMenuSrc += '<button class=" mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default btnToggleWatch blue--text"  data-val="yes" style="width: 45%;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-eye-check mdi-24px theme--dark"></i> Watched</span></button>';
            settingsMenuSrc += '<button class=" mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default btnToggleWatch"  data-val="no" style="width: 45%;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-eye-off mdi-24px theme--dark"></i> Unwatched</span></button></div>';
        }else
        {
          settingsMenuSrc += '<button class=" mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default btnToggleWatch"  data-val="yes" style="width: 45%;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-eye-check mdi-24px theme--dark"></i> Watched</span></button>';
            settingsMenuSrc += '<button class=" mt-1 ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default btnToggleWatch blue--text"  data-val="no" style="width: 45%;"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-eye-off mdi-24px theme--dark"></i> Unwatched</span></button></div>';
        }
        

        settingsMenuSrc+= '</div>';
         //btnSourceSelect
        var btnSourceSelect = this.player.controlBar.addChild("button",{},12);
        btnSourceSelect.el().innerHTML = settingsMenuSrc;

        let btnSrcSelect = document.getElementById("srcSelect");
        $("#srcSelect").on('click',function(){           
          if($(".mvSettingsMenu").attr('data-state') == 1) {
            $(".mvSettingsMenu").attr('data-state', 0);
            $(".mvSettingsMenu").css('display', 'block');
          }
          else {
            $(".mvSettingsMenu").attr('data-state', 1);
            $(".mvSettingsMenu").css('display', '');
          }
        });
        
        btnSrcSelect.addEventListener("touchend", function(){
          if($(".mvSettingsMenu").attr('data-state') == 1) {
            $(".mvSettingsMenu").attr('data-state', 0);
            $(".mvSettingsMenu").css('display', 'block');
          }
          else {
            $(".mvSettingsMenu").attr('data-state', 1);
            $(".mvSettingsMenu").css('display', '');
          }
        });
          
        $(".btnToggleWatch ").click(function(){      
            $(".mvSettingsMenu").attr('data-state', 1);
            $(".mvSettingsMenu").css('display', '');

              let watch = $(this).attr("data-val");
              $('.btnToggleWatch').removeClass("blue--text");
              if(watch=="no")
              {
                 self.current_video.watched = null;
                      $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=unwatch&user_id="+self.user.id+"&token="+self.user.auth_token+"&video_id="+self.current_video.video_id, function() {                                                
                      $(this).addClass("blue--text");
                      }); 
              }else
              {
                  $(this).addClass("blue--text");
                    self.current_video.watched = "yes";
                  $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=watch&user_id="+self.user.id+"&token="+self.user.auth_token+"&video_id="+self.current_video.video_id, function() {                   
                  });
              }

        });
                  
        $(".btnSourceSelect").on("touchend", function(){                        
          let label = $(this).attr("data-val");          
          self.qualities.forEach((item) => {          
              if(item.title==label) 
              {                              
                self.changeSource(item.src);                                              
              }             
          });

       
           $(".btnSourceSelect").removeClass("light-green darken-3");
            $(this).addClass("light-green darken-3");

           $(".mvSettingsMenu").attr('data-state', 1);
            $(".mvSettingsMenu").css('display', '');          
        });

        
        $(".btnSourceSelect").click(function(){   

       $(".btnSourceSelect").removeClass("light-green darken-3");
          $(this).addClass("light-green darken-3");
          let currentSrc = self.player.currentSrc()          
          let label = $(this).attr("data-val");
           let tracks = self.player.videoTracks();
           for (let i = 0; i < tracks.length; i++) {
              let track = tracks[i];
              if(track.label==label) 
              {
                if(track.src!==currentSrc)                
                  self.changeSource(track.src);                                                                              
                  
                break;
              }                                
           }
           $(".mvSettingsMenu").attr('data-state', 1);
            $(".mvSettingsMenu").css('display', '');  
          
        });
        // btnSearchSubs
        $(".btnSearchSubs").click(function(){    
           $(".mvSettingsMenu").attr('data-state', 1);
           $(".mvSettingsMenu").css('display', '');    
          self.search_subs();
        });        
        
        $(".btnSearchSubs").on("touchend", function(){
           $(".mvSettingsMenu").attr('data-state', 1);
            $(".mvSettingsMenu").css('display', '');  
          self.search_subs();
        });

        this.player.on("pause", function () {
  //      alert("Paused");
        });

        this.player.on("play", function () {
          
            if(self.current_video.start_time>10 && !self.resume_dialog)
            {              
              self.resume_dialog = true;
              self.showResumeModal(); 
            }
            
        });
        this.player.on("ended", function () {
          self.player.currentTime(0);
          self.$parent.$parent.video_ended();
        });
        
 
    

      },
      changeSource(newSrc)
      {               
        this.player.pause();
        let currentTime = this.player.currentTime();
        $("#video_player_html5_api").attr("src",newSrc);
        this.player.currentTime(currentTime);
        this.player.play(); 
      },
      addSubtitle(entry)
      {
        let vid = document.getElementById("video_player");    
        let track = document.createElement("track");
        track.kind = "captions";
        track.label = entry.language;
        track.srclang = entry.iso_lang;
        track.src = entry.url;   
        this.subtitles.push({title:entry.language, src: entry.url,srcLang:entry.iso_lang, color: "white"});
        vid.appendChild(track);
      
        
      },
      getSubs: function()
      {        
        let self = this;
        if(this.user.vip != 'true'){                        
        this.$swal("Premium Feature","This feature requires Premium membership, upgrade now to get full access to Mobilevids.","error");
        return;
        }
            if(this.current_video.episode != null)
            {                
                let api_url = self.$root_url + "/webapi/subtitle/search_show.php?vid="+this.current_video.video_id + "&user_id=" + self.user.id+"&token="+self.user.auth_token
                self.subtitle_search = true;
                $.getJSON(api_url, function(data) {                                        
                    self.subs_available = data.subs;
                    self.btnSubtitle = "mdi mdi-24px mdi-comment-text-outline";                
                });
            }
            else
            {                          
                self.subtitle_search = true;
                $.getJSON(self.$root_url + "/webapi/subtitle/search.php?vid="+this.current_video.movie_id + "&user_id=" + self.user.id+"&token="+self.user.auth_token, function(data) {                    
                    self.subs_available = data.subs;
                    self.btnSubtitle = "mdi mdi-24px mdi-comment-text-outline";                
                });
            }         
      }
  },

  components: { 
    "SiteConfig":SiteConfig,
  },
 mounted() {
   let self = this;


   document.onkeydown = function(event) {         
       var active = document.activeElement;        
      if(active.type==undefined || active.type!="text")
      {
            switch (event.keyCode) {       
            case 32:
                  self.showVolume = false;
                  self.spacePlayPause();
                  event.preventDefault();
                break;       
          }
      }
      return event;
      };
      
 
  
  },
  beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
};


</script>


<style>

.play_next_dialog{
   text-align: center;
  background-color: #161616;
  width:400px;  
  border-radius: 15px;
  margin: auto;
  margin-top: 200px;
  font-size: 18pt;
  padding: 10px;
}

.resume_dialog
{
  text-align: center;
  background-color: #161616;
  width:400px;  
  border-radius: 15px;
  margin: auto;
  margin-top: 200px;
  font-size: 18pt;
  padding: 10px;
}
.player_dashboard{
  background-color:rgba(0,0,0,0.5);
   width:100%; 
   height:60px;
   border-bottom-right-radius: 10px;
   border-bottom-left-radius: 10px;
   padding: 10px;
}
.player_topbar{
  background-color:rgba(0,0,0,0.5);
  width:100%; 
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   padding: 5px;
}

#player_control{
  margin-top:-24px;  
   
}
.vjs-matrix.video-js {
  color: white;
  font-size: 12pt;
}

/* Change the border of the big play button. */
.vjs-matrix.video-js .vjs-big-play-button {
  border-color: #5397f0;
}

/* Change the color of various "bars". */
.vjs-matrix.video-js .vjs-volume-level,
.vjs-matrix.video-js .vjs-play-progress,
.vjs-matrix.video-js .vjs-slider-bar {
  background: #00ff00;
}
.vjs-matrix.video-js .vjs-control-bar {
  
  background: linear-gradient(0, rgba(0,0,0,0.6) 20%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,00) 100%);
  bottom: 0px;
}
.vjs-menu-content,.vjs-modal-dialog {
  background: rgba(0,0,0,0.8)!important;
  border-radius: 10px;
}
.vjs-track-setting legend {
color: #bebebe !important;
}
.vjs-track-setting select {
color: white !important;
background: #363636;
padding: 5px;
border-radius: 5px;
cursor: pointer;
}
.vjs-default-button,.vjs-done-button{
  background: #4fa81b!important;
  color: white !important;
  padding: 15px;
  width:150px;
  border-radius: 15px!important;
}
.vjs-default-button{
   background: #701100!important;
}
 .vjs-menu-item-text
  {
    font-size: 12pt;
  }

.dialog_next_episode
{
  position: absolute; top: 0%;left: 0%;
         margin-left:-200px; margin-top:-50px;  width:400px; z-index:999;
}
.mvSettingsMenu{  
  width:350px; 
  background-color: rgba(0,0,0,0.9); 
  position:absolute; 
  top:-280px; 
  left:-286px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 5px;
  height: 280px;
  display: none;
  text-align: left;   
  padding:10px;
}
.sourceMenu{
  display:flex;
}
.subSearchItem{
  width:100%; height:48px; padding:5px;
  cursor: pointer;
  opacity: 0.5;
}
.subSearchItem:hover {
  opacity: 1; 
  background-color: #4fa81b;
}
.subSearchItem img{
  display: inline-block; *display: inline; zoom: 1; vertical-align: top;
  width:32px;
  height: 32px;
}
.subSearchItem p{
  color:white; display: inline-block; *display: inline; zoom: 1; margin-top:5x; margin-left:10px;
}
.sliderVol{
  position: absolute;
  margin-left: 82px;
  margin-top:-38px;
  width:200px;
  border-radius: 5px;      
  background-color: rgba(0,0,0,1);
  height: 36px;
-webkit-box-shadow: -2px 0px 6px 1px rgba(87,56,30,0.44);
-moz-box-shadow: -2px 0px 6px 1px rgba(87,56,30,0.44);
box-shadow: -2px 0px 6px 1px rgba(87,56,30,0.44);
padding-right: 5px;
padding-top:2px;
}
.slidecontainer {
  width: 100%; /* Width of the outside container */
  padding: 10px;
}

.slider {
  
  width: 80%;
  height: 8px;
  border-radius: 4px;  
  background: #747474;
  border-style: solid;
  border-width: 1pt;
  border-color: #000000;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s; 
  margin-left:20%;
}
/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}


.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #00ff15;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 25px;
  border-radius: 25%;
  background: #00ff22;
  cursor: pointer;
}
#sliderOutput{
  display: block;
  width:20%;  
  position: absolute;
}
@media screen  and (max-width: 700px){
  .mvSettingsMenu{  
  position: relative;
   top:0;   
   
   left:-420px;
   margin-top: -160px;
   z-index: 9999;
   height: 220px;
   width:420px;

  }
  .mvSettingsMenu span{
    font-size: 10pt;
  }

  .sourceSubs span
  {
    font-size: 12pt!important;    
    position: relative!important;            
    margin-top: -10px !important;

  }
.sourceMark span
{
  font-size: 12pt!important;    
}

     .vjs-menu-item{
        line-height: .99em!important;
     }
    .vjs-button,.vjs-menu-button
    {
      font-size: 10pt!important;
      width: 2.4em!important;      
    }
    .mdi-skip-backward,.mdi-text-search,.mdi-skip-forward,.mdi-cog
    {
      font-size: 16pt!important;
      position:absolute;
      left: 4px;
      top: 10px;
    }
     .vjs-menu-content {
       margin-left:-70px!important;      
      z-index: 990;
       
    }
    .vjs-modal-dialog
    {
      font-size: 7pt!important;
      height: 100%!important;
    }
    .vjs-default-button,.vjs-done-button{
      font-size: 8pt!important;
      width: 80px;
      height:28px;
      padding: 4px;
      border-radius: 5px!important;
    }
    .vjs-menu-item-text
    {
      font-size: 8pt;
    }
    .subSearchItem{
      opacity: 1; 
      padding:5px;
    }
    .subSearchItem p{
    font-size: 8pt;
    position: absolute;
    width: 78%;
    }
    .subSearchItem img{
    width:14px;
    height: 14px;
    }
    .sliderVol{
       margin-top:-80px;
       height: 36px;
       margin-left:40px;
       border-radius: 5px;      
       background-color: rgba(0,0,0,0.8);
       
       z-index: 9999;
    }
  .dialog_next_episode
  {
      position: absolute; top: 0%;left: 0%;
      margin-top:50px;  width:80%; z-index:999;
  }


}

@media screen  and (min-width: 700px) and (max-width: 1000px){    
  .dialog_next_episode
  {
     position: absolute; top: 0%;left: 0%;
    margin-top:50px;  width:90%; z-index:999;
  }
.subSearchItem p{
    font-size: 10pt;
    }
}




</style>