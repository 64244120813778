<template>
  <v-app>    
    <div id="main-page">
     <v-card width="400" class="mx-auto mt-5" color="rgba(0,0,0,0.7)" v-if="settings.sign_up_allowed==1">
       <v-card-title color="">
         <h1 class="display-1 white--text" >Register</h1>
       </v-card-title>
       <v-card-text >
         <v-form v-model="valid" id="KebabForm">
           <v-text-field label="Username"
              :rules="nameRules"              
              required
              color="primary"
              name="Name"
              dark
              prepend-icon="mdi-account-circle" /> 

            <v-text-field label="E-mail"
              prepend-icon="mdi-email"
              dark
              :rules="emailRules" 
              required
              name="Email"/> 

           <v-text-field label="password" :type="showPassword ? 'text' : 'password'" 
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
              @click:append="showPassword = !showPassword"
               dark
              :rules="passwordRules" 
              required
              name="Password"
              />
            <v-divider></v-divider>
              <v-text-field label="Invitation Code" v-if="settings.require_invite==1"
              prepend-icon="mdi-barcode" 
              name="Invite" 
              dark
              :maxlength="20"
              :counter="20"
              :rules="inviteRules"/> 

         </v-form>
       </v-card-text>
       <v-divider></v-divider>
       <v-card-actions>        
        <v-spacer></v-spacer>
        <v-btn color="info" @click="register()" :disabled="!valid"  dark>Register</v-btn>
       </v-card-actions> 

     </v-card>     
     <v-card style="color:white;" width="400" class="mx-auto mt-5 pa-5" color="rgba(0,0,0,0.7)" v-else>
       <h3 >The site is currently not accepting any new accounts. </h3>
       <p>Please come back at a later time</p>
         <v-btn color="info" to="/login" :disabled="!valid"  dark>Login</v-btn>
     </v-card>
    </div>
    <SiteConfig />
  </v-app>
</template>


<script>
import $ from 'jquery';
import router from '../router.js' 
import SiteConfig from '../components/SiteConfig'
export default {
  name: 'Register',
  components: { 
    "SiteConfig":SiteConfig
  },
  created(){
      let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/backend/get_site_settings.php", function(data) {        
            
        if(data.status>0)
          self.settings = data.settings;                  
    });

  },
  methods: {
      register () {
        let self = this;
        var form = $('#KebabForm').serializeArray();
        var formdata = {};
        $.each(form,
        function(i, v) {
          formdata[v.name] = v.value;
        });
        
        var datastring = JSON.stringify(formdata);
        

        $.post( self.$root_url + "/webapi/user/register.php", { data: datastring})
          .done(function( data ) {
              let user = $.parseJSON(data);
            if(user.status==0)
            {                             
               self.$swal('Register failed', user.reason, "error");                        
            }else
            {
                self.$swal('Register successful', user.reason, "success").then(
                  function () {  router.push({ name: 'home' }); }
                );            

            }
          
        });
      }
    },
    data: () => ({
        settings:[],
        valid: true,
        showPassword: false,
        showRepeat: false,          
          nameRules: [
              (v) => !!v || 'Username is required',
              (v) => (v || '').length >= 6 || 'Name must be at least 6 characters long',
              (v) => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
            ],           
            emailRules: [
              (v) => !!v || 'E-mail is required',
              (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],            
            passwordRules: [
              (v) => !!v || 'Password is required',
              (v) => (v || '').length >= 8 || 'Password must be atleast 8 characters long',
              (v) => !!v || 'Password is required',                  
            ],
            inviteRules: [
              (v) => !!v || 'Invitation is required',
              (v) => (v || '').length == 20 || 'Invite code invalid',
            ]
    })
};
</script>
