<template>
  <v-app dark>   
     <div id="main-page" class="">
     <v-card class="mx-auto login_card" color="rgba(0,0,0,0.7)">
       
       <v-card-title co>
         <h1 class="display-1 mx-auto" style="color:white;">Login</h1>
       </v-card-title>
       <v-card-text>
         <v-form id="LoginForm" v-model="valid">
           <v-text-field label="Name" type="text"
           prepend-icon="mdi-account-circle"
            :rules="nameRules"              
             required
            @keypress.native.13="loginUser()"
             dark
           name="Name" /> 
           
           <v-text-field label="password" :type="showPassword ? 'text' : 'password'" 
           prepend-icon="mdi-lock"
           name="Password"
           :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
           @click:append="showPassword = !showPassword"
           dark
           :rules="passwordRules" 
           @keypress.native.13="loginUser()"
           required/>
         </v-form>
       </v-card-text>
       <v-divider class="mt-10"></v-divider>
       <v-card-actions >
        <v-btn color="green darken-2" to="/register">Register</v-btn>
        <v-spacer></v-spacer>
         <v-btn text class="blue--text" style="font-size:9pt;"  to="/recover">Recover</v-btn>

        <v-btn color="info"
        dark
        @click="loginUser()" 
        :disabled="!valid"
        :loading="data_loading">Login</v-btn>
       </v-card-actions>
     
    
     </v-card>    
     
     </div> 
     <SiteConfig />
  </v-app>
</template>


<script>
import $ from 'jquery';
import router from '../router.js' 
import SiteConfig from '../components/SiteConfig'


export default {
  name: 'Login',
   
  data: () => ({   
      data_loading : false,   
      valid: true,
      showPassword: false,
        nameRules: [
              (v) => !!v || 'Name is required',
              (v) => (v || '').length >= 6 || '',
              (v) => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
            ],                       
            passwordRules: [
              (v) => !!v || 'Password is required',
              (v) => (v || '').length >= 8 || '',
              (v) => !!v || 'Password is required',                  
            ]
  }),
   methods:{
     
    loginUser: function () {    
        let self = this;
        this.data_loading = true;    
        var form = $('#LoginForm').serializeArray();
        var formdata = {};
        $.each(form,
        function(i, v) {
          formdata[v.name] = v.value;
        });
        
        var datastring = JSON.stringify(formdata);
        

        $.post( self.$root_url + "/webapi/user/login.php", { data: datastring})
          .done(function( data ) {            
            self.data_loading = false;    
            let user = $.parseJSON(data);
            if(user.status==0)
            {                             
               self.$swal('Login failed', user.reason, "error");            
            }else
            {              
                localStorage.setItem('user',JSON.stringify(user));              
                 router.push({ name: 'home' });
            }
                         
        });
      }
       
  },
  components: { 
    "SiteConfig":SiteConfig
  },
};
</script>
<style>
.login_card{
  width:480px;
  height:320px;
  top: 30%; 
}

@media screen  and (max-width: 700px){
  .login_card{
  width:80%;
  height:320px;
  top:5%;
}
}
</style>