<template>

      <v-container >  
      <h4  style="position:absolute; top:40px; width:100%">My TVShows          
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h4>            
      
       <div style="height:40px;"></div>
    
    <v-tabs
        class="mt-6"
      background-color="rgba(0,0,0,0.7)"
      color="amber darken-2"
      left           
    >
      <v-tab  @click="changeTab(0)">Shows</v-tab>
      <v-tab @click="changeTab(1)">Unwatched Episodes</v-tab>      
    </v-tabs>
    
    <div v-if="selected_tab==0">
         <v-row class="mt-4" style="text-align:center;">
<div class="custom_card ml-2 mt-2"            
              v-for="show in shows"
            :key="show.id"
            >  
              <v-progress-linear v-if="show.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear>   
              <a @click="play_item(show)" class="custom_card_link">        
                <img :src="show.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                <h5><b>{{show.title}}</b></h5>
                

              <v-card-actions class="custom_card_actions">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;">
            <v-list-item style="background-color:#000000;">
                <v-btn text small color="white"><v-icon>mdi-remote-tv</v-icon>  
                <v-list-item-title style="font-size:10pt;" class="ml-2" @click="action_menu_click('Gotoshow',show)">Go to show</v-list-item-title></v-btn>                            
            </v-list-item>            
            <v-list-item v-if="show.follow_id>0"  style="background-color:#000000;">
                <v-btn text small color="#d4af37"><v-icon>mdi-heart-off</v-icon>  
                <v-list-item-title style="font-size:10pt;" class="ml-2" @click="action_menu_click('Unsub',show)">Unsubscribe</v-list-item-title></v-btn>                            
            </v-list-item>             
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>
              
            </div>

      
         </v-row>
      </div>

      <div v-if="selected_tab==1">
        <VideoList view="unwatched_episodes" />    
        <v-pagination
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12 ml-10 mr-10"
      ></v-pagination>
      </div>



        
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';
import router from '../router.js' 
import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'MyShows',    
    props: {
      source: String
    },
     data: () => ({   
          page: 1,
        total_pages:1,
        switch1: true,
        loading: true,
        selected_tab: "",
        user:[],
        cards: [],
        shows:[]
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{    
    "VideoList" : VideoList,
    "SiteConfig": SiteConfig
  },
  methods:{
    play_item(card)
        {                        
             router.push({ name: 'season', params: {showid: card.id} });    
        },
        action_menu_click (menu,card) {
          let self = this;
         var datastring ="";
         var result = false;
            switch(menu)
            {
                case "Gotoshow": 
                     router.push({ name: 'season', params: {showid: card.id} }); 
                break;

                case "Unsub":                                            
                    datastring = JSON.stringify({follow_id:card.follow_id});
                    result = this._post(self.$root_url + "/webapi/videos/unfollow.php",datastring);
                  if(result.status==0)      
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show Unsubscribed','You will no longer receive new episodes notifications from this show.','success');        
                    const index = this.shows.indexOf(card);
                    if (index > -1) {
                        this.shows.splice(index, 1);
                    }
                  }
                  


                break;
            }
            },
            _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
        changeTab(index)
        {            
            var self = this // create a closure to access component in the callback below
            switch(index)
            {
                case 0:
                    $.getJSON(self.$root_url + "/webapi/videos/user_shows.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                        self.loading = false;
                        self.shows = data.shows;                           
                    });
                     break;
                case 1:     
                    $.getJSON(self.$root_url + "/webapi/videos/user_unwatched_episodes.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                        self.loading = false;
                        self.cards = data.items;   
                        self.total_pages = data.total_pages;
                        
                    });
                break;
                case 2: break;

            }
            this.selected_tab = index;
        },

        changePage(page)
        {
            var self = this;
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatched_episodes.php?p="+page+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
            self.cards = data.items;                
            self.loading = false;
            });
        } 
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
    $.getJSON(self.$root_url + "/webapi/videos/user_shows.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                        self.loading = false;
                        self.shows = data.shows;                           
                    });
    }
  }
</script>

