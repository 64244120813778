<template>

      <v-container >  
         <h2  style="position:absolute; top:40px; width:100%">IMDB Manager
           <v-btn dark  color="blue" text @click="showNew()"><span class="mdi mdi-file-video mdi-24px" style="color:white;" ></span> New</v-btn>
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h2>        
      <v-card
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0"
    >    
      <v-divider></v-divider>

    <v-card-title>      
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="imdb_list"
      :search="search"
       @click:row="rowClick" item-key="id"
    >
    
    </v-data-table>
    <iframe name="post_frame" id="post_frame" style="display:none;"></iframe>
      
      </v-card>            
 <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>        
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selected_imdb.original_title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="red" dark text @click="deleteIMDB()">Delete</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        
        <v-list three-line subheader>
          <v-subheader>IMDB Information</v-subheader>
          <v-list-item>
              <v-list-item-content>              
              <v-text-field label="IMDB ID" hint="Give it a title" v-model="selected_imdb.imdb_id" style="max-width:200px; margin-top:6px;"></v-text-field>              
              <v-text-field label="Original Title" hint="Give it a title" v-model="selected_imdb.original_title" ></v-text-field>              
            </v-list-item-content>            
          </v-list-item>     
            <v-list-item> 
                <v-list-item-content>              
              <v-text-field label="Year" hint="Year" v-model="selected_imdb.year" style="max-width:200px;"></v-text-field>                                       
              <v-text-field label="Release Date" hint="Release date"  v-model="selected_imdb.release_date" style="max-width:200px;"></v-text-field>                                        
              <v-text-field label="Runtime" hint="Runtime in minutes"  v-model="selected_imdb.runtime" style="max-width:200px;"></v-text-field>              
              <v-text-field label="Rating" hint="Rating 1-10"  v-model="selected_imdb.rating" style="max-width:200px;"></v-text-field>              
              <v-combobox v-model="selected_imdb.genres" :items="genres_opt"  name="genres" label="Genres" multiple flat hide-selected clearable
          ></v-combobox>
            </v-list-item-content> 
            </v-list-item>   
        <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Cast" hint="Use the formate RealName:Role,Realname:Role"  v-model="selected_imdb.cast"></v-text-field>
            </v-list-item-content>
        </v-list-item>
        <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Directors" hint="Use the formate RealName:Role,Realname:Role"  v-model="selected_imdb.directors"></v-text-field>
            </v-list-item-content>
        </v-list-item>
           <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Writers" hint="Use the formate RealName:Role,Realname:Role" v-model="selected_imdb.writers"></v-text-field>
            </v-list-item-content>
        </v-list-item>
             <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Producers" hint="Use the formate RealName:Role,Realname:Role" v-model="selected_imdb.producers"></v-text-field>
            </v-list-item-content>
        </v-list-item>
            <v-list-item>
            <v-list-item-content>       
              <v-textarea label="Plot" hint="Plot"  name="plot"> </v-textarea>                     
            </v-list-item-content>
          </v-list-item>                            

        </v-list>
         
      </v-card>
    </v-dialog>
  </v-row>



<v-row justify="center">
    <v-dialog v-model="news_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <form :action="$root_url + '/webapi/backend/new_imdb.php'" method="post" enctype="multipart/form-data" id="upload_form" target="post_frame">
        <v-toolbar dark>
          <v-btn icon dark @click="news_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>New IMDB</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="PostIMDB()">Done</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        
        <v-list three-line subheader>
          <v-subheader>IMDB Information</v-subheader>
          <v-list-item>
              <v-list-item-content>              
             <h3>Poster</h3>                    
              <input type="file" id="poster" name="poster" class="custom-file-input mt-6">                      

             <h3 class="mt-4">Media Images</h3>
             <v-btn @click="addMediaImg()">Add Media Image</v-btn>
             <div id="media_images">
                 
             </div>
                           </v-list-item-content>
          </v-list-item>
          <v-list-item>
           
              <v-list-item-content>              
              <v-text-field label="IMDB ID" hint="Give it a title" name="imdb_id" style="max-width:200px; margin-top:6px;"></v-text-field>              
              <v-text-field label="Original Title" hint="Give it a title" name="title" ></v-text-field>              
            </v-list-item-content>            
          </v-list-item>     
            <v-list-item> 
                <v-list-item-content>              
              <v-text-field label="Year" hint="Year" name="year" style="max-width:200px;"></v-text-field>                                       
              <v-text-field label="Release Date" hint="Release date"  name="release_date" style="max-width:200px;"></v-text-field>                                        
              <v-text-field label="Runtime" hint="Runtime in minutes"  name="runtime" style="max-width:200px;"></v-text-field>              
              <v-text-field label="Rating" hint="Rating 1-10"  name="rating" style="max-width:200px;"></v-text-field>              
              <v-combobox v-model="new_post.genres" :items="genres_opt"  name="genres" label="Genres" multiple flat hide-selected clearable
          ></v-combobox>
            </v-list-item-content> 
            </v-list-item>   
        <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Cast" hint="Use the formate RealName:Role,Realname:Role"  name="cast"></v-text-field>
            </v-list-item-content>
        </v-list-item>
        <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Directors" hint="Use the formate RealName:Role,Realname:Role"  name="directors"></v-text-field>
            </v-list-item-content>
        </v-list-item>
           <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Writers" hint="Use the formate RealName:Role,Realname:Role"  name="writers"></v-text-field>
            </v-list-item-content>
        </v-list-item>
             <v-list-item> 
            <v-list-item-content>    
              <v-text-field label="Producers" hint="Use the formate RealName:Role,Realname:Role" name="producers"></v-text-field>
            </v-list-item-content>
        </v-list-item>
            <v-list-item>
            <v-list-item-content>       
              <v-textarea label="Plot" hint="Plot"  name="plot"> </v-textarea>                     
            </v-list-item-content>
          </v-list-item>                            

        </v-list>
          </form>
      </v-card>
    </v-dialog>
  </v-row>



<SiteConfig/>
      </v-container>
</template>
<style>
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'SELECT';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 10px;
  width: 100px;
  text-align: center;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
    margin-top: -2px;
  font-size: 10pt;
}
</style>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'Home',    
    props: {
      source: String,
    },
     data: () => ({   
        page            : 1,
        user            :[],
        loading         : true,        
        selected_imdb   :[],
        new_post        :[],
        imdb_list           :[],
        news_dialog :false,
        dialog              :false,
        search: '',
        genres_opt:[          
          'Animation',
          'Action',
          'Adventure',
          'Comedy',
          'Crime',
          'Drama',
          'Fantasy',
          'Historical',
          'Historical fiction',
          'Horror',
          'Magical realism',
          'Mystery',
          'Paranoid fiction',
          'Philosophical',
          'Political',
          'Romance',
          'Saga',
          'Satire',
          'Science fiction',                    
          'Social',  
          'Speculative',  
          'Thriller',
          'Urban',
          'Western',
          'Urban',
          'Urban'],
        headers: [
          { text: 'IMDB ID', value: 'imdb_id' },           
          { text: 'Title', value: 'original_title' },
          { text: 'Year', value: 'year' },
          { text: 'Rating', value: 'rating' },
          { text: 'Runtime', value: 'runtime' },                     
           ]

        }),
  components:{
"SiteConfig":SiteConfig,
  },
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  methods:{
      addMediaImg()
      {
          $("#media_images").append('<input type="file" id="poster" name="media_images[]" class="custom-file-input mt-6">')
      },
      showNew()
      {            
            this.news_dialog = true;
             this.new_post = {title:"",message:"",end_date:""};
      },
    PostIMDB()
    {
    this.news_dialog = false;            
    $( "#upload_form" ).submit();

    },
    
      deleteIMDB()
      {       
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently remove the IMDB post',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_imdb);      
              $.post( self.$root_url + "/webapi/backend/delete_imdb.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {                                  
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The post was successfully deleted',
                      'success'
                    );
                    
                    let index = self.imdb_list.indexOf(self.selected_imdb);
                    if (index > -1) {
                      self.imdb_list.splice(index, 1);
                      }
                    }          
                });  
            }
          })

      },
      

      rowClick: function (item, row) {      
      row.select(true);
      this.selected_imdb = item;      
      this.dialog = true;
    }   
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
    
     $.getJSON(self.$root_url + "/webapi/backend/get_imdb_list.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {        
        self.imdb_list = data.items;
        self.loading = false;
        
    });
        $('#post_frame').bind('load', function(){       
       let html = document.getElementById('post_frame').contentWindow.document.body.innerHTML;

        let json = JSON.parse(html);
        if(json.status==0)
        {          
         self.$swal('IMDB upload', html.reason, "error");     
        }
        else
        {
          
         self.$swal("IMDB upload", html.reason, "success");    
        }
    });


    }
  }
</script>

