<template >
       <div class="mt-4">

 <v-navigation-drawer
        v-model="drawer"      
      tempoary
        absolute=""
        style="background-color:rgba(0,0,0,0.8);"
        height="330"
        temporary=""
        v-if="drawer"
      >
           
        <v-divider></v-divider>
  
        <v-list dense>
          <v-subheader style="background-color:rgba(0,0,0,1); margin-top:-10px;">Channels</v-subheader>
          <v-list-item
            v-for="(item, i) in channels"
            :key="i"            
            @click="updateSelected(item)">
            <v-list-item-icon >
              <v-icon v-text="item.icon" :color="item.VIP ? '#d4af37': 'white'" elevation="0"></v-icon>
            </v-list-item-icon>
            <v-list-item-content  :color="item.VIP ? '#d4af37': 'white'">
            <v-btn absolute class="ml-6" :color="item.VIP ? '#d4af37': 'white'" text left small tile 
            depressed>{{item.name}}</v-btn>
            
            </v-list-item-content>            
          </v-list-item>      

        </v-list>
      </v-navigation-drawer>

<div class="chat_container">
<v-btn color="rgba(0,0,0,0.5)"  @click="drawer=!drawer"><v-icon >mdi-view-list</v-icon> Channels</v-btn>


    
      <v-list color="rgba(0,0,0,0.5)"  style="height: 100%;margin-top:10px;">
        <v-subheader style="background-color:rgba(0,0,0,1); margin-top:-10px;">{{current_channel.name}}</v-subheader>
    <div style="background-color:rgba(0,0,0,0.3);">
      <v-list-item readonly>
       <v-list-item-icon  style="margin-top:10px;">
               <v-avatar  size="avatarSize">
                 <img style="width:42px; height:42px;" class="imgUser" :src="root_url +'/webapi/user/photo.php?user_id=0'" alt="alt">
               </v-avatar>  
            </v-list-item-icon>
            <v-list-item-content style="margin-left:-27px; ">
               <div style="margin-top:-0px;">
              <p><span style="color:rgb(212, 175, 55); font-weight:bold;">MV Admin:</span> These channels are for socialising and helping each other out. For requests go to top right menu and click on Request Movie/TVShow(VIPs Only).</p>
              <p>Please don't spam and be kind to eachother.</p>
               </div>
            </v-list-item-content>
      </v-list-item>
    </div>                           

       <div id="messages_area" style=" height: 75%; overflow-y: auto; " @click="getUser=false">

          <v-list-item
            v-for="(post, i) in posts"
            :key="i"
            readonly            
            >       
            <a style="margin-left:0px; text-decoration:none; padding-right:10px;" :href="'/profile/'+post.user_id" color="primary">     
            <v-list-item-icon  style="margin-top:10px;">
               <v-avatar  size="avatarSize">
                 <img style="width:42px; height:42px;" class="imgUser" :src="root_url +'/webapi/user/photo.php?user_id='+post.user_id" alt="alt">
               </v-avatar>  
            </v-list-item-icon>
            </a>
            <v-list-item-content style="margin-left:-32px; ">
                
              <div style="margin-top:-10px;"> 
                  <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
       <v-icon v-if="post.is_vip=='true'" v-bind="attrs"  v-on="on" style="color:rgb(212, 175, 55); margin-top:-6px;">mdi-crown-outline</v-icon>
      </template>
      <span>{{post.name}} is a Premium member</span>
    </v-tooltip>
                
                <a style="margin-left:0px; text-decoration:none; padding-right:10px; " :href="'/profile/'+post.user_id" color="primary"><span>{{post.name}}</span> </a><span style="font-size:9pt; color: grey; margin-left:-5px;"> - {{timeSince(post.post_date)}} ago</span></div>
              <div v-html="post.message"></div>
              <div><a @click="reply(post.name)" style="font-size:9pt; color:rgb(36, 59, 117);">Reply</a></div>              
             
            </v-list-item-content>          
             <v-icon v-if="user.users_permission == 1" class="red float-right" @click="deleteMessage(post)">mdi-delete</v-icon>
          </v-list-item>
          <v-list-item v-if="postsLength==0"><p class="mx-auto mt-12">This channel is empty, be the first one and say hello.</p></v-list-item>
       </div>
        </v-list>
        <div class="social_div">
           
  

          <v-list  dense v-if="getUser" style="position:absolute; bottom:0px; width:300px; border-radius:25px;" >          
          <v-list-item readonly @click="getUser=false">            
            <v-list-item-content>
             <v-text-field
        id="user_search_field"
        v-model="user_search"
        autofocus
        append-icon="mdi-magnify"
        label="Search user to mention..."
        single-line
        hide-details
      ></v-text-field> 
            <v-data-table
            :headers="user_headers"
            :items="user_list"
            :search="user_search"
            hide-default-header
             @click:row="user_click" item-key="id"
            
             dense
             flat
          ></v-data-table>
            </v-list-item-content>            
          </v-list-item>      
        </v-list>

           <v-text-field label="Message..." type="text"
           color="primary"
           append-icon="mdi-card-text-outline"  
           prepend-icon="mdi-emoticon-outline"         
           v-model="user_message"           
           @click="getUser=false"
           @click:prepend="show_icons=!show_icons"
           @keypress.native.13="sendMessage()"        
           @keypress.native.@="getUserName(event)"                            
           name="Message"
           ref="messageBox" /> 
            
        </div>

</div>

<v-dialog v-model="show_icons"  transition="dialog-bottom-transition" width="328" >      
      <v-card class="mx-auto">      
          <VEmojiPicker @select="selectEmoji" />
      </v-card>
    </v-dialog>

<SiteConfig />
      </div>
 

                                           
          
      
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'
import { VEmojiPicker } from 'v-emoji-picker';


  export default {
    name: 'Social',    
    props: {
      source: String
    },
     data: () => ({
         item: 0,   
         getUser:false,
         current_channel: 0,
         root_url: '',
         postsLength:0,
         user: [],
         user_list:[],
         show_icons:false,
         user_search:"",
        user_headers: [
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'name',            
          }],
         user_message: "",
         intervallId:0,
         drawer: false,
         channels: [

        ],
        posts: []          
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
     
  },
  components:{
"SiteConfig":SiteConfig,
 "VEmojiPicker": VEmojiPicker
  },
  methods:{
    selectEmoji(emoji) {
      
      this.user_message += emoji.data;
      this.show_icons = !this.show_icons;
    },
    reply(username)
    {
      this.user_message = "@" + username + " ";
      
       this.$refs["messageBox"].focus();
    },
    deleteMessage(post)
    {      
        var datastring = JSON.stringify(post);      
        var self = this;
        $.post( self.$root_url+ "/webapi/social/del_message.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
          .done(function( data ) {                    
              let result = $.parseJSON(data);
            if(result.status==0)
            {                             
               self.$swal('Failed', result.reason, "error");                        
            }else
            {
              this.dialog = false;
               self.$swal.fire(
                'Deleted',
                'The Message was deleted',
                'success'
              )         

            }          
              });   


    },
    timeSince(date) {
          var tempDate = new Date(date);
          var seconds = Math.floor((new Date() - tempDate) / 1000);
          var interval = seconds / 31536000;
          if (interval > 1) {
            return Math.floor(interval) + " years";
          }
          interval = seconds / 2592000;
          if (interval > 1) {
            return Math.floor(interval) + " months";
          }
          interval = seconds / 86400;
          if (interval > 1) {
            return Math.floor(interval) + " days";
          }
          interval = seconds / 3600;
          if (interval > 1) {
            return Math.floor(interval) + " hours";
          }
          interval = seconds / 60;
          if (interval > 1) {
            return Math.floor(interval) + " minutes";
          }
          return Math.floor(seconds) + " seconds";
      },
        getTimeZone()
        {      
            return encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
        },
        user_click: function (item, row) {                 
            row.select(true);                       
               this.getUser = false;
               this.user_message += item.name
          }  ,
         updateSelected (selectedItem) {    
           this.drawer = !this.drawer;       
                var self = this; // create a closure to access component in the callback below
              if(selectedItem.VIP)
              {
                if(self.user.vip!="true")
                {
                  self.$swal("VIP Function","You need to be a VIP member to access this area","error");
                  return;
                }
              }
              
                this.current_channel = selectedItem;                       
                  $.getJSON(self.$root_url + "/webapi/social/get_messages.php?id=" + selectedItem.id+"&user_id="+self.user.id +"&token="+self.user.auth_token+"&timezone="+self.getTimeZone(), function(data) {    
                    
                    if(data.status>0)
                    {
                    self.posts = data.posts;          
                    self.posts.reverse();    
                    self.postsLength = data.posts.length;
                      $('#messages_area').scrollTop($('#messages_area')[0].scrollHeight); 
                    }
                    else
                    {
                    self.posts = [];
                    self.postsLength = 0;
                    }
                });  
     },
     updateScroll(){
                  let self = this; // create a closure to access component in the callback below
                  let last = self.posts[self.posts.length-1];
                  if(self.current_channel!==null && last != null)
                  {
                    let msgArea = $('#messages_area');
                    if(msgArea == undefined)
                     clearInterval(self.intervallId);

                  
                    $.getJSON(self.$root_url + "/webapi/social/get_new_messages.php?id=" + self.current_channel.id+"&latest_id="+last.id+"&user_id="+self.user.id +"&token="+self.user.auth_token+"&timezone="+self.getTimeZone(), function(data) {                                      
                    if(data.status>0)
                    {                      
                      self.posts = self.posts.concat(data.posts);                         
                       setTimeout(function(){$('#messages_area').scrollTop($('#messages_area')[0].scrollHeight);},300);
                    }
                     
                  
                }); 
              }
           
     },
     getUserName(){
       let self = this;
       self.user_list = [];

       var i;
        for (i = self.posts.length-1; i > -1; i--) {
          let User = self.posts[i];
          let post_user = {id: User.user_id, name: User.name};
         var exists = false;
          var x;
              for (x = 0; x < self.user_list.length; x++) {
                if(User.user_id===self.user_list[x].id)
                {
                  exists = true; 
                  break;
                }                         
            }
            if (!exists)    self.user_list.push(post_user);              
        }
      
       this.getUser = true;
         
     },
        sendMessage()
          {
          console.log(encodeURIComponent(this.user_message));
          let channel_id = this.current_channel.id;                
          var self = this; // create a closure to access component in the callback below            
             $.getJSON(self.$root_url + "/webapi/social/post_message.php?id="+channel_id+"&user_id=" + self.user.id+"&token="+self.user.auth_token+"&msg="+ encodeURIComponent(this.user_message)+"&timezone="+self.getTimeZone() , function() {                                                      
                self.user_message = "";
                 $.getJSON(self.$root_url + "/webapi/social/get_messages.php?id=" + channel_id+"&user_id="+self.user.id +"&token="+self.user.auth_token+"&timezone="+self.getTimeZone(), function(data) {    
                    
                    if(data.status>0)
                    { 
                      self.posts = data.posts;              
                      self.posts.reverse();
                      self.postsLength = data.posts.length;                    
                    }
                    else
                    {
                      self.posts = [];
                      self.postsLength = 0;
                    }
                     setTimeout(function(){$('#messages_area').scrollTop($('#messages_area')[0].scrollHeight);},200);
                });  


                });  
          }
  },
  beforeRouteLeave (to, from, next) {                 
        clearInterval(this.intervallId);
        next();
      },
  mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this; // create a closure to access component in the callback below
    this.root_url = this.$root_url;
    $(".v-input__icon--append .v-icon").css("color","yellow");
     $.getJSON(self.$root_url + "/webapi/social/get_channels.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&timezone="+self.getTimeZone(), function(data) {
      self.current_channel = data.channels[0];
      self.channels = data.channels;      
      self.posts = data.posts;
      self.posts.reverse();
      self.postsLength = data.posts.length;        
    });

    setTimeout(function(){$('#messages_area').scrollTop($('#messages_area')[0].scrollHeight);},1000);
    
    this.intervallId = setInterval(this.updateScroll,5000);    
    }
  }
</script>

<style >
.v-dialog {
    position: absolute;
    bottom: 100px;
    left:250px;
    right: 0;
}
#messages_area::-webkit-scrollbar {
    width: 12px;
} 

#messages_area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.7); 
    border-radius: 10px;
}
#messages_area::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: burlywood;
}
</style>