<template>
    <v-row class="mt-4" style="text-align:center;">

      <div class="reward_card ml-2 mt-2"            
            v-for="card in this.$parent.cards"
            :key="card.id"   
            >  
              <a @click="get_survey(card)" class="custom_card_link">        
                <img :src="card.creative_url"  alt="Avatar" style="" class="reward_card_img">
               <p style="position:absolute; margin-top:-40px; margin-left: 0px; color:white; background-color:rgba(0,0,0,0.6); width:120px; height:34px; padding-bottom:10px;  border-bottom-left-radius: 25px; border-bottom-right-radius: 25px; text-align:left; padding-left:15px;"><v-icon class="" style="font-size:24pt; color:#d4af37; margin-top:0px;">mdi-currency-usd-circle</v-icon> +{{card.payout}} </p> 
              </a> 
              <div class="reward_card_container">
                <h5><b>{{card.name}}</b></h5>    
                
              </div>
              
            </div>

           
          
        </v-row>
</template>
<style  scoped>
.reward_card {
    /* Add shadows to create the "card" effect */
    width:120px;
    margin-left:15px;
  }
   .reward_card_container h5{
    text-align: center;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;    
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);        
  }
    .reward_card_img{
  width:100%;
  height:140px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.4);
  transition: 0.1s;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */  
  border-radius: 25px;
  }

    .custom_card_link { 
   background-color: rgba(0,0,0,0);
   
    transition: all 1s linear;         
  }
  .reward_card:hover .reward_card_img{

    box-shadow: inset 0 0 0 1px #55361b, inset 0 0 0 2px #55361b;    
    
  }



@media screen and (max-width: 600px) {

}
@media only screen and (min-width:700px) and (max-width:920px) {

}


</style>

<script>
import $ from 'jquery';

  export default {
    name: 'VideoCards',
    props: {view: String},
     data: () => ({
       posterNA: '',
     action_menu: [],
     user:[]
  }),
  created () {
        this.user = $.parseJSON(localStorage.getItem('user'));        
  },
  mounted()
  {

      
  },
  methods:{
        onError (card) {
        var i;   
            for (i = 0; i < this.$parent.cards.length; i++) {
                if(this.$parent.cards[i].id==card.id)
                {                           
                    this.$parent.cards[i].poster_thumbnail = this.$root_url + "/img/poster_NA.png";
                }
            }                               
        },
        
        get_survey(card)
        {                        
           window.location = card.click_url;        
        },   
        _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        }
        

  },
  
  }
</script>

