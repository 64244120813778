<template>

      <div id="divHome" class="mt-6">  
              <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
         <h4  style="padding-bottom:10px;"> Welcome home 
           <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
       <v-icon v-if="user.vip=='true'" v-bind="attrs"  v-on="on" style="color:rgb(212, 175, 55); margin-top:-6px;">mdi-crown-outline</v-icon>
      </template>
      <span>You are a Premium member</span>
    </v-tooltip>
    <span>{{user.name}}.</span>
   
    
      </h4>            
          
      
        <v-switch color="#d4af37" v-model="switch1" inset :label="` ${switch1 ? 'Cards' : 'List'}`" 
    class="float-right"     style="margin-top:-20px;"></v-switch>        
        <div style="margin-top:-50px;"></div>
       
       
        <OnDeckList v-if="!switch1" />
        <OnDeck v-if="switch1" />
        
        <RecentList v-if="!switch1" />
        <RecentCards v-if="switch1" />
        <SiteConfig />
        <AdComponent view="divHome" />
            
      </div>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'
import OnDeck from '../components/On_Deck'
import OnDeckList from '../components/On_Deck_List'
import RecentCards from '../components/Recent_Cards'
import RecentList from '../components/Recent_List'
import AdComponent from '../components/AdComp'


  export default {
    name: 'Home',    
    
    props: {
      source: String
    },
     data: () => ({   
        page            : 1,
        switch1         : true,
        loading         : true,
        recent_released : [],
        recent_movies   : [],
        recent_episodes : [],
        recent_uploads  : [],
        recent_sports   : [],
        on_deck         : [],
        user:           []

        }),
  components:{
    "RecentCards" : RecentCards,
    "RecentList"  : RecentList,
    "OnDeck"      : OnDeck,
    "OnDeckList"  : OnDeckList,
    "SiteConfig"  : SiteConfig,
    "AdComponent" : AdComponent
  },
  created(){
    this.user =  $.parseJSON(localStorage.getItem('user'));
    
    if(this.user.view_style!=undefined && this.user.view_style>0)
        this.switch1 = true;
    else
        this.switch1 = false;



  },
  methods:{
        
  },
  beforeRouteLeave (to, from, next) { 
      this.recent_movies     = [];
      this.recent_episodes   = [];
      this.recent_sports     = [];
      this.on_deck           = [];
      this.user              = [];
      this.recent_uploads    = [];
      this.recent_released   = [];

      delete this.recent_movies;
      delete this.recent_episodes;
      delete this.recent_sports;
      delete this.on_deck;
      delete this.user;
      delete this.recent_uploads;
      delete this.recent_released;
      next();
     },

    mounted() { // when the Vue app is booted up, this is run automatically.
    let self = this // create a closure to access component in the callback below
      
     $.getJSON(self.$root_url + "/webapi/videos/on_deck.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
       
      self.on_deck = data;
    });

     $.getJSON(self.$root_url + "/webapi/videos/latest.php?user_id=" + self.user.id +"&token="+self.user.auth_token, function(data) {
      self.recent_movies    = data.recent_movies;
      self.recent_uploads   = data.recent_uploads;
      self.recent_episodes  = data.recent_episodes;
      self.recent_released  = data.recently_released_movies; 
      self.recent_sports    = data.recent_sports;
      
      Object.freeze(self.recent_movies);
      Object.freeze(self.recent_uploads);
      Object.freeze(self.recent_episodes);
      Object.freeze(self.recently_released_movies);
      Object.freeze(self.recent_sports);

    
      self.loading = false;
    });

    }
  }
</script>

