<template >

      <v-container class="mt-5">  
      <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
    <div id="app" style="background-color:rgba(0,0,0,0.0);" class="mt-2"> 
  <v-app id="inspire" style="background-color:rgba(0,0,0,0.0);" >
    <v-row class="fill-height"  >
      <v-col style="background-color:rgba(0,0,0,0.0);" >
        <v-sheet height="64" style="margin-top:-12px;">
          <v-toolbar style="background-color:rgba(0,0,0,0.4);">
            <v-btn outlined class="mr-4" color="white darken-2" @click="setToday">
              Today
            </v-btn>            
            <v-btn fab text small color="white darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="white darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <h4>TVShows Calendar</h4>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="white darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet>
          <v-calendar
           style="background-color:black;"
            event-overlap-mode="stack"
            ref="calendar"
            v-model="focus"
            color="orange darken-1"
            :event-color="getEventColor"
            event-text-color="black"
            :events="events"            
            :type="type"
            
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"  
            @mouseenter:time="mouseEnter"          
          ></v-calendar>
         
        </v-sheet>
      </v-col>
    </v-row>
  </v-app>
</div>

    <v-row justify="center">
    <v-dialog v-model="event_dialog" width="450" hide-overlay transition="dialog-bottom-transition">      
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="event_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selected_event.name}}</v-toolbar-title>          
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Event details</v-subheader>
          <v-list-item>
            <v-list-item-content>    
                <p style="font-size: 11pt; margin-top: -14px;">
                    <span style="font-size:16pt;">{{selected_event.name}}</span>
                     <br><br>
                     <span class="orange--text" style="font-size: 10pt;">Description: </span><span class="grey--text">{{selected_event.description}}</span>
                     <br>
                     <span class="orange--text" style="font-size: 10pt;">Starts: </span><span class="grey--text">{{selected_event.start}}</span>
                     <br>
                     <span class="orange--text" style="font-size: 10pt;">Ends: </span><span class="grey--text">{{selected_event.end}}</span>
                     <br>
                     <span class="orange--text" style="font-size: 10pt;">Status: </span><span class="grey--text">{{selected_event.status}}</span>
                     </p>

              
            </v-list-item-content>
          </v-list-item>                           
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
<SiteConfig/>
      </v-container>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'

  export default {
    name: 'TVShows',    
    props: {
      source: String
    },
     data: () => ({   
        loading: true,
        focus: '',
        type:'week',
        event_dialog: false,
        today: '',
        selected_event:[],
        events: [
        ]       
        }),
  created(){
    this.user =  $.parseJSON(localStorage.getItem('user'));
  },
  components:{
    "SiteConfig": SiteConfig
  },
  methods:{  
      
      typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days'
    },
    mouseEnter()
    {
       
    },
      viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
     getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },   
      showEvent ({ nativeEvent, event }) {
          
          this.selected_event = event;
          this.event_dialog = true;
          return nativeEvent;
          
    }         
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
          
             var self = this;
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/getcalendar.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                
                self.events = data;
                self.loading = false;
                self.setToday();
            });
            this.$refs.calendar.checkChange()
         
            
    }
  }
</script>
<style scoped>
.my-event {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
border-radius: 2px;
background-color: #1867c0;
color: #ffffff;
border: 1px solid #1867c0;
font-size: 12px;
padding: 3px;
cursor: pointer;
margin-bottom: 1px;
left: 4px;
margin-right: 8px;
position: relative;
}

.my-event.with-time {
position: absolute;
right: 4px;
margin-right: 0px;
}
</style>
