<template >

      <div >  
      <h4  style="position:absolute; top:10px; width:100%">Video Manager
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h4>            
      
       <div style="height:30px;"></div>
    
    <v-tabs
        class="mt-6"
      background-color="rgba(0,0,0,0.7)"
      color="amber darken-2"
      left           
    >
      <v-tab  @click="changeTab(0)">Movies</v-tab>
      <v-tab @click="changeTab(1)">TVShows</v-tab>    
      <v-tab @click="changeTab(2)" v-if="show_seasons.length>0">Episodes</v-tab>   
      <div  style="width:400px!important; right:10px; top:-5px; position:absolute;">
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details    
      ></v-text-field>
      </div>
    </v-tabs>
    
    <div v-if="selected_tab==0">

         <v-data-table
    :headers="headers_Movies"
    :items="movies"    
    :sort-desc="[false, true]"
    multi-sort
    :search="search"
    @click:row="rowClickMovies" item-key="id"
    class="elevation-1"
  ></v-data-table>
        
      </div>

    <div v-if="selected_tab==1">
         <v-data-table
    :headers="headers_TVShows"
    :items="tvshows"        
    :sort-desc="[false, true]"
    :search="search"
    multi-sort
    @click:row="rowClickEpisodes" item-key="id"
    class="elevation-1"
  ></v-data-table>
        
      </div>
    <div v-if="selected_tab==2">
    
    <v-data-table
    :headers="headers_Season"
    :items="show_seasons"        
    :sort-desc="[false, true]"
    :search="search"
    v-model="datatable_selected"
    multi-sort
    multiple
    show-select
    @click:row="rowSelectEpisode" item-key="id"
    class="elevation-1"
  ></v-data-table>
          <v-btn @click="delete_selected()" class="mt-2 float-right blue">Delete Selected</v-btn>
      </div>



<v-row class="text-center">
    <v-dialog v-model="episodeEditDialog"  hide-overlay transition="dialog-bottom-transition" width="1200">      
      <v-card class="mt-12">
        <v-toolbar dark>
          <v-btn icon dark @click="episodeEditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selected_show.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
             <v-btn dark  color="green" text @click="getEpisodes(selected_show)">View Episodes</v-btn>      
            <v-btn dark  color="red" @click="DeleteShow()" text>Delete TVShow</v-btn>                        
            <v-btn dark  color="green" text >Update TVShow</v-btn>   
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Imdb data</v-subheader>          
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Title" v-model="selected_show.title" hint="Show name"></v-text-field>                            
            </v-list-item-content>
          </v-list-item>        
        
        <v-list-item>
            <v-list-item-content>              
              <v-textarea  label="Plot" v-model="selected_show.plot" hint="Plot of the movie"></v-textarea>              
            </v-list-item-content>
          </v-list-item>
            <v-list-item>
            <v-list-item-content>              
              <v-text-field  label="Year" v-model="selected_show.year" hint="Year"></v-text-field>              
              <v-text-field  label="Runtime (minutes)" v-model="selected_show.runtime" hint="Movie runtime"></v-text-field>              
              <v-text-field  label="Rating" v-model="selected_show.rating" hint="IMDB Rating"></v-text-field>              
            </v-list-item-content>
          </v-list-item>
          
        <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Genres: Example: ["Genre1","Genre2"]' v-model="selected_show.genres" hint="Movie genres"></v-text-field>              
            </v-list-item-content>
        </v-list-item>

         <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Cast: Example: ["Actor1":"Role(s)","Actor2": "Role(s)"]' v-model="selected_show.cast" hint="Cast list"></v-text-field>              
            </v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Directors: Example: ["Director1_ID":"Director1","Director2_ID":"Director2"]' v-model="selected_show.directors" hint="Movie directors"></v-text-field>              
            </v-list-item-content>
        </v-list-item>
         <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Writers: Example: ["Writer1_ID":"Writer1","Writer2_ID":"Writer2"]' v-model="selected_show.writers" hint="Movie writers"></v-text-field>              
            </v-list-item-content>
        </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
</v-row>

<v-row justify="center">
    <v-dialog v-model="movieEditDialog" hide-overlay transition="dialog-bottom-transition" width="1200">      
       <v-card class="mt-12">
        <v-toolbar dark>
          <v-btn icon dark @click="movieEditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selected_movie.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark  color="red" @click="DeleteMovie()">Delete Movie</v-btn>                        
            <v-btn dark  color="green" @click="UpdateMeta()" text >Update META</v-btn>   
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Imdb data</v-subheader>          
          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="IMDB/TMDB ID" v-model="selected_movie.imdb_id" hint="IMDB/TMDB ID"></v-text-field>                            
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>              
              <v-text-field label="Title" v-model="selected_movie.title" hint="Movie name"></v-text-field>                            
            </v-list-item-content>
          </v-list-item>
        <v-list-item>
            <v-list-item-content>              
              <v-textarea  label="Plot" v-model="selected_movie.plot" hint="Plot of the movie"></v-textarea>              
            </v-list-item-content>
          </v-list-item>
            <v-list-item>
            <v-list-item-content>              
              <v-text-field  label="Year" v-model="selected_movie.year" hint="Year"></v-text-field>              
              <v-text-field  label="Runtime (minutes)" v-model="selected_movie.runtime" hint="Movie runtime"></v-text-field>              
              <v-text-field  label="Rating" v-model="selected_movie.rating" hint="IMDB Rating"></v-text-field>              
            </v-list-item-content>
          </v-list-item>
          
        <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Genres: Example: ["Genre1","Genre2"]' v-model="selected_movie.genres" hint="Movie genres"></v-text-field>              
            </v-list-item-content>
        </v-list-item>

         <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Cast: Example: ["Actor1":"Role(s)","Actor2": "Role(s)"]' v-model="selected_movie.cast" hint="Cast list"></v-text-field>              
            </v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Directors: Example: ["Director1_ID":"Director1","Director2_ID":"Director2"]' v-model="selected_movie.directors" hint="Movie directors"></v-text-field>              
            </v-list-item-content>
        </v-list-item>
         <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Writers: Example: ["Writer1_ID":"Writer1","Writer2_ID":"Writer2"]' v-model="selected_movie.writers" hint="Movie writers"></v-text-field>              
            </v-list-item-content>
        </v-list-item>
        <v-subheader>Available Qualities</v-subheader>   

        <v-list-item>
            <v-list-item-content>                            
                
              <v-text-field  @click:prepend="deleteVideo(selected_movie,'1080P')"  prepend-icon="mdi mdi-delete" readonly="true" label='HD_1080P' v-model="selected_movie.HD_VIP_1080" hint="1080P Video URL"></v-text-field>              
              <v-text-field  @click:prepend="deleteVideo(selected_movie,'720P')" prepend-icon="mdi mdi-delete" readonly="true" label='HD_720P' v-model="selected_movie.HD_VIP" hint="720P Video URL"></v-text-field>              
              <v-text-field  @click:prepend="deleteVideo(selected_movie,'480P')" prepend-icon="mdi mdi-delete" readonly="true" label='SD' v-model="selected_movie.SD_VIP" hint="480P Video URL"></v-text-field>              
            </v-list-item-content>
        </v-list-item>

             <v-list-item>
            <v-list-item-content>              
              <v-text-field  label='Writers: Example: ["Writer1_ID":"Writer1","Writer2_ID":"Writer2"]' v-model="selected_movie.writers" hint="Movie writers"></v-text-field>              
            </v-list-item-content>
        </v-list-item>

        </v-list>          
      </v-card>
    </v-dialog>
  </v-row>

        
      <SiteConfig />
      </div>
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'VideoManager',    
    props: {
      source: String
    },
     data: () => ({   
         search:'',
         movieEditDialog:false,
         episodeEditDialog:false,
         selected_movie:"",
         selected_show:"",
         selected_show_seasons:[],
         datatable_selected:[],
          page: 1,
        total_pages:1,
        timer: null,
        switch1: true,
        loading: true,
        selected_tab: "",
        user:[],
        cards: [],
         headers_Movies: [        
          { text: 'Name', value: 'title' },
          { text: 'HD 1080P', value: 'HD_VIP_1080' },        
          { text: 'HD', value: 'HD_VIP' },        
          { text: 'SD', value: 'SD_VIP' },   
          { text: 'Plays', value: 'play_count' },    
          { text: 'Added', value: 'add_date' }           
          ],
          headers_TVShows: [        
          { text: 'Name', value: 'title' }      
          ],
          headers_Season: [        
          { text: 'Season', value: 'season' },      
          { text: 'Episode', value: 'episode' },      
          { text: 'PlayCount', value: 'play_count' }   
          ],

        movies:[],
        tvshows:[],
       show_seasons:[]
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{    
  "SiteConfig":SiteConfig
  },
   beforeRouteLeave (to, from, next) { 
       clearInterval(this.timer);
          next();
   },
  methods:{
    DeleteShow(){      
         let self = this;         
         this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently ' + this.selected_show.title + " all episodes and subs, are you sure?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_show);      
              $.post( self.$root_url + "/webapi/videos/delete_item.php?item=show&user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {   

                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The invite was successfully deleted',
                      'success'
                    );
                    
                    let index = self.invites.indexOf(self.selected_invite);
                    if (index > -1) {
                      self.invites.splice(index, 1);
                      }
                    }          
                });  
            }
          })
    },
    UpdateMeta()
    {
      let self = this;
      let datastring = JSON.stringify(self.selected_movie);      
              $.post( self.$root_url + "/webapi/videos/update_meta.php?item=movie&user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {               
                  
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'Metadata updated.',
                      'success'
                    );
                    
                    let index = self.invites.indexOf(self.selected_invite);
                    if (index > -1) {
                      self.invites.splice(index, 1);
                      }
                    }          
                });  
        
    },
    DeleteMovie(){      
         let self = this;
        console.log(self.selected_movie);
         this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently ' + this.selected_movie.title + " and including subs, are you sure?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_movie);      
              $.post( self.$root_url + "/webapi/videos/delete_item.php?item=movie&user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {                     
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The invite was successfully deleted',
                      'success'
                    );
                    
                    let index = self.invites.indexOf(self.selected_invite);
                    if (index > -1) {
                      self.invites.splice(index, 1);
                      }
                    }          
                });  
            }
          })
    },
    delete_selected()
    {
      let self = this;
  
         this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently the selected episodes,including subs, are you sure?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
             var datastring = JSON.stringify(self.datatable_selected);      
         $.post( self.$root_url + "/webapi/videos/delete_episodes.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring, show_id: self.selected_show.id})
         .done(function( data ) {                    
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'Episodes deleted.',
                      'success'
                    );                                      
                     
                  }
              });                        
            }
          })
    },

     getEpisodes(show)
       {       
         this.episodeEditDialog = false;
         this.selected_tab = 2;
         this.search ="";
         let self = this;
         let index = 0;
         
         
        Object.entries(this.selected_show_seasons).forEach(([key, value]) => {            
             Object.entries(value)
             .forEach(([key2, value2]) => {               
               self.show_seasons.push({id:index,show_id: show.id,season:key,episode:value2[1],key2:key2, play_count:value2[2]});
               index++;
             });
            

        });        
    
    
       }
     ,      
      deleteVideo: function(item,src)
      {
         let self = this;
         this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will permanently the ' + src + ' version of ' + item.title,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(self.selected_movie);      
              $.post( self.$root_url + "/webapi/videos/delete_video_file.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring, quality: src})
                .done(function( data ) {              
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The invite was successfully deleted',
                      'success'
                    );
                    
                    let index = self.invites.indexOf(self.selected_invite);
                    if (index > -1) {
                      self.invites.splice(index, 1);
                      }
                    }          
                });  
            }
          })
      },
       rowClickMovies: function (item, row) {      
      row.select(true);
      
        this.selected_movie = item;      
        this.movieEditDialog = true;
      },
      rowSelectEpisode: function (item, row) {     
         row.select(true);
      }
      ,

       rowClickEpisodes: function (item, row) {     
        let self = this;        
         $.getJSON(self.$root_url + "/webapi/videos/get_season.php?show_id="+item.show_id+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {      
            self.selected_show           = data.show;            
            self.selected_show_seasons   = data.season_list;            
            self.episodeEditDialog = true;
         });
         return row;
      
      },
        updateStatus()
        {            
            var self = this // create a closure to access component in the callback below
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/video_manager.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                                    self.loading = false;
                                    self.movies     = data.movies;                           
                                    self.tvshows   = data.tvshows;                           

           });
        }
        ,
            _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
        changeTab(index)
        {            
          
           if(index!=2)
           {
            this.show_seasons = [];
           }
            this.selected_tab = index;
        },
        update_thread(){  
            var self = this // create a closure to access component in the callback below
            self.loading = true;
                $.getJSON(self.$root_url + "/webapi/videos/video_conversions.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                                        self.loading = false;
                                        self.active_conversions     = data.active;                           
                                        self.finished_conversions   = data.finished;                           
                                    });
                    }        

  },
    mounted() { // when the Vue app is booted up, this is run automatically.
  
    var self = this // create a closure to access component in the callback below
      $.getJSON(self.$root_url + "/webapi/videos/video_manager.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                                    self.loading = false;
                                    self.movies     = data.movies;                           
                                    self.tvshows   = data.tvshows;                           

           });                  
    //this.timer = setInterval(this.update_thread, 5000);

    }
  }
</script>


