<template>

<v-card
      class="mt-4 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0); "
      elevation="0"
      
    >  
    <v-expansion-panels
        v-model="movie_panel"        
        multiple
        style="padding:0px!important; "
         
      >
        <v-expansion-panel  style="background-color:rgba(0,0,0,0.62);" >
          <v-expansion-panel-header><h3  style=""><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-movie</v-icon> Recently Added Movies</h3></v-expansion-panel-header>
          <v-expansion-panel-content dense>    

     <v-divider></v-divider>
      <v-list style="background-color:rgba(0,0,0,0); width:100%;">        
        <v-list-item-group color="white" style="">
          
          <v-list-item
            v-for="(card, i) in this.$parent.recent_movies"
            :key="i"
            class="mt-1"            
              style="background-color:rgba(0,0,0,0.62); height:100px;  border-radius:5px; overflow:hidden;"
               @click="play_item(card)" >            
              <v-img
               :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                
                contain="1"
                style="height:100px; max-width:68px; margin-left:-20px; -webkit-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
-moz-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6); " >
              </v-img>   
            <v-list-item-content>
             <v-list-item-title><p class="ml-2 mt-0">{{card.title}}</p>              
                <div  class="ml-2" style="font-size:9pt; width:100%; ">
                  <p style="color:gray; margin-top:-2px;">{{card.plot}}</p>
                  <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                                      
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>
                
                </div>
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

     <v-expansion-panels
        v-model="released_movies_panel"        
        multiple
        style="padding:0px!important; margin-top:8px;"         
      >
        <v-expansion-panel  style="background-color:rgba(0,0,0,0.62);" >
          <v-expansion-panel-header><h3  style=""><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-movie</v-icon> Recently Released Movies</h3></v-expansion-panel-header>
          <v-expansion-panel-content dense>    

     <v-divider></v-divider>
      <v-list style="background-color:rgba(0,0,0,0); width:100%;">        
        <v-list-item-group color="white" style="">
          
          <v-list-item
            v-for="(card, i) in this.$parent.recent_released"
            :key="i"
            class="mt-1"            
              style="background-color:rgba(0,0,0,0.62); height:100px;  border-radius:5px; overflow:hidden;"
               @click="play_item(card)" >            
              <v-img
               :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                
                contain="1"
                style="height:100px; max-width:68px; margin-left:-20px; -webkit-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
-moz-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6); " >
              </v-img>   
            <v-list-item-content>
             <v-list-item-title><p class="ml-2 mt-0">{{card.title}}</p>              
                <div  class="ml-2" style="font-size:9pt; width:100%; ">
                  <p style="color:gray; margin-top:-2px;">{{card.plot}}</p>
                  <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                                      
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>
                
                </div>
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
        v-model="panel"        
        multiple
        class="mt-2"
     
      >
        <v-expansion-panel  style="background-color:rgba(0,0,0,0.62);  border-radius:5px;" >
          <v-expansion-panel-header><h3><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-remote-tv</v-icon> Recently Added TV Episodes</h3></v-expansion-panel-header>
          <v-expansion-panel-content>    

     <v-divider></v-divider>
<v-list style="background-color:rgba(0,0,0,0);">        
        <v-list-item-group color="white" style="background-color:rgba(0,0,0,0.5); ">
  

               <v-list-item
            v-for="(card, i) in this.$parent.recent_uploads"
            :key="i"
            class="mt-1"            
              style="background-color:rgba(0,0,0,0.62); height:100px;  border-radius:5px; overflow:hidden;"
               @click="play_item(card)" >            
              <v-img
               :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                
                contain="1"
                style="height:100px; max-width:68px; margin-left:-20px; -webkit-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
                        -moz-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6); ">
              </v-img>            
            <v-list-item-content>
                        

              <v-list-item-title><p class="ml-2">{{card.title}}
            
                <div  class="ml-2" style="font-size:9pt; width:100%;">
                  <p style="color:white; margin-top:-15px;"> <span class=" orange--text"><br>Episode: </span><span>S{{card.season}}E{{card.episode}} </span>   
                   <br><span class=" orange--text"> Aired: </span> {{card.air_date}}<br>
                    <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                         
                  </p>
                                                     
                                               
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>
                
                </div>
            </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
        v-model="panel"        
        multiple
        class="mt-2"
     
      >
        <v-expansion-panel  style="background-color:rgba(0,0,0,0.62);  border-radius:5px;" >
          <v-expansion-panel-header><h3><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-remote-tv</v-icon> Recently Aired TV Episodes</h3></v-expansion-panel-header>
          <v-expansion-panel-content>    

     <v-divider></v-divider>
<v-list style="background-color:rgba(0,0,0,0);">        
        <v-list-item-group color="white" style="background-color:rgba(0,0,0,0.5); ">
  

               <v-list-item
            v-for="(card, i) in this.$parent.recent_episodes"
            :key="i"
            class="mt-1"            
              style="background-color:rgba(0,0,0,0.62); height:100px;  border-radius:5px; overflow:hidden;"
               @click="play_item(card)" >            
              <v-img
               :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                
                contain="1"
                style="height:100px; max-width:68px; margin-left:-20px; -webkit-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
                        -moz-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6); ">
              </v-img>            
            <v-list-item-content>
                        

              <v-list-item-title><p class="ml-2">{{card.title}}
            
                <div  class="ml-2" style="font-size:9pt; width:100%;">
                  <p style="color:white; margin-top:-15px;"> <span class=" orange--text"><br>Episode: </span><span>S{{card.season}}E{{card.episode}} </span>   
                   <br><span class=" orange--text"> Aired: </span> {{card.air_date}}<br>
                    <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                         
                  </p>
                                                     
                                               
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>
                
                </div>
            </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

      <v-expansion-panels
        v-model="panel"        
        multiple
        class="mt-2"
     
      >
        <v-expansion-panel  style="background-color:rgba(0,0,0,0.62);  border-radius:5px;" >
          <v-expansion-panel-header><h3><v-icon style="font-size:22pt; color:White; margin-top:-5px;">mdi-remote-tv</v-icon> Latest PPV</h3></v-expansion-panel-header>
          <v-expansion-panel-content>    

  
     <v-divider></v-divider>
  <v-list style="background-color:rgba(0,0,0,0);">        
        <v-list-item-group color="white" style="background-color:rgba(0,0,0,0.5); ">
          <v-list-item
            v-for="(card, i) in this.$parent.recent_sports"
            :key="i"
            class="mt-2"
            style="background-color:rgba(0,0,0,0.5); padding:10px;"
               @click="play_item(card)" >                           
              <v-img
                :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                aspect-ratio="1"
                style="height:80px; max-width:80px;"
                           
              >
              </v-img>            
            <v-list-item-content>
              <v-list-item-title>
                <p v-if="card.season>0" class="ml-5">{{card.title + " S"+card.season + "E" + card.episode}}</p>
                <p v-else class="ml-5">{{card.title + " " + card.episode}}</p>
                  </v-list-item-title>
                  
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
     </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    </v-card>

    
</template>
<script>
import router from '../router.js' 
  export default {
    name: 'RecentList',
    props: {
      source: String
    },
     data: () => ({  
       posterNA: '',
       
     action_menu: [
        { title: 'Add to favorites', icon: "mdi-heart", color:"white"},
        { title: 'Go Premium' ,icon: "mdi-star",color:"#d4af37"},
        { title: 'Help' ,icon: "mdi-help",color:"white"},
        { title: 'Sign Out' ,icon: "mdi-exit-run",color:"white"}
    ]
  }),
    created () {  
        this.posterNA = this.$root_url + '/img/poster_NA.png';
  },
  methods:{
         genres(genres)
      {        
        let res = "";
        try{
          genres = JSON.parse(genres);
          if(genres!==undefined)
          genres.forEach(function(key) {
              res = res + key + ", ";
          });      
          res = res.substring(0, res.length - 2);
          return res;
        }
          catch(error)
          {
            return "";
          }
    },

      toHHMMSS(mins) {
    var secs = mins * 60;
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60
    if(isNaN(seconds) || isNaN(minutes))
    {
        return "0:00:00";
    }
    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
    },
        action_menu_click (menu,card) {
            switch(menu.title)
            {
                case "Add to favorites": 
                     alert(card.title); 
                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
            }                        
        },
         play_item(card)
        {                        
            if(card.cat_id>1 && card.cat_id<4)
             router.push({ name: 'play_season', params: {showid: card.id,season:card.season,episode:card.episode} });      
             else
             router.push({ name: 'play', params: {vid: card.id} });             
        }

  },
    mounted() { // when the Vue app is booted up, this is run automatically.    
   
    }
  }
</script>

