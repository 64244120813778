<template>
       <div >

 <v-navigation-drawer
        v-model="drawer"      
      tempoary
        absolute=""
        style="background-color:rgba(0,0,0,0.7);"
        height="450"
        width="450"
        temporary=""
        v-if="drawer"
      >
           
        <v-divider></v-divider>
  
        <v-list dense>                      
          
          <v-toolbar dark dense style="margin-top:-10px;">
          <v-toolbar-title>Inbox</v-toolbar-title>
             
              <v-spacer>
              </v-spacer>
          <v-btn icon dark @click="drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          
          </v-toolbar>
          <p style="text-align:center; margin-top:20px;" v-if="inbox.length<=0">Your inbox is empty</p>
          <v-list-item
            v-for="(item, i) in inbox"
            :key="i"            
            >                      
            <v-list-item-content style="padding:0; margin-top:5px;">
               <v-icon @click="deleteMessage(item)" style="position:absolute; right:40px; top:22px;" color="red">mdi-delete</v-icon>
             <div style="width:38px;">
             <img width="38" height="38" :src="root_url +'/webapi/user/photo.php?user_id='+item.from_user_id" alt="alt" class="imgUser">   
             </div>         
            
            <a style="margin-left:48px; margin-top:-42px;" class="orange--text" @click="getMessages(item)">                                
                
                
                <p>{{item.name}} - <span style="font-size:8pt; color: grey; margin-left:2px;"> {{item.post_date}}</span> <br><span style="font-size:9pt; color:#cad3d0;">{{item.message}}</span>                                 
                </p>
                
                <p v-if="item.read==0" style="position:absolute; right:12px; top:20px;">
                <span class="mdi mdi-mailbox-up orange--text accent-2" style="font-size:18pt;"></span>
                
                </p>
                
                 
            </a>
              
                                          
            </v-list-item-content>            
          </v-list-item>      

        </v-list>
      </v-navigation-drawer>

<div class="chat_container">
<v-btn color="rgba(0,0,0,0.5)"  @click="drawer=!drawer">
          <v-badge color="red accent-4" v-if="total_unread>0" :content="notifications_count" left  transition="slide-x-transition" style="margin-left: 10px; margin-right:10px;">                
    <v-icon color="orange accent-2">mdi-mailbox-up</v-icon></v-badge> 
     <v-icon v-else>mdi-mailbox</v-icon> Inbox</v-btn>


    
      <v-list color="rgba(0,0,0,0.5)"  style="height: 100%;margin-top:10px;">
        <v-subheader style="background-color:rgba(0,0,0,1); margin-top:-10px;">{{current_conversation}}</v-subheader>
       <div id="messages_area" style=" height: 90%; overflow-y: auto;">
          <v-list-item
            v-for="(post, i) in messages"
            :key="i"
            readonly            
        >
        <a style="margin-left:0px; text-decoration:none; padding-right:10px;" :href="'/profile/'+post.id" color="primary">
            <v-list-item-icon  style="margin-top:10px;">
               <v-avatar  size="avatarSize">
                 <img style="width:32px; height:32px;" class="imgUser" :src="root_url +'/webapi/user/photo.php?user_id='+post.from_user_id" alt="alt">
               </v-avatar>  
            </v-list-item-icon>
        </a>
            <v-list-item-content style="margin-left:-20px; ">
                
              <div style="margin-top:-10px;"> <a style="margin-left:0px; text-decoration:none; padding-right:10px;" :href="'/profile/'+post.id" color="primary">{{post.name}}</a><span style="font-size:9pt; color: grey; margin-left:-12px;"> - {{post.post_date}}</span>
     
              </div>
              <div>{{post.message}}</div>                              
            </v-list-item-content>            
            
          </v-list-item>
          <v-list-item v-if="messages.length==0"><p class="mx-auto mt-12">Welcome to your inbox, open a conversation from the inbox.</p></v-list-item>
       </div>
        </v-list>
        <div v-if="messages.length>0"  style="background-color:#100b0b; padding:15px; margin-top:-20px; border-top: 1px solid rgba(0,0,0,1);">
           <v-text-field label="Message..." type="text"
           color="primary"
           prepend-icon="mdi-card-text-outline"  
           append-icon="mdi-emoticon-outline"         
           v-model="user_message"           
           @keypress.native.13="sendMessage()"              
             dark
           name="Message" /> 
        </div>

</div>
<SiteConfig/>
      </div>  
      
</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'Social',    
    props: {
      source: String
    },
    computed:{
    notifications_count: function()
      {        
        if(this.total_unread!=null) return this.total_unread;
        else return 0;        
      },
    },
     data: () => ({         
         root_url: '',   
         user_message:"",
         total_unread: "0",
         inbox:[],
         messages:[],
         current_conversation:"",
         current_thread:[],
         drawer: true,
         user:[],
        posts: []          
        }),
  created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{
"SiteConfig": SiteConfig
  },
  methods:{
    deleteMessage(item)
    {
        let self = this;
        this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will delete the message from you inbox.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go ahead'
          }).then((result) => {        
            if (result.value) {
              var datastring = JSON.stringify(item);      
              $.post( self.$root_url + "/webapi/user/delete_inbox.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {              
                    
                    let result = $.parseJSON(data);
                  if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                  }else
                  {
                    self.dialog = false;
                    self.$swal.fire(
                      'Updated!',
                      'The message was successfully deleted',
                      'success'
                    );
                    
                    let index = self.inbox.indexOf(item);
                    if (index > -1) {
                      self.inbox.splice(index, 1);
                      }
                    }          
                });  
            }
          });
    },
      getMessages(item){
        this.drawer= false;
          this.current_conversation = item.subject + " - " + item.name;
          this.current_thread = item;
          let self = this;  
            $.getJSON(self.$root_url + "/webapi/user/get_messages.php?inbox_id="+item.id+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {          
             self.messages = data.messages;             
                $.getJSON(self.$root_url + "/webapi/user/get_inbox.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {            
                    self.inbox = data.inbox;
                    self.total_unread = data.unread;
                });
            }); 

      },
         sendMessage()
          {
          
          
          var self = this; // create a closure to access component in the callback below      
          
          let item = {inbox_id: this.current_thread.id, msg: this.user_message};
          var datastring = JSON.stringify(item);      
              $.post( self.$root_url + "/webapi/user/post_reply.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
                .done(function( data ) {                                  
                    let result = $.parseJSON(data);
                  if(result.status==1)
                  {                    
                     $.getJSON(self.$root_url + "/webapi/user/get_messages.php?inbox_id="+item.inbox_id+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {          
                        self.messages = data.messages;                    
                      });   
                  }
                });
                this.user_message = "";

          },

     },     
  beforeRouteLeave (to, from, next) { 
        next();
      },
  mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this; // create a closure to access component in the callback below
    this.root_url = this.$root_url;
    
     $.getJSON(self.$root_url + "/webapi/user/get_inbox.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {            
        
             self.inbox = data.inbox;
             self.total_unread = data.unread;
    });        
    }
  }
</script>

<style >
#messages_area::-webkit-scrollbar {
    width: 12px;
} 

#messages_area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.7); 
    border-radius: 10px;
}
#messages_area::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: burlywood;
}
</style>