<template >                     
 <div>
        
        <v-card
      class="mx-auto cardClass"                
      tile
    >
    <h3 style="text-align:center;"> {{show.original_title}} ({{show.year}})  </h3>     
        
        <img :src="show.poster" class="show_poster">      
      
      <div class="imdb_info">
        
        <p class="mt-2">Plot: <span style="color:#FFFDE7">{{show.plot}}</span></p>
        <p>Genres: <span v-html="genres"></span></p>
        <p>Cast: <span v-html="casts">{{}}</span></p>
        <p>Directors: <span style="color:#FFFDE7">{{directors}}</span></p>
        <p>Producers: <span style="color:#FFFDE7">{{producers}}</span></p>      
     
      <v-menu offset-y dark min-width="140" class="" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">Options
            <v-icon v-on="on" style="    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);">mdi-dots-vertical</v-icon></div>                                        
        </template>
        <v-list style="background-color:#000000;" >       
            <v-list-item v-if="show.follow_id<=0" style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Sub',show)" text small color="#d4af37"><v-icon>mdi-heart</v-icon><span class="ml-2"> Subscribe</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="show.follow_id>0"  style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Unsub',show)" text small color="#d4af37"><v-icon>mdi-heart-off</v-icon><span class="ml-2">Unsubscribe</span></v-btn>
            </v-list-item>       
            <v-list-item style="background-color:#000000;" v-if="watch_status=='unwatch'">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Mark_show_watched',show)" text small color="#d4af37"><v-icon>mdi-eye-check</v-icon><span class="ml-2">Mark as watched</span></v-btn>                                
            </v-list-item>                           
            <v-list-item style="background-color:#000000;" v-if="watch_status=='watch'">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Mark_show_watched',show)" text small color="#d4af37"><v-icon>mdi-eye-off</v-icon><span class="ml-2">Mark as unwatched</span></v-btn>                                
            </v-list-item>                           
        </v-list>
      </v-menu>
    
        <p class="float-right">IMDB Rating: <span style="color:#FFFDE7">{{show.rating}}</span> Runtime: <span style="color:#FFFDE7">{{show.runtime}} Min</span> </p>      
      </div>             
    </v-card>

<v-card style="  border-radius:15px; background-color:rgba(0,0,0,.6); margin-top:10px; padding:10px;" >
  <h3 class="ml-2" style="color:#FFCC80;">Seasons</h3>
<v-slide-group
      v-model="model"
      style="margin-top:5px;"      
      center-active      
      mobile-breakpoint="2"
    >    
  <v-slide-item
          v-for="(card, i) in seasons"
            :key="i"            
      >
<div class="custom_card">                  
              <a @click="get_episodes(i)" class="custom_card_link">        
                <img :src="show.poster"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                <h5 ><b>Season {{i}}</b></h5>

              </div>
              
            </div>
  </v-slide-item>
</v-slide-group>

</v-card>
      <v-col cols="12">
        <v-card
          color="rgba(0,0,0,0.8)"
          elevation="0" style="padding:10px;">
                <v-slide-group            
              prev-icon="mdi-minus"
              next-icon="mdi-plus"
              show-arrows
              style="width:100%;"
            >
              <v-slide-item>
                <div id="trailer">                
                </div>
              </v-slide-item>
              <v-slide-item
                v-for="(item,index) in images"
                :key="index">
                <img class="ml-10" :src="root_url+'/media/images/'+item" width="500" height="320"/>                              
              </v-slide-item>
            </v-slide-group>
        </v-card>    
      </v-col>


       
 </div>
</template>
<script>
import $ from 'jquery';
import router from '../router.js' 

  export default {
    name: 'Seasons',    
    props: {
      source: String
    },
     data: () => ({   
        switch1: true,
        root_url: '',
        watch_status: "unwatch",
        user: [],
        images:[],
         show: [],
         seasons: []
        }),
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
        this.root_url = this.$root_url;
  },
  computed:{
    genres: function()
      {
        let res = "";
        if(this.show.genres!==undefined && this.show.genres!==null)
        this.show.genres.forEach(function(key) {
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/genre">' + key + '</a>, ';
        });      
        res = res.substring(0, res.length - 2);
        return res;
    },
     casts: function()
      {
        let res = "";        
        if(this.show.cast!==undefined && this.show.cast!==null)
          Object.keys(this.show.cast).forEach(function(key) {
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/cast">' + key + '</a>, ';
        }); 
        res = res.substring(0, res.length - 2);
        return res;
    },
    directors: function()
      {
        let res = "";
        
        if(this.show.directors!==undefined && this.show.directors!==null)
          Object.values(this.show.directors).forEach(function(key) {             
              res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/director">' + key + '</a>, ';
        }); 
        res = res.substring(0, res.length - 2);
        return res;
    },  
    producers: function()
      {
        let res = "";        
        if(this.show.producers!==undefined && this.show.producers!==null)
          Object.values(this.show.producers).forEach(function(key) {
             res = res + '<a style="color:#FFFDE7;" href="/search/'+encodeURI(key) + '/producer">' + key + '</a>, ';
        }); 
        res = res.substring(0, res.length - 2);
        return res;
    }
    
  },
  components:{
   
  },
  methods:{      
      action_menu_click (menu,card) {
        let datastring = "";
        let result = "";
        switch(menu)
        {           
            case "Mark_show_watched": this.toggle_watch_all(card);  break;
             case "Sub": 
                 
                 if(this.user.vip!="true")
                  {
                    this.$swal("VIP Function","You need to be a VIP member to access this area","error");
                    return;
                  }
                      datastring = JSON.stringify({show_id:card.id});
                       result = this._post(this.$root_url + "/webapi/videos/follow.php",datastring);
                  if(result.status==0)                  
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show subscribed','You will now start receiving new episodes notifications from this show.','success');
                    
                    card.follow_id = result.follow_id;
                  }

                break;
                case "Unsub":                                            
                    datastring = JSON.stringify({follow_id:card.follow_id});
                    result = this._post(this.$root_url + "/webapi/videos/unfollow.php",datastring);
                  if(result.status==0)      
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show unsubscribed','You will no longer receive new episodes notifications from this show.','success');        
                    card.follow_id = 0;
                  }
                  
                break;

      }
    },
      _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
    toggle_watch_all(card)
          {
            if(this.watch_status=="unwatch")
              this.watch_status = "watch";
              else
              this.watch_status = "unwatch";


          this.$swal.fire({
          title: 'Are you sure?',
          text: 'This action will toggle watchstatus on all episodes of ' +card.title,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {
              let self = this;             
              $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd="+self.watch_status+"&show_id="+card.id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                
              });                        
            }
          })            
          }
          ,
    get_episodes(season)
    {                    
        router.push({ name: 'play_season', params: {showid: this.show.id,season:season,episode:1} });             
    }
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
   
     $.getJSON(self.$root_url + "/webapi/videos/get_season.php?show_id="+this.$route.params.showid+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {      
       
      self.show = data.show;
      self.seasons = data.season_list;
      try{
        console.log(self.show);
          if(self.show.trailer=="" )
          {            
              $.getJSON(self.$root_url + "/webapi/videos/get_trailer.php?imdb="+self.show.imdb_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                      
              $("#trailer").html('<video controls style="width:420px;" src="'+data.trailer+'"</video>');
            });
          }              
            else                      
              $("#trailer").html('<video controls style="width:420px;" src="'+self.show.trailer+'"</video>');



       if(self.show.media_images!==undefined)
          self.show.media_images.forEach(function(key) {
             self.images.push(key);
          }); 
      }catch(error)
      {
        console.log(error);
      }
    
      
      
    });
    }
  }
</script>
<style scoped>
.cardClass{
  border-radius:15px; background-color:rgba(0,0,0,.6); 
  padding:15px; color:#FFCC80;
  min-height:440px;
  width: 100%!important;
  
}
.show_poster{
  position:absolute;
  width:228px; 
  margin-top:40px;
}
.imdb_info{
  margin-left:240px;  
  margin-top:40px;
}


@media only screen and (max-width: 600px) {
  .show_poster {
     position:relative;
      width: 220px;
      margin-top:0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .imdb_info
  {
    margin-top:40px;
   margin-left:0px;
    width: 100%;

  }

}
@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .show_poster {
     position:relative;
      width: 380px;
      margin-top:0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .imdb_info
  {
    margin-top:0px;
   margin-left:0px;
    width: 100%;
   height: 420px;
  }

}
@media only screen and (max-width: 1200px) and (min-width: 1000px) {
  .show_poster {
     position:relative;
      width: 380px;
      margin-top:0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .imdb_info
  {
    margin-top:0px;
   margin-left:0px;
   height: 400px;
    width: 100%;

  }

}
</style>
