<template>

<v-card
      class="mt-12 mx-auto"      
      tile
      style="background-color:rgba(0,0,0,0)"
      elevation="0"
    >
       <v-expansion-panels
        v-model="panel"                
        multiple 
      >
        <v-expansion-panel  style="background-color:rgba(0,0,0,0.62);  border-radius:5px;" >
          <v-expansion-panel-header><h3  style="color:#d4af37;"><v-icon style="font-size:22pt; color:#d4af37; margin-top:-5px;">mdi-movie-roll</v-icon> On Deck</h3></v-expansion-panel-header>
          <v-expansion-panel-content >    

    
     <v-divider></v-divider>
    <div v-if="!show_vip" style="text-align:center;">

      <h2 class="mt-10" style="text-align:center; color:#d4af37;">On Deck is a VIP feature, upgrade now to maximize your experience and get access to all the amazing features.</h2>
      <a class="v-btn v-btn--contained v-btn--router theme--dark v-size--default" style="background-color: rgb(212, 175, 55);" href="/premium">Go Premium!</a>
    </div>    
      <v-list style="background-color:rgba(0,0,0,0);"   v-if="show_vip">                
        <h3 class="mx-auto orange--text text--lighten-4 pl-2" v-if="this.$parent.on_deck.length<=0 && this.$parent.loading==false">You have no unfinished videos, yeay!</h3>
        <v-list-item-group color="white" style="background-color:rgba(0,0,0,0.5)">
          
         <v-list-item
            v-for="(card, i) in this.$parent.on_deck"
            :key="i"
            class="mt-1"            
              style="background-color:rgba(0,0,0,0.62); height:100px;  border-radius:5px; overflow:hidden;"
               @click="play_item(card)" >            
              <v-img
               :lazy-src="posterNA"
                :src="card.poster_thumbnail"     
                class="white--text"                
                position="top"                         
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                
                contain="1"
                style="height:100px; max-width:68px; margin-left:-20px; -webkit-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);
                        -moz-box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6);box-shadow: 13px 0px 12px -10px rgba(0,0,0,0.6); ">
              </v-img>      
            <v-list-item-content>
                <v-list-item-title><p class="ml-2">{{card.title}}
            
                <div  class="ml-2" style="font-size:9pt; width:100%;" v-if="card.cat_id>1">
                  <p style="color:gray; margin-top:-2px;"> S{{card.season}}E{{card.episode}}</p>
                  <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                                      
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>                
                </div>
                <div  class="ml-2" style="font-size:9pt; width:100%;" v-else>
                  <p style="color:gray; margin-top:-2px;">{{card.plot}}</p>
                  <span class=" orange--text">Genres: </span><span>{{genres(card.genres)}} </span>                                      
                    <p class="float-right mr-2 orange--text" style="margin-top:-58px;"><span>{{ card.year}}</span> </p>                
                </div>
            </v-list-item-title>
          
          
              
            </v-list-item-content>          
          
          </v-list-item>
        </v-list-item-group>
      </v-list>
                </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

</v-card>
</template>
<script>

import router from '../router.js' 
  export default {
    name: 'RecentList',
    props: {
      source: String
    },
     data: () => ({  
      posterNA: '',
      
     action_menu: [
        { title: 'Add to favorites', icon: "mdi-heart", color:"white"},
        { title: 'Go Premium' ,icon: "mdi-star",color:"#d4af37"},
        { title: 'Help' ,icon: "mdi-help",color:"white"},
        { title: 'Sign Out' ,icon: "mdi-exit-run",color:"white"}
    ]
  }),
   created () {  
        this.posterNA = this.$root_url + '/img/poster_NA.png';
  },
  computed:{
     show_vip:function(){
        if(this.$parent.user.vip == 'true')
          return true;
        else 
          return false;
      }
  } ,
  methods:{
                                    
        action_menu_click (menu,card) {
            switch(menu.title)
            {
                case "Add to favorites": 
                     alert(card.title); 
                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
            }                        
        },
        genres(genres)
      {        
        let res = "";
        try{        
          genres = JSON.parse(genres);
          if(genres!==undefined)
          genres.forEach(function(key) {
              res = res + key + ", ";
          });      
          res = res.substring(0, res.length - 2);
          return res;
        }catch(error){
            return "";
        }
    },

      toHHMMSS(mins) {
    var secs = mins * 60;
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60
    if(isNaN(seconds) || isNaN(minutes))
    {
        return "0:00:00";
    }
    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
    },
         play_item(card)
        {                        
           if(card.cat_id>1 && card.cat_id<4)
             router.push({ name: 'play_season', params: {showid: card.id,season:card.season,episode:card.episode} });      
             else
             router.push({ name: 'play', params: {vid: card.id} });             
        }

  },
    mounted() { // when the Vue app is booted up, this is run automatically.    
   
    }
  }
</script>

