<template>
    <v-row class="mt-4" style="text-align:center;">
      <div class="custom_card ml-2 mt-2"            
            v-for="card in this.$parent.cards"
            :key="card.id"   
            >  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear>   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                <h5><b>{{card.title}}</b></h5>
                

              <v-card-actions class="custom_card_actions">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);">mdi-dots-vertical</v-icon></div>                                        
        </template>

      <v-list v-if="view=='movies'" style="background-color:#000000;" >
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('GotoMovie',card)" text small color="white"><v-icon>mdi-movie</v-icon><span class="ml-2">Go to Movie</span></v-btn>                                              
          </v-list-item>                                         
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
          </v-list-item>                         

        </v-list>
       <v-list v-if="view=='search' && card.cat_id==1" style="background-color:#000000;" >
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('GotoMovie',card)" text small color="white"><v-icon>mdi-movie</v-icon><span class="ml-2">Go to Movie</span></v-btn>                                
          </v-list-item>     
          <v-list-item style="background-color:#000000;">
              <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('AddToPlaylist',card)" text small color="white"><v-icon>mdi-playlist-plus</v-icon><span class="ml-2">Add to Playlist</span></v-btn>                                
          </v-list-item>                         
        </v-list>

        <v-list style="background-color:#000000;" v-if="view=='tv'">
  <v-list-item style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px;" text small color="white" @click="action_menu_click('Gotoshow',card)" ><v-icon>mdi-remote-tv</v-icon> <span class="ml-2">Go to show</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id<=0" style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Sub',card)" text small color="#d4af37"><v-icon>mdi-heart</v-icon><span class="ml-2"> Subscribe</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id>0"  style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Unsub',card)" text small color="#d4af37"><v-icon>mdi-heart-off</v-icon><span class="ml-2">Unsubscribe</span></v-btn>
            </v-list-item>                    
        </v-list>

     <v-list style="background-color:#000000;" v-if="view=='search' && card.cat_id>1">
  <v-list-item style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px;" text small color="white" @click="action_menu_click('Gotoshow',card)" ><v-icon>mdi-remote-tv</v-icon> <span class="ml-2">Go to show</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id<=0" style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Sub',card)" text small color="#d4af37"><v-icon>mdi-heart</v-icon><span class="ml-2"> Subscribe</span></v-btn>                            
            </v-list-item>
            <v-list-item v-if="card.follow_id>0"  style="background-color:#000000;">
                <v-btn style="font-size:10pt; margin-left:-15px" @click="action_menu_click('Unsub',card)" text small color="#d4af37"><v-icon>mdi-heart-off</v-icon><span class="ml-2">Unsubscribe</span></v-btn>
            </v-list-item>                    
        </v-list>
        
                        


      </v-menu>                                 
              </v-card-actions>
              </div>
              
            </div>

           
          <v-row>
    <v-dialog v-model="show_playlists"  transition="dialog-bottom-transition" width="500">      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_playlists = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add To Playlist</v-toolbar-title>
          
          <v-spacer></v-spacer>
          
             <v-toolbar-items>
            
            <v-btn color="green" dark text @click="add_to_playlist()">Done</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>      
          <v-list-item>
            <v-list-item-content >                              
                <v-select v-model="selected_playlist"
                  :items="playlists"
                  item-text="name"
                  
                    return-object></v-select>              
            </v-list-item-content>
            </v-list-item>
          
        </v-list>
      </v-card>
    </v-dialog>
 </v-row>
        </v-row>
</template>
<style  scoped>
.video_card
{
  width:240px;
  height: 380px;

  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .video_card{
    min-width:160px!important;
    min-height:280px!important;
    max-height:280px!important;
  }
}
@media only screen and (min-width:700px) and (max-width:920px) {
  .video_card{
    min-width:160px!important;
    min-height:280px!important;
    max-height:280px!important;
  }
}


</style>

<script>
import $ from 'jquery';
import router from '../router.js' 
  export default {
    name: 'VideoCards',    
    props: {view: String},
     data: () => ({
       posterNA: '',
       show_playlists:false,
       selected_playlist:[],
       selected_video:[],
     action_menu: [],
     playlists:[],
     user:[]
  }),
  created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
        this.posterNA = this.$root_url + '/img/poster_NA.png';
  },
  mounted()
  {

      
  },
  methods:{
      add_to_playlist()
      {
          let self = this;
      var playlist_data = JSON.stringify(this.selected_playlist);   
      var vid_data = JSON.stringify(this.selected_video);   

        $.post( self.$root_url + "/webapi/videos/playlist_add.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { playlist_data: playlist_data, video_data:vid_data})
              .done(function( data ) {              
                  
                  let result = $.parseJSON(data);
                        if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                    self.show_playlists = false;
                  }else
                  {
                    self.show_playlists = false;
                    self.$swal( "Done",self.selected_video.title + " was added to playlist " + self.selected_playlist.name);
                  }         
          }); 
   
      },
        onError (card) {
        var i;   
            for (i = 0; i < this.$parent.cards.length; i++) {
                if(this.$parent.cards[i].id==card.id)
                {                           
                    this.$parent.cards[i].poster_thumbnail = this.$root_url + "/img/poster_NA.png";
                }
            }                               
        },
        
        action_menu_click (menu,card) {
         var datastring ="";
         var result = false;
         var self = this;
            switch(menu)
            {
                case "GotoMovie":
                   router.push({ name: 'play', params: {vid: card.id} }); 
                  break;
                case "Gotoshow": 
                     router.push({ name: 'season', params: {showid: card.id} }); 
                break;
                case  "AddToPlaylist":
                  
                    $.getJSON(self.$root_url + "/webapi/videos/playlists.php?user_id="+self.user.id +"&token="+self.user.auth_token+"&p=1", function(data) {
                      self.selected_video = card;
                      self.show_playlists = true;
                      self.playlists = data.items;                
                      self.loading = false;                      
                  });
                break;
                case "Sub": 
                 
                 if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
                  }
                      datastring = JSON.stringify({show_id:card.id});
                       result = this._post(this.$root_url + "/webapi/videos/follow.php",datastring);
                  if(result.status==0)                  
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show subscribed','You will now start receiving new episodes notifications from this show.','success');
                    
                    card.follow_id = result.follow_id;
                  }

                break;
                case "Unsub":                                            
                    datastring = JSON.stringify({follow_id:card.follow_id});
                    result = this._post(this.$root_url + "/webapi/videos/unfollow.php",datastring);
                  if(result.status==0)      
                      this.$swal('Failed', result.reason, "error");                        
                  else
                  {
                    this.$swal.fire('Show unsubscribed','You will no longer receive new episodes notifications from this show.','success');        
                    card.follow_id = 0;
                  }                  
                break;
                case "Account" :                        
                      router.push({ name: 'account' });
                break;
                
            }
            },
        play_item(card)
        {                        
            if(card.cat_id>1 && card.cat_id<4)
             router.push({ name: 'season', params: {showid: card.id} });   
             else
             router.push({ name: 'play', params: {vid: card.id} });             
        },   
        _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        }
        

  },
  
  }
</script>

