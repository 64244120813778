<template >

      <v-container >  
 <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
       <h4  style="position:absolute; top:40px; width:100%">Top rated movies on IMDB
  
      </h4>          
      
        <v-switch color="#d4af37" v-model="switch1" inset :label="`${switch1 ? 'Cards' : 'List'}`" 
        style="position:absolute; top:0px; right:40px;"></v-switch>        
       <div style="height:30px;"></div>
        <v-pagination
        color="#d4af37"
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12"
         :total-visible="30"

      ></v-pagination>
            <VideoList view="movies" v-if="!switch1" />
            <VideoCards view="movies" v-if="switch1" />
      <v-pagination
        v-model="page"
           color="#d4af37"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12"
         :total-visible="30"
      ></v-pagination>
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';

import VideoCards from '../components/Video_Cards'
import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'Movies',        
    props: {
      source: String
    },
     data: () => ({   
        page: 1,
        total_pages:1,
        switch1: true,
        loading: true,
        user:[],
         cards: [     
        ]
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  
  components:{
    "VideoCards" : VideoCards,
    "VideoList" : VideoList,
    "SiteConfig": SiteConfig
  },
  methods:{
         changePage(page)
        {
            var self = this;
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/top_movies.php?p="+page+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
            self.cards = data.items;                
            self.loading = false;
            });
        }
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
     $.getJSON(self.$root_url + "/webapi/videos/top_movies.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
      self.cards = data.items;
      self.total_pages = data.total_pages;
      self.loading = false;
    });
    },
      beforeRouteLeave (to, from, next) { 
        this.cards      = [];
        this.user       = [];
        delete this.cards;
        delete this.user;
      next();
     }, 
  }
</script>

