<template >

      <v-container >  
  <h4  style="position:absolute; top:40px; width:100%">System Overview
     <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded             
              height="6"
              v-if="loading"
            ></v-progress-linear>
      </h4>     
<div style="margin-top:60px;">
 <v-list>
    <v-list-item-group color="indigo" >
    <v-list-item  style="height:160px;">    
        <v-list-item-content  style="margin-top:-80px;">
            <h4><span style="font-size:20pt;" class="mdi mdi-harddisk"></span> Disk Information (/mnt/hd/)</h4>            
                <p style="color:white; margin-top:60px; width:200px;position:absolute; top:10px;">Total disk space <br>{{ getDiskSpace }} GB</p>              
                <p style="color:white; margin-top:60px; margin-left:200px; width:200px; position:absolute; top:10px;">Free disk space <br>{{ getDiskFree }} GB</p>                            
        </v-list-item-content>
        <v-list-item-content >
             <v-progress-circular
                
                :rotate="360"
                :size="100"
                :width="15"
                :value="getDiskUsage"
                color="teal"
                >
                <p style="color:white; margin-top:12px;">Usage<br>{{ getDiskUsage }} %</p>                
                </v-progress-circular>
        </v-list-item-content>
    </v-list-item>
       <v-list-item  style="height:160px;">    
        <v-list-item-content  style="margin-top:-80px;">
            <h4><span style="font-size:20pt;" class="mdi mdi-harddisk"></span> Disk Information (/mnt/hd2/)</h4>            
                <p style="color:white; margin-top:60px; width:200px;position:absolute; top:10px;">Total disk space <br>{{ getSecondaryDiskSpace }} GB</p>              
                <p style="color:white; margin-top:60px; margin-left:200px; width:200px; position:absolute;top:10px;">Free disk space <br>{{ getSecondaryDiskFree }} GB</p>                            
        </v-list-item-content>
        <v-list-item-content >
             <v-progress-circular
                
                :rotate="360"
                :size="100"
                :width="15"
                :value="getSecondaryDiskUsage"
                color="teal"
                >
                <p style="color:white; margin-top:12px;">Usage<br>{{ getSecondaryDiskUsage }} %</p>                
                </v-progress-circular>
        </v-list-item-content>
    </v-list-item>

    <v-list-item  style="height:160px;">    
        <v-list-item-content  style="margin-top:-80px;">
            <h4><span style="font-size:20pt;" class="mdi mdi-harddisk"></span> Disk Information (/)</h4>            
                <p style="color:white; margin-top:60px; width:200px;position:absolute; top:10px;">Total disk space <br>{{ getDiskSpaceSSD }} GB</p>              
                <p style="color:white; margin-top:60px; margin-left:200px; width:200px; position:absolute; top:10px;">Free disk space <br>{{ getDiskFreeSSD }} GB</p>                            
        </v-list-item-content>
        <v-list-item-content >
             <v-progress-circular
                
                :rotate="360"
                :size="100"
                :width="15"
                :value="getDiskUsageSSD"
                color="teal"
                >
                <p style="color:white; margin-top:12px;">Usage<br>{{ getDiskUsageSSD }} %</p>                
                </v-progress-circular>
        </v-list-item-content>
    </v-list-item>


  <v-list-item style="height:180px;"  >
        <v-list-item-content  style="margin-top:-80px;">
            <h4><span style="font-size:20pt;" class="mdi mdi-sort-clock-descending"></span>System Information</h4>
                <p style="color:white; margin-top:60px; width:200px;position:absolute;  top:10px;">Uptime <br>{{ getUptime }}</p>              
                <p style="color:white; margin-top:60px; margin-left:210px; width:200px; position:absolute; top:10px;">Cores <br>{{ getCores }}</p> 
                <p style="color:white; margin-top:60px; margin-left:280px; width:200px; position:absolute; top:10px;">CPU Usage <br>{{ getCPU }}</p> 
                <p style="color:white; margin-top:60px; margin-left:400px; width:200px; position:absolute; top:10px;">Total Processes <br>{{ getProcc }}</p> 
                <p style="color:white; margin-top:60px; margin-left:550px; width:200px; position:absolute; top:10px;">Memory Usage <br>{{ getMemUsage }} MB</p>                 
                <p style="color:white; margin-top:60px; margin-left:700px; width:200px; position:absolute; top:10px;">Memory Total <br>{{ getMemTotal }} MB</p>                 
                
        </v-list-item-content>
          <v-list-item-content >
              <div style="margin-left:200px;">
             <v-progress-circular
                class="ml-12 mt-8"
                :rotate="360"
                :size="100"
                :width="15"
                :value="getMemUsagePercent"
                color="teal"
                >
                <p style="color:white; margin-top:12px;">Usage<br>{{ getMemUsagePercent }} %</p>                
                </v-progress-circular>
              </div>
        </v-list-item-content>
 </v-list-item>

  <v-list-item   style="height:160px;">
        <v-list-item-content  style="margin-top:-80px;">
              <h4><span style="font-size:20pt;" class="mdi mdi-sort-clock-descending"></span>Connections</h4>
                <p style="color:white; margin-top:60px; width:200px;position:absolute; top:10px;">Http Connections <br>{{ getHttpConnections }}</p>              
                <p style="color:white; margin-top:60px; margin-left:200px; width:200px; position:absolute; top:10px;">SQL Active Connections <br>{{ getSQLConnections }}</p> 

            
        </v-list-item-content>
 </v-list-item>



   
                
    </v-list-item-group>
</v-list>
  </div>

      <SiteConfig />
      </v-container>

</template>
<script>
import $ from 'jquery';
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'System',    
    props: {
      source: String
    },
     data: () => ({   
         system_info: [],
         timer: 0
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  computed:{
      getDiskUsage: function(){
          return  this.system_info.disk_usage;
      },
      getDiskSpace: function(){
          return  this.system_info.disk_total;
      },
      getDiskFree: function(){
          return  this.system_info.disk_free;
      },
      getSecondaryDiskSpace: function(){
          return  this.system_info.disk_total_secondary;
      },
       getSecondaryDiskFree: function(){
          return  this.system_info.disk_free_secondary;
      },
      getSecondaryDiskUsage: function(){
          return  this.system_info.disk_usage_secondary;
      },

      getDiskUsageSSD: function(){
          return  this.system_info.disk_usage_ssd;
      },
      getDiskFreeSSD: function(){
          return  this.system_info.disk_free_ssd;
      },      
      getMemUsagePercent: function(){
         
         return  Math.round ((parseInt(this.system_info.mem_usage) / parseInt(this.system_info.mem_total) )* 100)
      },
       getDiskSpaceSSD: function(){
          return  this.system_info.disk_total_ssd;
      }
      ,
      getUptime: function(){
          return  this.system_info.server_uptime;
      }
      ,
      getCores: function(){
          return  this.system_info.system_cores;
      }
      ,
      getCPU: function(){
          return  this.system_info.cpu_usage;
      }, 
      getMemUsage: function(){
          return  this.system_info.mem_usage;
      },
      getMemTotal: function(){
          return  this.system_info.mem_total;
      },
      getMemPHPUsage: function(){
          return  this.system_info.mem_usage;
      },
      getProcc: function(){
          return  this.system_info.num_processes;
      },
      getHttpConnections: function(){
          return this.system_info.http_connections;
      },
       getSQLConnections: function(){
          return this.system_info.sql_connections;
      }

  },
  components:{    
  "SiteConfig":SiteConfig
  },
   beforeRouteLeave (to, from, next) { 
       clearInterval(this.timer);
          next();
   },
  methods:{
      
        update_thread()
        {            
         var self = this // create a closure to access component in the callback below
                $.getJSON(self.$root_url + "/webapi/system/system_info.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                            self.loading = false;
                            self.system_info = data;                           
                            });
        }        
       ,
      
    },
      
    mounted() { // when the Vue app is booted up, this is run automatically.
  
    var self = this // create a closure to access component in the callback below
    $.getJSON(self.$root_url + "/webapi/system/system_info.php?&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
                            self.loading = false;
                            self.system_info = data;                                                       
                        });                    
    this.timer = setInterval(this.update_thread, 1000);

    }
  }
</script>

