<template>
  
     
      <v-row align="start" class="fill-height">
        <v-col cols="12"
        >
        <div style="text-align:center;">
        <v-avatar class="" size="avatarSize">
                 <img class="imgUser" :src="profile_user.image!=='' ? this.$root_url + '/webapi/user/photo.php?user_id='+profile_user.id : this.$root_url + '/img/user.png'" alt="alt">
        </v-avatar>  
        </div>

     <v-card width="full" align="start" class="mt-2" color="rgba(0,0,0,0.7)" dense >         
       <v-card-title >   
           
    <v-tooltip bottom v-if="show_vip">
      <template v-slot:activator="{ on, attrs }">
       <v-icon  v-bind="attrs"  v-on="on" style="color:rgb(212, 175, 55);" size="38">mdi-crown-outline</v-icon>
      </template>
      <span>{{profile_user.name}} is a Premium member</span>
    </v-tooltip>

         <h1 class="display-1 mx-auto" style="color:white;">{{profile_user.name}}</h1>
       </v-card-title>
       <v-card-text>
         <v-form id="LoginForm" v-model="valid">
           <v-divider></v-divider>    
          <v-container>
             <v-row>
             <v-col cols="12">
                 <v-text-field label="Member since" type="text" disabled dark v-model="profile_user.member_date" />                      
             </v-col> 
              <v-col cols="12">
                 <v-text-field label="Last seen" type="text" disabled dark v-model="profile_user.last_login" />                      
             </v-col> 
             </v-row>                                     
           </v-container>
         </v-form>
       </v-card-text>

       <v-card-actions>        
        <v-spacer></v-spacer>
        <v-btn color="info"
        dark
        @click="show_messagebox=true" 
        :loading="data_loading">Message</v-btn>
       </v-card-actions>

     </v-card>    
     <div class="mt-2" >
       <h3 style="color:rgb(212, 175, 55);">{{profile_user.name}}s Favorite Shows</h3>
    <v-slide-group v-if="profile_user.favorite_shows.length>0"
      v-model="model"    
      class="mt-4"      
      mobile-breakpoint="1"
    >
      <v-slide-item
        v-for="card in profile_user.favorite_shows"
        :key="card.id"
      >
<div class="custom_card">  
              <v-progress-linear v-if="card.position>0"
        :value="card.position/card.length * 100"
        color="deep-orange darken-4"
        background-color="grey darken-4"
        height="6"
      ></v-progress-linear >   
              <a @click="play_item(card)" class="custom_card_link">        
                <img :src="card.poster_thumbnail"  alt="Avatar" style="" class="custom_card_img">
                <div class="play_icon">
                  <p class="mdi mdi-play-circle" style="margin-top:-20px; margin-left:-10px"></p>
                </div>
              </a>
              <div class="custom_card_container">
                    <h5><b>{{card.title}}</b><br></h5>
                

              <v-card-actions class="custom_card_actions" style="margin-top:-72px;">                                              
              
                  <v-menu offset-y dark min-width="140" class="ml-2" >
        <template v-slot:activator="{ on }" >
          <div class="d-inline-flex">
            <v-icon v-on="on">mdi-dots-vertical</v-icon></div>                                        
        </template>

        <v-list style="background-color:#000000;" >
            <v-list-item style="background-color:#000000;">                
                <v-btn style="font-size:10pt; margin-left:-15px" @click="play_item(card)" text small color="white"><v-icon>mdi-remote-tv</v-icon><span class="ml-2">Go to Show</span></v-btn>                                
            </v-list-item>          
        </v-list>
      </v-menu>                                 
              </v-card-actions>
              </div>              
            </div>
     </v-slide-item>
    </v-slide-group>
     </div>
     </v-col>
     



 <v-dialog v-model="show_messagebox"  transition="dialog-bottom-transition" width="60%">      
      <v-card class="mx-auto">
        <v-toolbar dark>
          <v-btn icon dark @click="show_messagebox = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Message to {{profile_user.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            
            <v-btn color="green" dark text @click="send_message()">Send</v-btn>
            
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>          
               <v-list-item>
                   <v-list-item-content>              
                      <v-text-field label="Subject" v-model="subject" hint="Write a subject"></v-text-field>              
                  </v-list-item-content>
              </v-list-item>  
               <v-list-item>
                   <v-list-item-content>              
                      <v-textarea label="Message" v-model="message" hint="Write a message."></v-textarea>              
                  </v-list-item-content>
              </v-list-item>  
        </v-list>
      </v-card>
    </v-dialog>


<SiteConfig/>
      </v-row>
     
</template>


<script>
import $ from 'jquery';
import router from '../router.js' 
import SiteConfig from '../components/SiteConfig'


export default {
  name: 'Account',   
  data: () => ({   
      data_loading : false,   
      valid: true,
      subject: "",
      message:"",
      show_messagebox:false,
      profile_user:[],
      user: []
  }),
    created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
        let self = this;
        let user_id = this.$route.params.user_id;    
        $.getJSON(self.$root_url + "/webapi/user/profile.php?profile_id="+user_id+"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                
            self.profile_user = data;
       
        });
  },
  computed:{
     show_vip:function(){
        
        if(this.profile_user.is_vip == 'true')
          return true;
        else 
          return false;
      }
  },

  methods:{     
       play_item(card)
        {                        
             router.push({ name: 'season', params: {showid: card.id} });    
        },
       send_message()
        {

          let self = this;
            let dataobj = {subject:this.subject, message: this.message,user:this.profile_user};        
            var datastring = JSON.stringify(dataobj);          

          $.post( self.$root_url + "/webapi/user/new_message_thread.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { data: datastring})
            .done(function( data ) {           
              
                let result = $.parseJSON(data);
              if(result.status==0)
              {                             
                self.$swal('Failed', result.reason, "error");                        
              }else
              {
                self.$swal('Success', result.reason, "success"); 
              }});
        this.user_message = "";
        },
    
  },
  components: { 
    "SiteConfig":SiteConfig
  },
  mounted(){
 
     
  }
};
</script>