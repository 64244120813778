<template>
      <v-container >  
  <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
         <h4  style=" width:100%">Search results for {{query}}
      </h4>                      
      
         <v-switch color="#d4af37" v-model="switch1" inset :label="` ${switch1 ? 'Cards' : 'List'}`" 
    class="float-right"     style="margin-top:-20px;"></v-switch>             
        <div style="height:30px;"></div>
    <v-pagination
        v-if="total_pages>1"
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12 pr-5 pl-5"
        
      ></v-pagination>
        
        <VideoList view="search"  v-if="!switch1" />
        <VideoCards view="search" v-if="switch1" />

        <v-pagination
        v-if="total_pages>1"
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
         class="mt-12 pr-5 pl-5"
      ></v-pagination>
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';

import VideoCards from '../components/Video_Cards'
import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'Home',    
    
    props: {
      source: String
    },
     data: () => ({   
        page: 1,
        total_pages:1,
        query: "",
        opt:"",
        user:[],
        switch1: true,
        loading: true,
         cards: [     
        ]
        }),
          created () {
        this.user = $.parseJSON(localStorage.getItem('user'));
  },
  components:{
    "VideoCards" : VideoCards,
    "VideoList" : VideoList,
    "SiteConfig": SiteConfig
  },
  methods:{
        changePage(page)
        {
            var self = this;
            self.loading = true;
            $.getJSON(self.$root_url + "/webapi/videos/search.php?query="+self.query+"&p="+page +"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
            self.cards = data.items;                
            self.loading = false;
            });
        }
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
    self.query =  this.$route.params.query;
    if(this.$route.params.opt!==undefined)
      self.opt =  this.$route.params.opt;


     $.getJSON(self.$root_url + "/webapi/videos/search.php?query="+self.query+"&opt="+self.opt+"&p=1&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
       console.log(data);
      self.cards = data.items;
      self.total_pages = data.total_pages;
      self.loading = false;
    });

    }
  }
</script>

