<template >
  <v-app style="width:100%; background-color:rgba(0,0,0,0);" >      
      <div class="player_topbar" v-if="playlist===undefined" style="margin-top:20px;"> 
        <v-btn rounded to="/playlists/" color="grey darken-4">
          <v-icon dark v-on="on" class="mr-2 " style="margin-top:0px;">mdi-arrow-left-bold  </v-icon>
          Playlists
        </v-btn> 
        <v-btn class="float-right" rounded @click="delete_playlist()" color="red darken-4">
          <v-icon color ="white" dark v-on="on" class="mr-2 " style="margin-top:0px;">mdi-playlist-remove </v-icon>
          Delete
        </v-btn> 
      </div>
      <Player ref="Player" playlist="true"  @event="handler" />

        <v-card
      class="mx-auto mt-6"
        width="100%"    
        style="border-radius:15px; background-color:rgba(0,0,0,.4)"   
      tile
    >
  
      <v-list flat  style="background-color: rgba(0,0,0,0.2);">       
       
       <v-subheader>{{ current_playlist.name + " (" + this.items.length}})        
        </v-subheader>
        

        <v-list-item-group  color="primary">
          
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            
          >
            <v-list-item-icon >
              <v-icon dark v-on="on"
                        @click="toggle_watch(item,i)"   
                        :color="getWatchStatus(item) ? 'orange' : 'white'"
                        >{{getWatchStatus(item) ? 'mdi-eye-check' : 'mdi-eye'}}</v-icon>     
                
                               
            </v-list-item-icon>            
            <v-list-item-content @click="play_item(item,i,false)">
         
              <v-list-item-title > {{item.original_title}}</v-list-item-title>              
            </v-list-item-content>
            
                 <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon dark v-on="on"
                        @click="play_item(item,i,false)"   
                        v-if="item.subs!=null">mdi-comment-text-outline</v-icon>                      
        </template>
        <span v-if="item.subs!=null">This Video has {{item.subs.length}} subtitles</span>
      </v-tooltip>
         <v-menu offset-y dark min-width="140" class="" >
                <template v-slot:activator="{ on }" >
                <div class="d-inline-flex float-right mt-1"><v-icon v-on="on" style="    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);">mdi-dots-vertical</v-icon></div>                                        
                </template>
                <v-list style="background-color:#000000;" >       
                    <v-list-item style="background-color:#000000;">
                      <a @click="downloadSingle(item.src_vip_sd)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 480P</span></a>                                                  
                    </v-list-item>                                 
                    <v-list-item style="background-color:#000000;" v-if="user.vip=='true' && item.src_vip_hd!=''">
                      <a @click="downloadSingle(item.src_vip_hd)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon>mdi-download</v-icon> <span class="ml-2">Download 720P</span></a>                                                  
                    </v-list-item>          
                     <v-list-item style="background-color:#000000;" v-if="user.vip=='true' && item.src_vip_hd!=''">
                      <a @click="removeFromPlaylist(item)" style="font-size:11pt; color:white; padding: 10px; margin-left:-15px;" text small color="white" ><v-icon color="red">mdi-playlist-remove</v-icon> <span class="ml-2">Remove</span></a>                                                  
                    </v-list-item>                           
                </v-list>
                </v-menu>
                   
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
 
        
  </v-app>
  
</template>

<script>
    import $ from 'jquery';
    
    import Player from '../components/Player';
   let timer_handle;
    
  export default {
    data: () => ({   
        qualities: [],
        item_playing:0,   
        current_playlist:[],    
        btnSearchSubs: false,
        btnSubtitle: "Get Subtitles",
        season_dl_interval  : 0,
        season_dl_list: [],
        next_episode: 0,      
        timer:0,
        switch1: true,
        user:[],
        show:[],
        items: []
        }),    
   components:{
      "Player": Player
    },created()
    {
     this.user =  $.parseJSON(localStorage.getItem('user'));
    },
    mounted() {
      let self = this;           

   this.timer =  setInterval(function(){ 
     if(self.$refs.Player!=null && self.$refs.Player!=undefined)
     {     
          let nextEpIndex = self.item_playing+1;          
          let next_ep = self.items[nextEpIndex];
          if(self.$refs.Player.next_timer>0)
          {                        
            if(next_ep!==null)
            {              
              if(next_ep.episode_title!==undefined)
                $("#next_time_text").html('Playing E'+(nextEpIndex+1) + ": " + next_ep.episode_title + ' in ' + self.$refs.Player.next_timer + '');  
                else
                {
                  console.log(next_ep);
                  $("#next_time_text").html('Playing '+(nextEpIndex+1) + ": " + next_ep.original_title + ' in ' + self.$refs.Player.next_timer + '');  
                }
              self.$refs.Player.next_timer-=1; 
            }
            
          } 
          else
          {
            if(!self.$refs.Player.cancel_autoplay && self.$refs.Player.play_nextDialog)
            {
                self.$refs.Player.cancel_autoplay = true;                 
                 self.play_item(self.items[nextEpIndex],nextEpIndex,true);
                 $(".dialog_next_episode").css("display","none");
            }
          }
        }
      }, 1000);

      let url  = self.$root_url + "/webapi/videos/playlist_videos.php?list_id="+this.$route.params.listid+"&user_id="+self.user.id +"&token="+self.user.auth_token;     
      
      $.getJSON(url, function(data) {        
          
         self.items = data.items;                         
          self.play_item(self.items[0],0,false);
          self.current_playlist = data.items[0];
          
      });
    },
    computed: {

    },
    
    beforeRouteLeave (to, from, next) { 
      try{
          clearInterval(this.timer);
          let current_episode = this.items[this.item_playing];          
          let self = this;               
          $.getJSON(self.$root_url + "/webapi/videos/user_watched.php?video_id="+current_episode.video_id + "&position="+parseInt(self.$refs.Player.player.currentTime) + "&length=" + parseInt(self.$refs.Player.player.duration) +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {              
              
          });

            }
        catch(err) {    
          console.log(err) ;
        }
      
      next();
     },
  
    methods: {
    delete_playlist()
    {
        let self = this;
        var card = this.current_playlist;
        card.id = card.playlist_id;
       

        this.$swal.fire({
                      title: 'Are you sure?',
                      text: 'This will permanently remove ' + card.name,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it' 
                      }).then((result) => {        
                        if (result.value) {
                                   var datastring = JSON.stringify(card); 
                          var res = self._post(self.$root_url + "/webapi/videos/playlist_delete.php?user_id="+self.user.id +"&token="+self.user.auth_token,datastring);
                           if(res.status==0)
                            {                             
                              self.$swal('Failed', res.reason, "error");                        
                            }else
                            {
                              this.dialog = false;
                              self.$swal.fire(
                                'Updated!',
                                'The playlist was successfully deleted',
                                'success'
                              );
                            }
                           

                        }
                      });                      
    },
     _post(url,datastring)
        {
           let self = this;
           var result = null;   
           $.ajax({
            type: 'POST',
            url:    url + "?user_id=" + self.user.id+"&token="+ self.user.auth_token,
            data: {"data": datastring},
            success: function(data) {
                       result = $.parseJSON(data);   
                                     
                  },
            async:   false
            }); 
             return  result;                   
        },
     handler(params) {
        console.log(params);
        this.video_ended();
    },
    video_ended()
    {
            let self = this;        
            let current_episode = self.items[self.item_playing];                          
            var duration =   self.$refs.Player.player.duration;
              $.getJSON(self.$root_url + "/webapi/videos/user_watched.php?video_id="+current_episode.video_id + "&position="+parseInt(duration/60) + "&length=" + parseInt(duration/60)+"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {
                self.items[self.item_playing].watched = 1;
              });
              
                if(self.item_playing < self.items.length-1)
                {
                    
                    self.$refs.Player.next_episode = self.item_playing +2;
                    self.$refs.Player.play_nextDialog = true;
                    self.$refs.Player.next_timer = 10;
                    self.$refs.Player.next_video = self.items[self.item_playing+1];                    
                    self.$refs.Player.cancel_autoplay = false;
                    self.$refs.Player.showPlayNextDialog(); 

               

                } 
    },
    download_Async(urls)
    {      
        var url = urls.pop();        
        var a = document.createElement("a");
        a.setAttribute('href', url);
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank');
        a.click();

        if (urls.length == 0) {
          clearInterval(this.season_dl_interval);
        }

    },
    download_season()
    {
        if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
        }

      let self = this;
      this.$swal.fire({
          title: 'Are you sure?',
          text: 'This will download all items of Season ' + this.$route.params.season + "'. Please note that you need to allow popups for this site.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {
              
                      this.$swal.fire({
                title: 'Choose quality',
                text: 'Select betwen 720P and 480P',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText:"480P",
                confirmButtonText: "720P"                
                }).then((result) => {        
                  if (result.value) {
                   self.season_dl_list = [];
                    self.items.forEach(function(item) {
                        self.season_dl_list.push(self.get_download_url(item.src_vip_hd));                         
                    });
                    self.season_dl_interval = setInterval(self.download_Async, 300, self.season_dl_list);
                  }else
                  {
                     self.season_dl_list = [];

                    self.items.forEach(function(item) {
                        self.season_dl_list.push(self.get_download_url(item.src_vip_sd));                         
                    });
                    self.season_dl_interval = setInterval(self.download_Async, 300, self.season_dl_list)
                  }});                                      
            }});

    },
    downloadSingle(url)
    {
        if(this.user.vip!="true")
                  {
                    this.$swal("Premium function","You need to be a Premium member to access this area","error");
                    return;
        }
        window.location = this.get_download_url(url);
    },
    get_download_url(url)
    {

        var base = new String(url).substring(url.lastIndexOf('/') + 1); 
        if(base.lastIndexOf(".") != -1)       
          base = base.substring(0, base.lastIndexOf("."));
          base = base.replace("_480p","");
          base = base.replace("_720p","");
        return url +"&filename="+base+".mp4";
    },
      getItemEpisode(item)
      {
        
        if(item.extra_episode>0 && item.extra_episode !== undefined)
        {
          return item.episode + "-" + item.extra_episode + " " + item.episode_title;
        }
        else
        return  item.episode + ": " + item.episode_title;
      },
      changeSrc: function (item) {      
      this.selected_quality = item.title;
        
      },
        reloaditems: function(){
            let self = this;
            let url  = self.$root_url + "/webapi/videos/playlist_videos.php?list_id="+this.$route.params.listid+"&user_id="+self.user.id +"&token="+self.user.auth_token;                 
            $.getJSON(url, function(data) {        
                
                self.items = data.items;                         
                self.play_item(self.items[0],0,false);
                self.current_playlist = data.items[0];
                
            });
        },
        getSubs: function()
          {
            let self = this;
            let episode = this.item_playing+1;
            this.btnSearchSubs = true;
            this.btnSubtitle = "Searching please wait..";
                $.getJSON(self.$root_url + "/webapi/subtitle/search_show.php?show_id="+this.$route.params.showid+"&season="+this.$route.params.season + "&episode="+episode +"&user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {                                                
                    alert(data.reason);                    
                    self.btnSearchSubs = false;
                    self.btnSubtitle = "Search Subtitles";
                    self.reloaditems();                
                });
          },
          getWatchStatus(item)
          {
            
            if(item.watched == null) return false;
            else if(item.watched==1) return true;
            else return false;
          },

          toggle_watch_all()
          {
          this.$swal.fire({
          title: 'Are you sure?',
          text: 'This action will toggle watchstatus on all items of season ' + this.$route.params.season,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
          }).then((result) => {        
            if (result.value) {
              let self = this;
              let watched = null;
              let x;
              let cmd = "unwatch";
              
              if(self.items[0].watched==null)                         
                  cmd = "watch";
              
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd="+cmd+"&show_id="+self.show.id +"&season="+this.$route.params.season+"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                                                                
                for(x=0;x<self.items.length;x++)
                {
                  let item = self.items[x];
                  if(x==0) 
                  {
                    if(item.watched!=null)
                    watched = null; else 
                    {
                      watched = 1;                            
                    }
                  }
                  self.items[x].watched =  watched;               
                } 
              });                        
            }
          })            
          },

        toggle_watch(item,i)
        {
          
          let self = this;
          if(item.watched!=null)
          {
            this.items[i].watched = null;
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=unwatch&video_id="+item.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                                                
            });
          }else
          {
            this.items[i].watched = "1";
            $.getJSON(self.$root_url + "/webapi/videos/user_unwatch.php?cmd=watch&video_id="+item.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {   
            });
          }
        },
        removeFromPlaylist(item)
        {
            var item_data = JSON.stringify(item);               

        let self = this;
        $.post( self.$root_url + "/webapi/videos/playlist_item_remove.php?user_id=" + self.user.id+"&token="+self.user.auth_token, { item_data: item_data})
              .done(function( data ) {                                
                  let result = $.parseJSON(data);
                        if(result.status==0)
                  {                             
                    self.$swal('Failed', result.reason, "error");                        
                    
                  }else
                  {
                    self.show_playlists = false;
                    self.reloaditems();  
                  }         
          }); 

        },
         play_item(video,i,contiune)
        {                           
          var strEp = i+1;
          window.history.pushState("", "", "/play_list/"+this.$route.params.listid+"/" + strEp);
           $("html, body").animate({ scrollTop: 0 }, "slow");
          let self = this;
          clearTimeout(timer_handle);
          self.$refs.Player.play_nextDialog = false;          
          self.item_playing = i;          
          if(!contiune)
          {          
            self.$refs.Player.loadSources(video);
              $.getJSON(self.$root_url + "/webapi/videos/play_count.php?video_id="+video.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                
            });
          }
          else
          {
            self.$refs.Player.loadNext(video);
              $.getJSON(self.$root_url + "/webapi/videos/play_count.php?video_id="+video.video_id +"&user_id="+self.user.id +"&token="+self.user.auth_token, function() {                
            });
          }


        }, 
    }
  }
</script>
