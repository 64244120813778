<template>
    <div>
    
    </div>
</template>

<script>
import $ from 'jquery';
  export default {
    name: 'SiteConfig',
    props: {view: String},
     data: () => ({
         current_theme:1,
    }),
  created () {
    
  },

  methods:{     
   load_default_theme () {
        this.setTheme("background_1","#345043","","","rgba(0,80,67,0.2)","rgba(52,80,67,0.5)","rgba(0,0,0,0.6)"); 
   },
   setTheme(bgClass,sideMenuColor,sideMenuBGColor,SocialBGColor,textInputDefaultColor,textInputFocusColor,topMenuColor){
                if(bgClass!="")
                    $("#main-page").addClass(bgClass);  
                if(sideMenuColor!="")    
                    $("body").get(0).style.setProperty("--side-menu-color", sideMenuColor); 
                if(sideMenuBGColor!="")
                    $("body").get(0).style.setProperty("--side-menu-bgcolor", sideMenuBGColor); 
                if(SocialBGColor!="")
                    $("body").get(0).style.setProperty("--social-bg-color", SocialBGColor); 
                if(textInputDefaultColor!="")
                    $("body").get(0).style.setProperty("--text_input_default", textInputDefaultColor); 
                if(textInputFocusColor!="")
                    $("body").get(0).style.setProperty("--text_input_focus", textInputFocusColor); 
                if(topMenuColor!="")    
                    $("body").get(0).style.setProperty("--top-menu-color", topMenuColor);  
   }

   
   },
  mounted()
  {
      this.load_default_theme();
    let user = this.$parent.user;
    if(user==undefined)
        user = this.$parent.$parent.user;

    if(user!=undefined)
    {    
        this.current_theme = user.theme;
        switch(user.theme)
        {
            /*
             setTheme(bgClass, sideMenuColor, sideMenuBGColor, SocialBGColor, textInputDefaultColor, textInputFocusColor, topMenuColor)

            */
            case 1: this.setTheme("background_1","#FFFFF","rgba(6,6,16,0.8)","rgba(0,0,0,0.6)","rgba(52,80,67,0.2)","rgba(52,80,67,0.5)","rgba(6,6,16,0.8)");                
                    break; // default;
            case 2: this.setTheme("background_2","#9ca2bd","rgba(0,0,0,0.8)","","rgba(52,80,67,0.2)","rgba(52,80,67,0.5)","rgba(0,0,0,0.6)");
                    break;

            case 3: this.setTheme("background_3","#432a1a","rgba(0,0,0,0.6)","","rgba(0,0,0,0.2)","rgba(218,161,126,0.5)","rgba(0,0,0,0.6)");
                    break;
            case 4: this.setTheme("dark_mode","#FFF","rgba(0,0,0,0.0)","#000","rgba(0,0,0,0.2)","rgba(218,161,126,0.5)","rgba(0,0,0,0)");
            break;
          
            case 5: this.setTheme("background_5","#fc223d","rgba(0,0,0,0.0)","rgba(255,129,143,0.2)","rgba(0,0,0,0.2)","rgba(255,129,143,0.2)","rgba(0,0,0,0)");
            break;

            case 6: this.setTheme("background_6","#FFFFFF","rgba(48, 5, 48,0.9)","rgba(255,129,143,0.2)","rgba(0,0,0,0.2)","rgba(2, 5, 48,0.9)","rgba(48, 5, 48,0.9)");
            break;
            

        }
    }

     
    
  },

  }
</script>



