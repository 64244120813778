import { render, staticRenderFns } from "./TVShows.vue?vue&type=template&id=1a8baea6&"
import script from "./TVShows.vue?vue&type=script&lang=js&"
export * from "./TVShows.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCol,VCombobox,VContainer,VPagination,VProgressLinear,VRow,VSwitch})
