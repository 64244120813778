<template >

      <v-container >  
        <v-progress-linear
              color="deep-purple accent-4"
              indeterminate                   
              height="10"
              v-if="loading"
              style="position:absolute; top:0px; right:0px;"
            ></v-progress-linear>
      <h4  style="position:absolute; top:20px; width:100%">Popular TV shows on IMDB
      </h4>            
 
         <v-switch  color="#d4af37" v-model="switch1" inset :label="`${switch1 ? 'Cards' : 'List'}`" 
        style="position:absolute; top:0px; right:40px;"></v-switch>       
      
        <v-pagination
        color="#d4af37"
        v-model="page"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12 pr-5 pl-5"
      ></v-pagination>
        
        <VideoList view="tv"  v-if="!switch1" />
        <VideoCards view="tv" v-if="switch1" />

        <v-pagination
        v-model="page"
           color="#d4af37"
        :length="total_pages"
         @input="changePage"
        circle
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        class="mt-12 pr-5 pl-5"
      ></v-pagination>
      <SiteConfig />
      </v-container>
</template>
<script>
import $ from 'jquery';

import VideoCards from '../components/Video_Cards'
import VideoList from '../components/Video_List'
import SiteConfig from '../components/SiteConfig'
  export default {
    name: 'TVShows',    
   
    props: {
      source: String
    },
     data: () => ({   
          page: 1,
        total_pages:1,
        switch1: true,
        loading: true,
        user:[],
         cards: [     
        ]
        }),
  created(){
    this.user =  $.parseJSON(localStorage.getItem('user'));
    if(this.user.view_style!=undefined && (this.user.view_style>0 || this.user.view_style=='true'))
        this.switch1 = true;
    else
        this.switch1 = false;

  },
  components:{
    "VideoCards" : VideoCards,
    "VideoList" : VideoList,
    "SiteConfig": SiteConfig
  },
  methods:{
               changePage()
        {
            var self = this;
            self.loading = true;
         $.getJSON(self.$root_url + "/webapi/videos/top_shows.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
            self.cards = data.items;                
            self.loading = false;
            });
        } 
  },
    mounted() { // when the Vue app is booted up, this is run automatically.
    var self = this // create a closure to access component in the callback below
        $.getJSON(self.$root_url + "/webapi/videos/top_shows.php?user_id="+self.user.id +"&token="+self.user.auth_token, function(data) {
        self.loading = false;
        self.cards = data.items;   
        self.total_pages = data.total_pages;
    });
    },
     beforeRouteLeave (to, from, next) { 
        this.cards      = [];
        this.user       = [];
        delete this.cards;
        delete this.user;
      next();
     }
  }
</script>

