<template >
  <router-view class="view one" name="a" :key="$route.fullPath"></router-view>
  
</template>

<script>

import $ from 'jquery';
import router from './router.js' 
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    user:[]
    //
  }),
  methods:{
      update_thread(){  
        var url = this.$root_url + "/webapi/user/check_auth.php";    
        var user = $.parseJSON(localStorage.getItem('user'));     
            $.ajax({
                  type: 'POST',
                  url:    url + "?user_id=" + user.id+"&token="+ user.auth_token,
                  data: {"data": ""},
                  success: function(data) {
                        var result =$.parseJSON(data);                           
                        if(result.status==0)
                        {
                          // all good                            
                        }
                        else
                        {
                          localStorage.removeItem('user');
                            router.push({ name: 'login' });
                        }                                          
                        result = undefined;
                        },
                  async:   false
                  });   
        user = undefined;    
    }
  },

  created()
  {
    this.timer = setInterval(this.update_thread, 60000);

  },
}; 

</script>
